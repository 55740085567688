import { EventAggregator } from 'aurelia-event-aggregator';
import { RouterConfiguration, Router } from 'aurelia-router';
import { Box, ServiceBase, UIInternal } from 'digiwall-lib';
import * as Constants from '../../constants';
import { Merlin } from 'generated';
import { autoinject, computedFrom } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { DialogService } from 'aurelia-dialog';
import { IndexHelper } from 'resources/elements/index-helper';

@autoinject
export class BuyingIndex {
  router: Router;
  entityId: number = null;
  priceOfferId: number;
  projectService: ServiceBase<Merlin.Web.Model.Project>;
  refreshMenu = true;

  get baseUrl() {
    return this.router.parent.currentInstruction?.fragment;
  };

  constructor(private i18n: I18N) {
    this.projectService = new ServiceBase<Merlin.Web.Model.Project>(Constants.EntityTypeNames.Project);
  }

  attached() {
    UIInternal.subscribe('router:navigation:complete', () => {
      IndexHelper.setFocusRoute(this.router);
    });
  }

  async activate(params) {
    this.entityId = params.projectId;
    if (this.entityId > 0) {

      let ent = await this.projectService.getEntityById(this.entityId);
      this._ribbonHeaderText = ent.projectName + ' (' + this.entityId + ')';
    } else {
      this._ribbonHeaderText = this.i18n.tr('project.newProject');
    }

    this.router.routes.forEach(r => r.disabled = this.entityId < 0);
  }

  private _ribbonHeaderText: string;
  @computedFrom("_ribbonHeaderText")
  public get ribbonHeaderText(): string {
    return this._ribbonHeaderText;
  }

  get currentRoute() {
    return this.router?.currentInstruction?.config.route;
  }

  configureRouter(config: RouterConfiguration, router: Router, params, routeConfig, currentInstruction): void {
    this.router = router;

    config.map([
      {
        route: "",
        redirect: 'summary'
      },
      {
        route: "summary",
        moduleId: "projects/buying/buying-summary",
        name: "summary",
        title: this.i18n.tr('buyingindex.summary'),
        nav: true
      },
      {
        route: "offer",
        moduleId: "projects/buying/buying-offer",
        name: "offer",
        title: this.i18n.tr('buyingindex.offer'),
        nav: true
      },
      {
        route: "planning",
        moduleId: "projects/buying/buying-planning", //TODO à remplacer
        name: "planning",
        title: this.i18n.tr('buyingindex.planning'),
        nav: true
      },
      {
        route: "offer-request",
        moduleId: "request-supplier-offers/request-supplier-offer-list",
        name: "offer-request",
        title: this.i18n.tr('buyingindex.offerRequest'),
        nav: true
      },
      {
        route: "add-offer",
        moduleId: "request-supplier-offers/add-request-supplier-offer",
        name: "add-offer",
        title: this.i18n.tr('buyingindex.offerRequest'),
        nav: false,
        focusRoute: "offer-request"
      },
      {
        route: "request-supplier-offer-detail/:param1",
        moduleId: "request-supplier-offers/request-supplier-offer-detail",
        title: this.i18n.tr('requestsupplieroffer.requestsupplieroffer'),
        nav: false,
        name: "request-supplier-offer-detail",
        focusRoute: "offer-request"
      },
      {
        route: "supplier-offer-detail/:requestToSupplierId/:supplierOfferId/:type",
        moduleId: "supplier-offers/supplier-offer-detail",
        title: this.i18n.tr('supplieroffer.supplieroffer'),
        nav: false,
        name: "supplier-offer-detail",
        focusRoute: "offer-request"
      },
      {
        route: "comparison",
        moduleId: "projects/quotes/offer-comparison/offer-comparison",
        name: "comparison",
        title: this.i18n.tr('buyingindex.comparison'),
        nav: true
      },
      {
        route: "contracts",
        moduleId: "projects/buying/third-party-contract/third-party-contract-list",
        name: "third-party-contract-list",
        title: this.i18n.tr('buyingindex.contract'),
        nav: true
      },
      {
        route: "contract/:contractId",
        moduleId: "projects/buying/third-party-contract/third-party-contract-detail",
        name: "third-party-contract-detail",
        title: this.i18n.tr('buyingindex.contract'),
        nav: false,
        focusRoute: "third-party-contract-list"
      },
      {
        route: "document",
        moduleId: "documents/document-list",
        name: "document",
        title: this.i18n.tr('buyingindex.documents'),
        nav: true,
        params: {
          projectPhaseId: Constants.ProjectPhaseId.Buying
        }
      }
    ]);
  }

  public goToFirstDevis() {
    this.router.navigate(`quote`);
  }

}

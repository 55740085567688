import { RefToDisplay } from './../../../constants';
import { DialogController } from 'aurelia-dialog';
import { Router } from 'aurelia-router';
import { autoinject, BindingEngine, TaskQueue } from 'aurelia-framework';
import * as Constants from '../../../constants';
import { DataFormat } from 'select2';
import { Merlin } from 'generated';
import { ServiceBase, Box, ViewModelBase, CustomLogger, GlobalLoaderService } from 'digiwall-lib';
import { PriceOfferLineTreeView } from './price-offer-line-tree-view/price-offer-line-tree-view';
import { DocumentTypeDiscriminator } from 'request-supplier-offers/request-supplier-offer-detail';

import * as toastr from 'toastr';
import { DataLineDTO, PriceOfferLineRepartition, PriceRepartitionService, Repartition } from 'services/price-repartition-service';

@autoinject
export class PriceRepartition extends ViewModelBase {
  ribbonHeaderText = '';
  ressourceName = '';

  private applicationParameterService: ServiceBase<Merlin.Web.Model.ApplicationParameter>;
  private applicationParameter: Merlin.Web.Model.ApplicationParameter;
  public totalPercentage: number;
  private priceOfferLineId: number;
  private dto: Array<PriceRepartitionDtoDataFormat>;

  private dataLines: Array<DataLineDTO>;
  private fkId: number;

  public priceOfferLineRepartition: PriceOfferLineRepartition;
  public defaultPriceRepartitionStep = 1;

  type: DocumentTypeDiscriminator
  refToDisplay: RefToDisplay = RefToDisplay.MerlinRef;

  constructor(router: Router, logger: CustomLogger, private bindingEngine: BindingEngine, private dialogController: DialogController, private taskQueue: TaskQueue, private globalLoaderService: GlobalLoaderService, private priceRepartitionService: PriceRepartitionService) {
    super(router, logger);
    this.applicationParameterService = new ServiceBase<Merlin.Web.Model.ApplicationParameter>(Constants.EntityTypeNames.ApplicationParameter);
  }

  async activate(params) {
    this.applicationParameter = await this.applicationParameterService.firstEntity();
    this.defaultPriceRepartitionStep = this.applicationParameter?.priceOfferLineDefaultPriceRepartitionStep;
    this.type = params.type;

    this.priceRepartitionService.type = this.type;

    this.fkId = params.fkId;
    this.priceOfferLineId = params.param1;

    this.refToDisplay = params.refToDisplay

    this.priceOfferLineRepartition = await this.priceRepartitionService.getPriceRepartition(this.priceOfferLineId);
    this.dataLines = await this.priceRepartitionService.getLinesForRepartition(this.fkId, this.refToDisplay);

    this.dto = [];
    if (this.priceOfferLineRepartition.repartitions.length == 0) {
      this.addRepartition(null)
    } else {
      this.priceOfferLineRepartition.repartitions.forEach(repartition => {
        this.addRepartition(repartition);
      });
    }

  }

  public openPriceOfferLineTreeView(dto: PriceRepartitionDtoDataFormat) {
    this.box.showCustomDialog(PriceOfferLineTreeView,
      null,
      null,
      null,
      {
        canSave: false,
        model: {
          dataLines: this.dataLines,
          fkId: this.fkId,
          oldValueSelectedId: dto.priceOfferLineTo?.id
        }
      })
      .whenClosed(async result => {
        if (!result.wasCancelled) {
          if (result.output != null) {
            let output = result.output;
            let firstAdded = false;
            for (let index = 0; index < output.length; index++) {
              const element = output[index];
              let dataLine = this.dataLines.find(x => x.id == element.id);
              if (!firstAdded) {
                dto.percentage = 0;
                dto.priceOfferLineTo = { id: element.id, text: element.text };
                firstAdded = true;
              }
              else {
                let parent = this.getParent(this.dto.map((x) => { return { id: x.priceOfferLineTo.id as number, text: x.priceOfferLineTo.text } }), dataLine);
                if (parent != null) continue; //if parent already present in the list we do not add
                if (this.dto.find(x => x.priceOfferLineTo.id == dataLine.id) != null) continue; //If line already present we do not add
                this.addRepartition({
                  dataLine: dataLine,
                  percentage: 0,
                  repartitionOnError: null
                });
              }
            }
            this.removeDtoIfParentSelected();
            await this.computePercentage();
          }
        }
      });
  }

  private async computePercentage() {
    let percentage = await this.priceRepartitionService.getPercentageRepartition(this.dto.map(x => x.priceOfferLineTo.id) as number[]);
    this.dto.forEach(dto => {
      dto.percentage = parseFloat(percentage[dto.priceOfferLineTo.id]);
    });
  }

  private removeDtoIfParentSelected() {
    for (let index = 0; index < this.dto.length; index++) {
      const element = this.dto[index];
      let dataLine = this.dataLines.find(x => x.id == element.priceOfferLineTo.id);
      let parent = this.getParent(this.dto.map((x) => { return { id: x.priceOfferLineTo.id as number, text: x.priceOfferLineTo.text }; }), dataLine);
      if (parent != null) {
        this.removeRepartition(element);
        index--;
      }
    }
  }

  public async ok() {
    if (!(this.dto.every(x => x.priceOfferLineTo.id))) {
      toastr.error(this.i18n.tr("pricerepartition.missingLineError"));
      return;
    }
    let bodyList: Array<Repartition> = [];
    this.dto.forEach(x => {
      bodyList.push({
        dataLine:
        {
          id: parseInt(x.priceOfferLineTo.id.toString()),
          fullName: x.priceOfferLineTo.text,
          parentId: null
        },
        percentage: x.percentage,
        repartitionOnError: false
      }
      );
    });

    this.globalLoaderService.allow("pricerepartition.computing", 1);
    let response: Response = await this.priceRepartitionService.createPriceRepartitions(this.priceOfferLineId, bodyList);
    if (response.ok) {
      this.dialogController.ok();
    }
  }

  private getParent(selectedLines: Array<{ id: number; text: string }>, dataLine: DataLineDTO) {
    if (dataLine.parentId == null) return null;

    let parent = this.dataLines.find(x => x.id == dataLine.parentId);

    if (selectedLines.find(x => x.id == parent.id)) {
      let newParent = this.getParent(selectedLines, parent);
      if (newParent != null) {
        return newParent;
      }
      else {
        return parent;
      }
    }
    else {
      return this.getParent(selectedLines, parent);
    }
  }

  private validatePriceRepartition(priceRepartition: PriceRepartitionDtoDataFormat): void {
    let others = this.dto.filter(dto => dto != priceRepartition);
    let total = 0;
    others.map(o => o.percentage).forEach(v => total += v);
    let diff = (total + priceRepartition.percentage) - 100;
    if (diff > 0) {
      this.taskQueue.queueTask(() => {
        priceRepartition.percentage = 100 - total;
      });
    }
  }

  private computeTotal100Percentage() {
    let percentageExisting = this.dto.map(x => x.percentage).reduce((prevVal, currentVal) => prevVal + currentVal, 0)
    this.totalPercentage = parseFloat(percentageExisting.toFixed(2));
  }

  public addRepartition(repartition: Repartition = null) {
    let newRepartition: PriceRepartitionDtoDataFormat;
    if (repartition == null) {
      let percentageExisting = 0;
      this.dto.forEach(x => percentageExisting += x.percentage);
      newRepartition = { priceOfferLineTo: { id: '', text: '' }, percentage: 100 - percentageExisting, repartitionOnError: false };
    } else {
      newRepartition = { priceOfferLineTo: { id: repartition.dataLine.id, text: repartition.dataLine.fullName }, percentage: repartition.percentage, repartitionOnError: repartition.repartitionOnError };
    }
    this.addObservableRepartition(newRepartition);
    this.dto.push(newRepartition);
    this.computeTotal100Percentage();

  }

  private addObservableRepartition(newRepartition: PriceRepartitionDtoDataFormat) {
    this.disposables.push(
      this.bindingEngine.propertyObserver(newRepartition, "percentage").subscribe((newValue, oldValue) => {
        if (newValue != oldValue) {
          this.computeTotal100Percentage();
          this.validatePriceRepartition(newRepartition);
        }
      }),
      this.bindingEngine.propertyObserver(newRepartition, "priceOfferLineTo").subscribe(async (newVal, oldVal) => {
        if (newVal != oldVal) {
          this.removeDtoIfParentSelected();
          await this.computePercentage();
        }
      })
    );
  }

  public removeRepartition(dto) {
    let index = this.dto.findIndex(x => x == dto);
    this.dto.splice(index, 1);
    this.computeTotal100Percentage();
  }

  public close() {
    this.dialogController.close(false);
  }
}





interface PriceRepartitionDtoDataFormat {
  priceOfferLineTo: DataFormat;
  percentage: number;
  repartitionOnError: boolean;
}



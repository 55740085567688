import { RefToDisplay } from './../constants';
import { HttpClient } from 'aurelia-fetch-client';
import { autoinject } from "aurelia-framework";
import * as Constants from '../constants';

@autoinject
export class ReportApiService {

  constructor(private HttpClient: HttpClient) {

  }

  async getCover(priceOfferVersionId: number, isInjecting: boolean, displayIncluded: boolean): Promise<ReportResult> {
    let body = {
      entityId: priceOfferVersionId,
      isInjecting: isInjecting,
      displayIncluded: displayIncluded
    } as ReportInputDto;
    let response = await this.HttpClient.post(Constants.Application.ReportController.Cover, JSON.stringify(body))
    if (response.ok) {
      return await response.json();
    }
    return null;
  }

  async getOfferPdf(priceOfferVersionId: number, isInjecting: boolean, includeTotalInLetter: boolean, refToDisplay: RefToDisplay, displayIncluded: boolean): Promise<ReportResult> {
    let body = {
      entityId: priceOfferVersionId,
      isInjecting: isInjecting,
      includeTotalInLetter: includeTotalInLetter,
      refToDisplay: refToDisplay,
      displayIncluded: displayIncluded
    } as ReportInputDto;
    let response = await this.HttpClient.post(Constants.Application.ReportController.OfferPdf, JSON.stringify(body));
    if (response.ok) {
      return await response.json();
    }
    return null;
  }
  async getPreviewOfferPdf(priceOfferVersionId: number): Promise<ReportResult> {
    let response = await this.HttpClient.get(Constants.Application.ReportController.PreviewOfferPdf.format(priceOfferVersionId.toString(), false.toString(), false.toString(), Constants.RefToDisplay.MerlinRef.toString()));
    if (response.ok) {
      return await response.json();
    }
    return null;
  }

  async getInternalExcel(priceOfferVersionId: number, includeTotalInLetter: boolean, refToDisplay: RefToDisplay, displayIncluded: boolean): Promise<ReportResult> {
    let body = {
      entityId: priceOfferVersionId,
      includeTotalInLetter: includeTotalInLetter,
      displayIncluded: displayIncluded,
      isInjecting: false,
      includeUnitPriceInLetter: false,
      internalUserId: null,
      externalThirdPartyId: null,
      refToDisplay: refToDisplay
    } as ReportInputDto;

    let response = await this.HttpClient.post(Constants.Application.ReportController.InternalExcel, JSON.stringify(body));
    if (response.ok) {
      return await response.json();
    }
    return null;
  }

  async getExternalExcel(priceOfferVersionId: number, includeTotalInLetter: boolean, displayIncluded: boolean): Promise<ReportResult> {
    let response = await this.HttpClient.post(Constants.Application.PriceOfferVersionController.ExportXls.format(priceOfferVersionId.toString(), includeTotalInLetter.toString(), displayIncluded.toString()));
    if (response.ok) {
      return await response.json();
    }
    return null;
  }

  async getAdditionalWorkPdf(additionalWorkVersionId: number, internalUserId: number, externalThirdPartyId: number, refToDisplay: RefToDisplay): Promise<ReportResult> {
    let response = await this.HttpClient.get(Constants.Application.ReportController.AdditionalWorkPdf.format(additionalWorkVersionId.toString(), internalUserId?.toString(), externalThirdPartyId?.toString(), refToDisplay.toString()));
    if (response.ok) {
      return await response.json();
    }
    return null;
  }
  async getPreviewAdditionalWorkPdf(additionalWorkVersionId: number): Promise<ReportResult> {
    let response = await this.HttpClient.get(Constants.Application.ReportController.PreviewAdditionalWorkPdf.format(additionalWorkVersionId.toString(), null, null, Constants.RefToDisplay.MerlinRef.toString()));
    if (response.ok) {
      return await response.json();
    }
    return null;
  }

  async getContractPdf(contractId: number, internalUserId: number, refToDisplay: RefToDisplay): Promise<ReportResult> {
    let response = await this.HttpClient.get(Constants.Application.ReportController.ContractPdf.format(contractId.toString(), internalUserId.toString(), refToDisplay.toString()));
    if (response.ok) {
      return await response.json();
    }
    return null;
  }
  async getPreviewContractPdf(contractId: number): Promise<ReportResult> {
    let response = await this.HttpClient.get(Constants.Application.ReportController.PreviewContractPdf.format(contractId.toString(), null, Constants.RefToDisplay.MerlinRef.toString()));
    if (response.ok) {
      return await response.json();
    }
    return null;
  }

  async getProgressStatementPdf(contractId: number, refToDisplay: RefToDisplay): Promise<ReportResult> {
    let response = await this.HttpClient.get(Constants.Application.ReportController.ProgressStatementPdf.format(contractId.toString(), refToDisplay.toString()));
    if (response.ok) {
      return await response.json();
    }
    return null;
  }
  async getPreviewProgressStatementPdf(contractId: number,): Promise<ReportResult> {
    let response = await this.HttpClient.get(Constants.Application.ReportController.PreviewProgressStatementPdf.format(contractId.toString(), Constants.RefToDisplay.MerlinRef.toString()));
    if (response.ok) {
      return await response.json();
    }
    return null;
  }

  async getInvoicePdf(invoiceId: number, refToDisplay: RefToDisplay): Promise<ReportResult> {
    let response = await this.HttpClient.get(Constants.Application.ReportController.InvoicePdf.format(invoiceId.toString(), refToDisplay.toString()));
    if (response.ok) {
      return await response.json();
    }
    return null;
  }
  async getPreviewInvoicePdf(invoiceId: number): Promise<ReportResult> {
    let response = await this.HttpClient.get(Constants.Application.ReportController.InvoicePdf.format(invoiceId.toString(), Constants.RefToDisplay.MerlinRef.toString()));
    if (response.ok) {
      return await response.json();
    }
    return null;
  }
}

export interface ReportResult {
  url: string;
  name: string;
}

export interface ReportInputDto {
  entityId: number;
  displayIncluded: boolean;
  isInjecting: boolean;
  includeTotalInLetter: boolean;
  includeUnitPriceInLetter: boolean;
  internalUserId: number | null;
  externalThirdPartyId: number | null;
  refToDisplay: RefToDisplay;
}


import { ListViewModelBase, CustomLogger, ServiceBase, SelectAllHeaderComponent, FieldType, Various, DialogBoxViewModel, ActionDialogBoxInputParameters, CamelCase, IMenuItems, Box } from 'digiwall-lib';
import { Router } from 'aurelia-router';
import { autoinject, Container } from 'aurelia-framework';
import { Merlin } from "../generated";
import { ColDef, ICellRendererParams } from "ag-grid-community";
import * as Constants from '../constants';
import { GridHelperMerlin } from 'resources/elements/grid-helper';
import { HttpClient } from 'aurelia-fetch-client';
import { EmailSenderBox } from 'email-sender/email-sender-box';

@autoinject
export class NotificationSentList extends ListViewModelBase<Merlin.Web.Model.NotificationSent> {
  constructor(router: Router, logger: CustomLogger, public httpClient: HttpClient) {
    super(router, logger, new ServiceBase<Merlin.Web.Model.NotificationSent>(Constants.EntityTypeNames.NotificationSent));
    this.service.gridDataSource.expands = ['notificationContentType', 'emailTemplate.emailType.type', 'notificationSentFiles', 'project'];
  }

  public ressourceName: string = Constants.EntityTypeNames.NotificationSent;
  private Constants: any = Constants;
  private projectId: number;


  public onCellClicked(entity: Merlin.Web.Model.NotificationSent) {
    if (entity?.id) {
      this.openNotificationBox(entity);
    }
    return false;
  }

  private openNotificationBox(entity: Merlin.Web.Model.NotificationSent) {
    this.box.showEditDialog(EmailSenderBox, entity.id, this.i18n.tr('notificationsent.notificationsent'),
      {
        canSave: false,
        size: 'all',
        model: { entity: entity, isReadonly: true }
      });
  }


  public async activate(params: any) {
    await super.activate(params);
    if (params.id != null) {
      let entity = await this.service.getEntityById(params.id);
      this.onCellClicked(entity);
    }
    this.projectId = params.projectId;
    if (this.projectId != null) {
      this.service.gridDataSource.queryParameters = { projectId: this.projectId };
    }
  }

  public initializeGridOptions() {
    super.initializeGridOptions(false, false);
  }

  public async cancelNotifications() {
    let buttonYes: ActionDialogBoxInputParameters =
    {
      label: this.i18n.tr("general.yes", { ns: "common" }),
      title: this.i18n.tr("general.yes", { ns: "common" }),
      theme: 'primary',
      type: 'solid',
      disabled: false,
      fn: (thisBox: DialogBoxViewModel) => {
        thisBox.controller.ok();
      }
    };
    let buttonNo: ActionDialogBoxInputParameters =
    {
      label: this.i18n.tr("general.no", { ns: "common" }),
      title: this.i18n.tr("general.no", { ns: "common" }),
      theme: 'dark',
      type: 'ghost',
      disabled: false,
      fn: (thisBox: DialogBoxViewModel) => {
        thisBox.controller.cancel(false);
      }
    };
    await this.box.showQuestion(this.i18n.tr("notificationsent.cancelMultipleQuestion"), null, [buttonNo, buttonYes]).whenClosed(async result => {
      if (result.output == buttonYes) {
        this.selectedEntities.forEach(async notificationSent => {
          let currentNotification = await this.service.getEntityById(notificationSent.id);
          if (currentNotification.status == Constants.NotificationStatus[Constants.NotificationStatus.ToSend]) {
            notificationSent.status = Constants.NotificationStatus[Constants.NotificationStatus.Cancelled];
            this.service.saveEntity(currentNotification, true);
          }
        });
        this.unselectAll();
      }
    });
  }

  public canSendMail(selectedEntities: Array<Merlin.Web.Model.NotificationSent>): boolean {
    return selectedEntities.filter(x => x.status == "ToSend").length > 0;
  }

  public async sendMail(selectedEntities: Array<Merlin.Web.Model.NotificationSent>) {
    let ids: Array<number> = new Array();
    selectedEntities.filter(x => x.status == "ToSend").forEach(notif => {
      ids.push(notif.id);
    });
    if (ids.length > 0) {
      await this.httpClient.post(Constants.Application.RemoteServiceName + Constants.Application.ForceSendEmail, JSON.stringify(ids));
    }
  }

  public getMenuItems(params): Array<IMenuItems> {
    return [
      {
        group: "1",
        hiddenLabel: true,
        items: [
          {
            label: params.i18n.tr("menu.open"),
            icon: "digi-open",
            handler: () => {
              let box = Container.instance.get(Box);
              box.showEditDialog(EmailSenderBox, params.data.id, this.i18n.tr('notificationsent.notificationsent'),
                {
                  canSave: false,
                  size: 'all',
                  model: { entity: params.data, isReadonly: true }
                });
            }
          },
          {
            label: params.i18n.tr("notificationsent.goToProject"),
            icon: "digi-navigation-line",
            handler: () => {
              params.router.navigate("projects/" + params.data.projectId);
            },
            disabled: () => {
              return params.data.projectId == null;
            }
          }
        ]
      },
      {
        label: params.i18n.tr("menu.delete"),
        icon: "digi-delete-bin-line",
        handler: async () => {
          let result = await params.service.deleteEntities([params.data], true);
          if (result) {
            params.gridOptions.api.forEachNode((rowNode) => {
              if (rowNode.data != null)
                if (rowNode.data.isSelected == true)
                  rowNode.data.isSelected = false;
            });
            params.gridOptions.api.refreshInfiniteCache();
          }
        },
        disabled: () => {
          return false;
        }
      }];
  }

  public getDataGridColumns() {
    let projectService: ServiceBase<Merlin.Web.Model.Project> = new ServiceBase<Merlin.Web.Model.Project>(Constants.EntityTypeNames.Project);
    let defs: ColDef[] = [
      ...new GridHelperMerlin().contextMenuColdDef(this, this.getMenuItems),
      {
        headerName: this.i18n.tr("notificationsent.type"),
        field: "type",
        type: FieldType.Enum,
        filterParams: {
          suppressFilterButton: true,
          category: "notificationsent",
          enum: Constants.NotificationType
        },
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          category: "notificationsent",
          enum: Constants.NotificationType
        },
        cellRenderer: (data) => {
          let type: string;
          if (data.data?.type != Constants.NotificationType.Email) {
            type = 'email';
          }
          return '<span class="digi-mail-line"></span>';
        },
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("notificationsent.status"),
        field: "status",
        type: FieldType.Enum,
        filterParams: {
          suppressFilterButton: true,
          category: "notificationsent",
          enum: Constants.NotificationStatus
        },
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          category: "notificationsent",
          enum: Constants.NotificationStatus
        },
        valueGetter: (data) => {
          if (data?.data?.[data.colDef.field] != null) {
            return this.i18n.tr("notificationsent." + CamelCase.toCamelCase(data.data[data.colDef.field] as string));
          }
          return;
        },
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("notificationsent.date"),
        field: "date",
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          dateFormat: Various.DateFormatWithHours
        },
        type: FieldType.Date,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("notificationsent.subject"),
        field: "subject",
        type: FieldType.String,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("notificationsent.notificationContentTypeId"),
        field: "notificationContentType.denomination._translation",
        type: FieldType.Enumeration,
        filterParams: {
          category: Constants.EnumerationTypes.EmailType,
        },
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          category: Constants.EnumerationTypes.EmailType,
        },
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("notificationsent.from"),
        field: "from",
        type: FieldType.String,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("notificationsent.fromName"),
        field: "fromName",
        type: FieldType.String,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("notificationsent.to"),
        field: "to",
        type: FieldType.String,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("notificationsent.toName"),
        field: "toName",
        type: FieldType.String,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("notificationsent.cC"),
        field: "cc",
        hide: true,
        type: FieldType.String,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("notificationsent.attachments"),
        field: "attachmentNumber",
        hide: true,
        type: FieldType.Number,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("notificationsent.projectId"),
        field: "project.projectName",
        type: FieldType.OneToMany,
        filterParams: {
          service: projectService
        },
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          service: projectService
        },
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      ...new GridHelperMerlin().baseEntityColDef(this),
    ];
    return defs;
  }
} 

import environment from 'environment';
import { EntityTypeNames as CoreEntityTypeNames } from 'digiwall-lib';

export class Application {
  public static UtilsController = {
    ImportLeadExcel: "/api/Utils/ImportLeadExcel",
  }
  public static ApplicationTitle = "Merlin";

  public static get RemoteServiceName() {
    return environment.remoteApiUrl;
  }
  //public static RemoteFileServiceName = "/api/file/upload";
  //public static RemoteFileServicePicture = "/api/file/uploadBase64";
  // public static RemoteFileServiceRotate = "/api/file/rotate";
  public static RemoteResetPasswordServiceName = "/breeze/MerlinBreeze/ResetPassword";
  public static CalcMathFormula = "/api/Merlin/CalcMathFormula";
  public static DuplicateItem = "/api/Merlin/DuplicateItem";
  public static DuplicateItemComposition = "/api/Merlin/DuplicateItemComposition";
  public static GetEmailContent = "/api/Merlin/GetEmailContent";
  public static ImportExcel = "/api/Merlin/ImportExcel";
  public static AnalyseMetering = "/api/Merlin/AnalyseMetering";
  public static ImportMeteringResult = "/api/Merlin/ImportMeteringResult";
  public static Phase2AnalysisPOLValues = "/api/Merlin/Phase2AnalysisPOLValues";
  public static Phase3IdentificationLinesTreatment = "/api/Merlin/Phase3IdentificationLinesTreatment";
  public static DeleteAfterImport = "/api/Merlin/DeleteAfterImport";
  public static ImportFailed = "/api/Merlin/ImportFailed";
  public static DeleteImportMetering = "/api/Merlin/DeleteImportMetering";
  public static CopyPriceOfferLine = "/api/Merlin/copyPriceOfferLine";
  public static ForceSendEmail = "/api/Merlin/ForceSendEmail";

  public static GetQuoteMeteringPriceOfferLines = "/api/Quote/GetPriceOfferLines";
  public static PostQuoteMeteringPriceOfferLines = "/api/Quote/PostPriceOfferLines";
  public static GetQuoteMeteringPriceOfferLineChildren = "/api/Quote/GetPriceOfferLineChildren";
  public static DuplicateLine = "/api/Quote/DuplicateLine";
  public static MoveLineToChild = "/api/Quote/MoveLineToChild";
  public static MoveLineToUp = "/api/Quote/MoveLineToUp";
  public static MoveLineToDown = "/api/Quote/MoveLineToDown";
  public static AddNewLine = "/api/Quote/AddNewLine";
  public static GetTotal = "/api/Quote/GetTotal";

  public static ItemsApiV1 = "/api/v1/items";
  public static ItemCompositionsApiV1 = "/api/v1/item-compositions";
  public static TasksApiV1 = "/api/v1/tasks";
  public static WorkQuotationVersionApiV1 = "/api/v1/work-quotation-versions";
  public static PriceOfferVersionApiV1 = "/api/v1/price-offer-versions";
  public static ComparisonApiV1 = "/api/v1/comparison";
  public static RequestSupplierOfferV1 = "/api/v1/request-suppliers/{0}";
  public static PricesServiceApiV1 = "/api/v1/prices-service";
  public static SupplierOfferV1 = "/api/v1/supplier-offers/{0}";
  public static DocumentV1 = "/api/v1/document";
  public static BuyingV1 = "/api/v1/buying";
  public static RealizationV1 = "/api/v1/realization";
  public static ClientProgressStatementLineV1 = "/api/v1/client-progress-statement-line";
  public static SubContractorProgressStatementLineV1 = "/api/v1/subcontractor-progress-statement-line";
  public static ClientProgressStatementVersionV1 = "/api/v1/client-progress-statement-version";
  public static SubContractorProgressStatementVersionV1 = "/api/v1/subcontractor-progress-statement-version";
  public static ProjectV1 = "/api/v1/projects";
  public static ComparisonBuyingV1 = "/api/v1/comparison-buying";
  public static ContractV1 = "/api/v1/contracts";
  public static AmendmentV1 = "/api/v1/amendments";
  public static EmailTemplateV1 = "/api/v1/email-template";
  public static ReportApi = "/api/report";
  public static AdditionalWorkV1 = "/api/v1/additional-work";
  public static PriceRepartitionV1 = "/api/v1/price-repartitions/{0}";
  public static Ponto = "/api/ponto";
  public static InvoiceV1 = "/api/v1/invoices";
  public static EmailV1 = "/api/v1/emails";

  public static ThirdPartyV1 = "/api/v1/third-parties";
  public static MeteringV1 = "/api/v1/metering";
  public static ReconciliationV1 = "/api/reconciliation";
  public static NotificationV1 = "/api/communication";

  public static ExternalCommunicationController = {
    GetEmailContent: "/api/Merlin/GetEmailContent",
    GetTemplateContent: "/api/Merlin/GetTemplateContent"
  }
  public static BuyingController = {
    GetAll: Application.BuyingV1 + "/{0}",
    Patch: Application.BuyingV1 + "/{0}/{1}",
    BulkPatch: Application.BuyingV1 + "/{0}/lines/bulk",
    PatchLine: Application.BuyingV1 + "/{0}/lines/{1}",
    Children: Application.BuyingV1 + "/{0}/lines/{1}/children/{2}/{3}",
    Filter: Application.BuyingV1 + "/{0}/lines/filter",
    Fetch: Application.BuyingV1 + "/{0}/lines/fetch/{1}",
    Duplicate: Application.BuyingV1 + "/{0}/lines/{1}/duplicate",
    Create: Application.BuyingV1 + "/{0}/lines",
    CanDelete: Application.BuyingV1 + "/{0}/lines/can-delete",
    Delete: Application.BuyingV1 + "/{0}/lines",
    CreateFromItems: Application.BuyingV1 + "/{0}/lines/fromItem",
    Summary: Application.BuyingV1 + "/{0}/summary",
    GetMeteringTotalPrices: Application.BuyingV1 + "/{0}/total",
    RecomputeLines: Application.BuyingV1 + "/{0}/recompute-lines",
    GetLastChapterId: Application.BuyingV1 + "/{0}/get-last-chapter-id"
  }

  public static RealizationController = {
    // GetAll: Application.RealizationV1 + "/{0}",
    // Patch: Application.RealizationV1 + "/{0}/{1}",
    PatchLine: Application.RealizationV1 + "/{0}/lines/{1}",
    BulkPatch: Application.RealizationV1 + "/{0}/lines/bulk",
    Children: Application.RealizationV1 + "/{0}/lines/{1}/children/{2}/{3}",
    Filter: Application.RealizationV1 + "/{0}/lines/filter",
    Fetch: Application.RealizationV1 + "/{0}/lines/fetch/{1}",
    Duplicate: Application.RealizationV1 + "/{0}/lines/{1}/duplicate",
    Create: Application.RealizationV1 + "/{0}/lines",
    CanDelete: Application.RealizationV1 + "/{0}/lines/can-delete",
    Delete: Application.RealizationV1 + "/{0}/lines",
    CreateFromItems: Application.RealizationV1 + "/{0}/lines/fromItem",
    // Summary: Application.RealizationV1 + "/{0}/summary",
    GetMeteringTotalPrices: Application.RealizationV1 + "/{0}/total",
    AddAdditionalWork: Application.RealizationV1 + "/{0}/lines/{1}/addAdditionalWork/{2}",
    CanCreateClientAdditionalWork: Application.RealizationV1 + "/{0}/can-create-client-additional-work",
    RecomputeLines: Application.RealizationV1 + "/{0}/recompute-lines",
    GetLastChapterId: Application.RealizationV1 + "/{0}/get-last-chapter-id",
  }
  public static ClientProgressStatementLineController = {
    GetAll: Application.ClientProgressStatementLineV1 + "/{0}",
    Patch: Application.ClientProgressStatementLineV1 + "/{0}/{1}",
    BulkPatch: Application.ClientProgressStatementLineV1 + "/{0}/lines/bulk",
    PatchLine: Application.ClientProgressStatementLineV1 + "/{0}/lines/{1}",
    Children: Application.ClientProgressStatementLineV1 + "/{0}/lines/{1}/children/{2}/{3}",
    Filter: Application.ClientProgressStatementLineV1 + "/{0}/lines/filter",
    Fetch: Application.ClientProgressStatementLineV1 + "/{0}/lines/fetch/{1}",
    Duplicate: Application.ClientProgressStatementLineV1 + "/{0}/lines/{1}/duplicate",
    Create: Application.ClientProgressStatementLineV1 + "/{0}/lines",
    CanDelete: Application.ClientProgressStatementLineV1 + "/{0}/lines/{1}/can-delete",
    Delete: Application.ClientProgressStatementLineV1 + "/{0}/lines/{1}",
    CheckCanCreateProgressStatement: Application.ClientProgressStatementLineV1 + "/{0}/can-create-progress-statement",
    GetLastProgressStatementId: Application.ClientProgressStatementLineV1 + "/{0}/get-last-progress-statement-id",
    SynchroData: Application.ClientProgressStatementLineV1 + "/{0}/synchro-data",
    GetTotals: Application.ClientProgressStatementLineV1 + "/{0}/total"
  }
  public static SubContractorProgressStatementLineController = {
    GetAll: Application.SubContractorProgressStatementLineV1 + "/{0}",
    Patch: Application.SubContractorProgressStatementLineV1 + "/{0}/{1}",
    BulkPatch: Application.SubContractorProgressStatementLineV1 + "/{0}/lines/bulk",
    PatchLine: Application.SubContractorProgressStatementLineV1 + "/{0}/lines/{1}",
    Children: Application.SubContractorProgressStatementLineV1 + "/{0}/lines/{1}/{2}/children/{3}/{4}",
    Filter: Application.SubContractorProgressStatementLineV1 + "/{0}/{1}/lines/filter",
    Fetch: Application.SubContractorProgressStatementLineV1 + "/{0}/lines/fetch/{1}",
    Duplicate: Application.SubContractorProgressStatementLineV1 + "/{0}/lines/{1}/duplicate",
    Create: Application.SubContractorProgressStatementLineV1 + "/{0}/lines",
    CanDelete: Application.SubContractorProgressStatementLineV1 + "/{0}/lines/{1}/can-delete",
    Delete: Application.SubContractorProgressStatementLineV1 + "/{0}/lines/{1}",
    CheckCanCreateProgressStatement: Application.SubContractorProgressStatementLineV1 + "/{0}/can-create-progress-statement",
    GetLastProgressStatementId: Application.SubContractorProgressStatementLineV1 + "/{0}/get-last-progress-statement-id/{1}",
    SynchroData: Application.SubContractorProgressStatementLineV1 + "/{0}/synchro-data/{1}",
    GetTotals: Application.SubContractorProgressStatementLineV1 + "/{0}/total/{1}"
  }
  public static ClientProgressStatementVersionController = {
    GetAll: Application.ClientProgressStatementVersionV1 + "/{0}",
    Patch: Application.ClientProgressStatementVersionV1 + "/{0}/{1}",
    BulkPatch: Application.ClientProgressStatementVersionV1 + "/{0}/lines/bulk",
    PatchLine: Application.ClientProgressStatementVersionV1 + "/{0}/lines/{1}",
    Children: Application.ClientProgressStatementVersionV1 + "/{0}/lines/{1}/children/{2}/{3}",
    Filter: Application.ClientProgressStatementVersionV1 + "/{0}/lines/filter/{1}",
    Fetch: Application.ClientProgressStatementVersionV1 + "/{0}/lines/fetch/{1}",
    AfterCreateProgressStatement: Application.ClientProgressStatementVersionV1 + "/{0}/after-create-progress-statement/{1}",
    AfterValidatingProgressStatementVersion: Application.ClientProgressStatementVersionV1 + "/{0}/after-validating-progress-statement/{1}",
    DeleteProgressStatementVersion: Application.ClientProgressStatementVersionV1 + "/{0}/{1}",
    GetTotals: Application.ClientProgressStatementVersionV1 + "/{0}/total/{1}"
  }
  public static SubContractorProgressStatementVersionController = {
    GetAll: Application.SubContractorProgressStatementVersionV1 + "/{0}",
    Patch: Application.SubContractorProgressStatementVersionV1 + "/{0}/{1}",
    BulkPatch: Application.SubContractorProgressStatementVersionV1 + "/{0}/lines/bulk",
    PatchLine: Application.SubContractorProgressStatementVersionV1 + "/{0}/lines/{1}",
    Children: Application.SubContractorProgressStatementVersionV1 + "/{0}/lines/{1}/children/{2}/{3}",
    Filter: Application.SubContractorProgressStatementVersionV1 + "/{0}/lines/filter/{1}/{2}",
    Fetch: Application.SubContractorProgressStatementVersionV1 + "/{0}/lines/fetch/{1}",
    AfterCreateProgressStatement: Application.SubContractorProgressStatementVersionV1 + "/{0}/after-create-progress-statement/{1}/{2}",
    AfterValidatingProgressStatementVersion: Application.SubContractorProgressStatementVersionV1 + "/{0}/after-validating-progress-statement/{1}",
    DeleteProgressStatementVersion: Application.SubContractorProgressStatementVersionV1 + "/{0}/{1}",
    GetTotals: Application.SubContractorProgressStatementVersionV1 + "/{0}/total/{1}/{2}"
  }
  public static AdditionalWorkController = {
    Children: Application.AdditionalWorkV1 + "/{0}/lines/{1}/children/{2}/{3}",
    GetHasAcceptedVersion: Application.AdditionalWorkV1 + "/{0}/get-has-accepted-version",
    GetHasDraftVersion: Application.AdditionalWorkV1 + "/{0}/get-has-draft-version",
    Filter: Application.AdditionalWorkV1 + "/{0}/lines/filter",
    Patch: Application.AdditionalWorkV1 + "/{0}/lines/{1}",
    BulkPatch: Application.AdditionalWorkV1 + "/{0}/lines/bulk",
    FilterClientAdditionalWork: Application.AdditionalWorkV1 + "/{0}/{1}/lines/filter",
    Fetch: Application.AdditionalWorkV1 + "/{0}/lines/fetch/{1}",
    CreateFromItems: Application.AdditionalWorkV1 + "/{0}/lines/fromItem/{1}/{2}",
    Create: Application.AdditionalWorkV1 + "/{0}",
    CreateAndAcceptedVersion: Application.AdditionalWorkV1 + "/{0}/{1}",
    CreateLine: Application.AdditionalWorkV1 + "/{0}/{1}/lines",
    RemoveLinkClientAdditionalWork: Application.AdditionalWorkV1 + "/{0}/{1}/remove-link-client-additional-work/{2}",
    RemoveLinks: Application.AdditionalWorkV1 + "/{0}/{1}/remove-links-client-additional-work",
    TransformToExtraLinkClientAdditionalWork: Application.AdditionalWorkV1 + "/{0}/{1}/transform-to-extra-link-client-additional-work/{2}",
    RejectLinkClientAdditionalWork: Application.AdditionalWorkV1 + "/{0}/{1}/reject-link-client-additional-work/{2}",
    GetMeteringTotalPrices: Application.AdditionalWorkV1 + "/{0}/total",
    ChangeStatus: Application.AdditionalWorkV1 + "/{0}/change-status/{1}",
    SetStatusVersion: Application.AdditionalWorkV1 + "/{0}/set-status-version/{1}",
    CloneVersion: Application.AdditionalWorkV1 + "/{0}/clone-client-additional-work-version",
    DeleteLine: Application.AdditionalWorkV1 + '/{0}/delete-line',
    FreeLine: Application.AdditionalWorkV1 + '/{0}/free-line',
    AddItem: Application.AdditionalWorkV1 + '/{0}/add-item',
    AddPostLine: Application.AdditionalWorkV1 + '/{0}/add-post-line',
    RecomputeLines: Application.AdditionalWorkV1 + "/{0}/recompute-lines",
  }

  public static ComparisonBuyingController = {
    CreateLinkBetweenPOLAndSOL: Application.ComparisonBuyingV1 + "/{0}/lines/comparison/create-link",
    GetAllWithComparisonColumn: Application.ComparisonBuyingV1 + "/{0}/lines/comparison",
    GetChildrenWithComparisonColumn: Application.ComparisonBuyingV1 + "/{0}/lines/{1}/comparison",
    ComparisonGetByIds: Application.ComparisonBuyingV1 + "/{0}/lines/comparison/fetch/{1}",
    ComparisonFooter: Application.ComparisonBuyingV1 + "/{0}/comparison-footer",
    UnSelectPrice: Application.ComparisonBuyingV1 + "/{0}/lines/{1}",
    UnSelectPriceOfSupplier: Application.ComparisonBuyingV1 + "/{0}/lines/un-select",
    GetThirdParties: Application.ComparisonBuyingV1 + "/{0}/third-parties",
    Patch: Application.ComparisonApiV1 + "/{0}/lines/{1}",
    BulkPatch: Application.ComparisonApiV1 + "/{0}/lines/bulk",
  }

  public static ComparisonController = {
    CreateLink: Application.ComparisonApiV1 + "/{0}/lines/comparison/create-link",
    GetAllWithComparisonColumn: Application.ComparisonApiV1 + "/{0}/lines/comparison",
    GetChildrenWithComparisonColumn: Application.ComparisonApiV1 + "/{0}/lines/{1}/comparison",
    ComparisonGetByIds: Application.ComparisonApiV1 + "/{0}/lines/comparison/fetch/{1}",
    ComparisonFooter: Application.ComparisonApiV1 + "/{0}/comparison-footer",
    UnSelectPrice: Application.ComparisonApiV1 + "/{0}/lines/{1}",
    UnSelectPriceOfSupplier: Application.ComparisonApiV1 + "/{0}/lines/un-select",
    GetThirdParties: Application.ComparisonApiV1 + "/{0}/third-parties",
    Patch: Application.ComparisonApiV1 + "/{0}/lines/{1}",
    BulkPatch: Application.ComparisonApiV1 + "/{0}/lines/bulk",
    AcceptFromEstimatedPrice: Application.ComparisonApiV1 + "/{0}/accept-from-estimated-price/{1}/{2}"
  }

  public static ContractController = {
    CreateContract: Application.ContractV1 + "/{0}/create/{1}/{2}",
    PatchStatus: Application.ContractV1 + "/{0}/status/{1}",
    PriceOfferLineGrid_Filter: Application.ContractV1 + "/{0}/lines/filter/{1}",
    PriceOfferLineGrid_FilterDraftContract: Application.ContractV1 + "/{0}/lines/filterDraftContract",
    PriceOfferLineGrid_GetChildren: Application.ContractV1 + "/{0}/lines/{1}/children/{2}/{3}/{4}",
    PriceOfferLineGrid_TotalPrice: Application.ContractV1 + "/{0}/total/{1}",
    PriceOfferLineGrid_TotalPriceDraft: Application.ContractV1 + "/{0}/totalDraft",
    Patch: Application.ContractV1 + "/{0}/lines/{1}",
    BulkPatch: Application.ContractV1 + "/{0}/lines/bulk",
    Fetch: Application.ContractV1 + "/{0}/lines/fetch/{1}/{2}",
    CanCreateAmendment: Application.ContractV1 + "/{0}/can-create-amendment",
    GetThirdPartyType: Application.ContractV1 + "/{0}/get-third-party-type",
    RecomputeLines: Application.ContractV1 + "/{0}/recompute-lines",
    GetMeteringTotalPrices: Application.ContractV1 + "/{0}/total",
  }


  public static AmendmentController = {
    Filter: Application.AmendmentV1 + "/{0}/lines/filter/{1}",
    CreateAmendment: Application.AmendmentV1 + "/{0}/create-amendment/{1}/{2}",
    GetValidatedOriginalContractId: Application.AmendmentV1 + "/{0}/get-validated-original-contract-id/{1}",
    SetSendIds: Application.AmendmentV1 + "/{0}/set-send-ids/{1}",
  }

  public static EmailTemplateController = {
    Placeholders: Application.EmailTemplateV1 + "/placeholders/{0}",
    Preview: Application.EmailTemplateV1 + "/preview"
  }

  public static DocumentController = {
    Create: Application.DocumentV1,
    GetDocument: Application.DocumentV1 + "/{0}",
    SaveDocument: Application.DocumentV1,
    UpdateToUseAsAnnex: Application.DocumentV1 + "/update/{0}/to-use-as-annex/{1}",
    UpdateIsVisisbleFromLancelot: Application.DocumentV1 + "/update/{0}/is-visible-from-lancleot/{1}"
  }

  public static ItemController = {
    PatchItem: Application.ItemsApiV1 + "/{0}",
    GetItem: Application.ItemsApiV1 + "/{0}",
    NewItemPrice: Application.ItemsApiV1 + "/{0}/prices/new",
    UpdateItemPrices: Application.ItemsApiV1 + "/prices",
    GetItemPrice: Application.ItemsApiV1 + "/prices/{0}",
    CanDeleteItemPrice: Application.ItemsApiV1 + "/prices/{0}/can-delete",
    GetCurrentItemPrice: Application.ItemsApiV1 + "/prices/current",
    NewItemPriceFromDate: Application.ItemsApiV1 + "/prices/{0}/from-date/new",
    UpdateItemPriceFromDate: Application.ItemsApiV1 + "/prices/from-date",
    NewItemComposition: Application.ItemsApiV1 + "/{0}/compositions/new",
    UpdateItemComposition: Application.ItemsApiV1 + "/compositions",
    NewItemCompositionLine: Application.ItemsApiV1 + "/compositions/{0}/lines/new",
    Compute: Application.ItemsApiV1 + "/compositions/compute",
    UpdateItemLink: Application.ItemsApiV1 + "/update-item-link/{0}",
    GetCurrentPrice: Application.ItemsApiV1 + "/current-price/{0}",
    CreateUnvalidatedItem: Application.ItemsApiV1 + "/create-unvalidated-item",
    ChildrenNotValidated: Application.ItemsApiV1 + "/children-not-validated/{0}"
  }

  public static ItemCompositionController = {
    CreateFromItems: Application.ItemCompositionsApiV1 + "/{0}/lines/fromItems",
    Create: Application.ItemCompositionsApiV1 + "/{0}/lines",
    GetAll: Application.ItemCompositionsApiV1 + "/{0}/lines/filter",
    GetChildren: Application.ItemCompositionsApiV1 + "/{0}/lines/{1}/children/{2}",
    Move: Application.ItemCompositionsApiV1 + "/{0}/lines/move",
    Patch: Application.ItemCompositionsApiV1 + "/{0}/lines/{1}",
    DeleteLine: Application.ItemCompositionsApiV1 + "/{0}/lines",
    GetByIds: Application.ItemCompositionsApiV1 + "/{0}/lines/fetch/{1}",
  }

  public static ReportController = {
    Cover: Application.ReportApi + "/first-page",
    OfferPdf: Application.ReportApi + '/offer-pdf',
    PreviewOfferPdf: Application.ReportApi + '/preview-offer-pdf/{0}/{1}/{2}/{3}',
    InternalExcel: Application.ReportApi + '/internal-excel',
    AdditionalWorkPdf: Application.ReportApi + '/additional-work-pdf/{0}/{1}/{2}/{3}',
    PreviewAdditionalWorkPdf: Application.ReportApi + '/preview-additional-work-pdf/{0}/{1}/{2}/{3}',
    ContractPdf: Application.ReportApi + '/contract-pdf/{0}/{1}/{2}',
    PreviewContractPdf: Application.ReportApi + '/preview-contract-pdf/{0}/{1}/{2}',
    ProgressStatementPdf: Application.ReportApi + '/progress-statement-pdf/{0}/{1}',
    PreviewProgressStatementPdf: Application.ReportApi + '/preview-progress-statement-pdf/{0}/{1}',
    InvoicePdf: Application.ReportApi + '/invoice-pdf/{0}/{1}',
    PreviewInvoicePdf: Application.ReportApi + '/preview-invoice-pdf/{0}/{1}',
  }

  public static WorkQuotationController = {
    CreateLine: Application.WorkQuotationVersionApiV1 + "/{0}/lines",
    CreateFromItems: Application.WorkQuotationVersionApiV1 + "/{0}/lines/fromItem",
    NewVersion: Application.WorkQuotationVersionApiV1 + "/{0}/new-version/{1}",
    Patch: Application.WorkQuotationVersionApiV1 + "/{0}/lines/{1}",
    BulkPatch: Application.WorkQuotationVersionApiV1 + "/{0}/lines/bulk",
    GetAll: Application.WorkQuotationVersionApiV1 + "/{0}/lines/filter",
    GetChildren: Application.WorkQuotationVersionApiV1 + "/{0}/lines/{1}/children/{2}/{3}",
    GetByIds: Application.WorkQuotationVersionApiV1 + "/{0}/lines/fetch/{1}/{2}",
    VersionHasLine: Application.WorkQuotationVersionApiV1 + "/{0}/hasLines",
    DeleteLine: Application.WorkQuotationVersionApiV1 + "/{0}/lines",
    CanDelete: Application.WorkQuotationVersionApiV1 + "/{0}/lines/canDelete",
    Move: Application.WorkQuotationVersionApiV1 + "/{0}/lines/move",
    Paste: Application.WorkQuotationVersionApiV1 + "/{0}/lines/paste",
    PriceOfferVersionTotal: Application.WorkQuotationVersionApiV1 + "/{0}/total",
    DuplicateLine: Application.WorkQuotationVersionApiV1 + "/{0}/lines/{1}/duplicate",
    Summary: Application.WorkQuotationVersionApiV1 + "/{0}/summary",
    ActivityCodesCompleteness: Application.WorkQuotationVersionApiV1 + "/{0}/activity-codes-completeness",
    BuyingPriceCompleteness: Application.WorkQuotationVersionApiV1 + "/{0}/buying-price-completeness",
    GetComment: Application.WorkQuotationVersionApiV1 + "/price-offer-line-comments/{0}",
    CreateOrUpdateComment: Application.WorkQuotationVersionApiV1 + "/price-offer-line-comments/{0}",
    DeleteComment: Application.WorkQuotationVersionApiV1 + "/price-offer-line-comments/{0}",
    CanExportXls: Application.WorkQuotationVersionApiV1 + "/{0}/can-export-xls",
    ExportXls: Application.WorkQuotationVersionApiV1 + "/{0}/export-xls",
    GetColumnsMapping: Application.WorkQuotationVersionApiV1 + "/{0}/mapping/{1}",
    GetImportSessions: Application.WorkQuotationVersionApiV1 + "/{0}/sessions",
    ChangeCategory: Application.WorkQuotationVersionApiV1 + "/{0}/change-category/{1}/{2}",
    GenerateExcel: Application.WorkQuotationVersionApiV1 + "/{0}/excel",
    OfferSended: Application.WorkQuotationVersionApiV1 + "/{0}/offer-sended",
    CanBeAccepted: Application.WorkQuotationVersionApiV1 + "/{0}/can-be-accepted",
    SetStatus: Application.WorkQuotationVersionApiV1 + "/{0}/set-status/{1}",
    CreateNewVariantGroup: Application.WorkQuotationVersionApiV1 + "/{0}/create-variant-group",
    DeleteVariantGroup: Application.WorkQuotationVersionApiV1 + "/{0}/delete-variant-group/{1}",
    DeleteSheets: Application.WorkQuotationVersionApiV1 + "/{0}/delete-sheets/{1}",
    HasLineFromStatus: Application.WorkQuotationVersionApiV1 + "/{0}/has-line-from-files",
    DeleteVersionLines: Application.WorkQuotationVersionApiV1 + "/{0}/delete-version-lines",
    RecomputeLines: Application.WorkQuotationVersionApiV1 + "/{0}/recompute-lines",
    GetLastChapterId: Application.WorkQuotationVersionApiV1 + "/{0}/get-last-chapter-id",
    ValidateLastImport: Application.WorkQuotationVersionApiV1 + "/{0}/validate-last-import",
    AcceptFromEstimatedPrice: Application.WorkQuotationVersionApiV1 + "/{0}/accept-from-estimated-price"
  }

  public static PriceOfferVersionController = {
    CreatePriceOfferVersion: Application.PriceOfferVersionApiV1 + "/{0}/create-version-line",
    CreateLine: Application.PriceOfferVersionApiV1 + "/{0}/lines",
    CreateFromItems: Application.PriceOfferVersionApiV1 + "/{0}/lines/fromItem",
    NewVersion: Application.PriceOfferVersionApiV1 + "/{0}/new-version",
    Patch: Application.PriceOfferVersionApiV1 + "/{0}/lines/{1}",
    BulkPatch: Application.PriceOfferVersionApiV1 + "/{0}/lines/bulk",
    GetAll: Application.PriceOfferVersionApiV1 + "/{0}/lines/filter",
    GetChildren: Application.PriceOfferVersionApiV1 + "/{0}/lines/{1}/children/{2}/{3}",
    GetByIds: Application.PriceOfferVersionApiV1 + "/{0}/lines/fetch/{1}/{2}",
    VersionHasLine: Application.PriceOfferVersionApiV1 + "/{0}/hasLines",
    DeleteLine: Application.PriceOfferVersionApiV1 + "/{0}/lines",
    CanDelete: Application.PriceOfferVersionApiV1 + "/{0}/lines/canDelete",
    Move: Application.PriceOfferVersionApiV1 + "/{0}/lines/move",
    Paste: Application.PriceOfferVersionApiV1 + "/{0}/lines/paste",
    PriceOfferVersionTotal: Application.PriceOfferVersionApiV1 + "/{0}/total",
    DuplicateVersion: Application.PriceOfferVersionApiV1 + "/{0}/duplicate",
    DuplicateLine: Application.PriceOfferVersionApiV1 + "/{0}/lines/{1}/duplicate",
    Summary: Application.PriceOfferVersionApiV1 + "/{0}/summary",
    ActivityCodesCompleteness: Application.PriceOfferVersionApiV1 + "/{0}/activity-codes-completeness",
    BuyingPriceCompleteness: Application.PriceOfferVersionApiV1 + "/{0}/buying-price-completeness",
    GetComment: Application.PriceOfferVersionApiV1 + "/price-offer-line-comments/{0}",
    CreateOrUpdateComment: Application.PriceOfferVersionApiV1 + "/price-offer-line-comments/{0}",
    DeleteComment: Application.PriceOfferVersionApiV1 + "/price-offer-line-comments/{0}",
    CanExportXls: Application.PriceOfferVersionApiV1 + "/{0}/can-export-xls",
    ExportXls: Application.PriceOfferVersionApiV1 + "/{0}/export-xls/{1}/{2}",
    GetColumnsMapping: Application.PriceOfferVersionApiV1 + "/{0}/mapping",
    GetImportSessions: Application.PriceOfferVersionApiV1 + "/{0}/sessions",
    GenerateExcel: Application.PriceOfferVersionApiV1 + "/{0}/excel",
    OfferSended: Application.PriceOfferVersionApiV1 + "/{0}/offer-sended",
    CanBeAccepted: Application.PriceOfferVersionApiV1 + "/{0}/can-be-accepted",
    SetStatus: Application.PriceOfferVersionApiV1 + "/{0}/set-status/{1}",
    CreateNewVariantGroup: Application.PriceOfferVersionApiV1 + "/{0}/create-variant-group",
    DeleteVariantGroup: Application.PriceOfferVersionApiV1 + "/{0}/delete-variant-group/{1}",
    HasLineFromStatus: Application.PriceOfferVersionApiV1 + "/{0}/has-line-from-files",
    DeleteVersionLines: Application.PriceOfferVersionApiV1 + "/{0}/delete-version-lines",
    GetPMThatNeedRepartion: Application.PriceOfferVersionApiV1 + "/{0}/get-pm-repartition",
    RecomputeTotalVersion: Application.PriceOfferVersionApiV1 + "/{0}/recompute-total-version",
    RecomputeLines: Application.PriceOfferVersionApiV1 + "/{0}/recompute-lines",
    GetLastChapterId: Application.PriceOfferVersionApiV1 + "/{0}/get-last-chapter-id",
    GetSummary: Application.PriceOfferVersionApiV1 + "/{0}/summary",
    UpdateCoefficient: Application.PriceOfferVersionApiV1 + "/{0}/summary/update-coefficient/{1}",
    UpdateSummaryLine: Application.PriceOfferVersionApiV1 + "/{0}/summary/update-summary-line"
  }

  public static PricesServiceController = {
    ComputePrices: Application.PricesServiceApiV1 + "/compute",
    GetMultiplyDecimal: Application.PricesServiceApiV1 + "/multiply/{0}/{1}"
  }

  public static ProjectController = {
    ProjectCanBeStarted: Application.ProjectV1 + "/{0}/project-can-be-started",
    HasMetering: Application.ProjectV1 + "/{0}/has-metering",
    StartProject: Application.ProjectV1 + "/{0}/start-project",
    ApplyTemplate: Application.ProjectV1 + "/{0}/apply-template/{1}",
    SetStatus: Application.ProjectV1 + "/{0}/set-status/{1}",
    GetBase: Application.ProjectV1 + "/{0}/resume-base",
    GetAddress: Application.ProjectV1 + "/{0}/resume-address",
    GetDates: Application.ProjectV1 + "/{0}/resume-dates",
    GetResumeExtern: Application.ProjectV1 + "/{0}/resume-extern",
    GetResumeIntern: Application.ProjectV1 + "/{0}/resume-intern",
    GetResumePartner: Application.ProjectV1 + "/{0}/resume-partner",
    GetCompany: Application.ProjectV1 + "/{0}/resume-company",
    GetNumber: Application.ProjectV1 + "/{0}/resume-number",
    GetPrecisionParameter: Application.ProjectV1 + "/{0}/precision-parameter",
    ChangeCurrentVersion: Application.ProjectV1 + "/{0}/change-current-version/{1}",
  }

  public static RequestSupplierController = {
    Delete: Application.RequestSupplierOfferV1 + "/{1}",
    CanDeleteRequestSupplierOffer: Application.RequestSupplierOfferV1 + "/{1}/can-delete",
    Create: Application.RequestSupplierOfferV1 + "/{1}/request-to-suppliers/{2}",
    CanDeleteRequestToSupplier: Application.RequestSupplierOfferV1 + "/{1}/request-to-suppliers/{2}",
    DeleteRequestToSupplier: Application.RequestSupplierOfferV1 + "/{1}/request-to-suppliers/{2}",
    AddLine: Application.RequestSupplierOfferV1 + "/{1}/lines/{2}",
    RemoveLine: Application.RequestSupplierOfferV1 + "/{1}/lines/{1}",
    GetAll: Application.RequestSupplierOfferV1 + "/{1}/lines/filter",
    GetSelectedLines: Application.RequestSupplierOfferV1 + "/{1}/selected-lines",
    GetSelectedLinesCount: Application.RequestSupplierOfferV1 + "/{1}/selected-lines/count",
    GetChildren: Application.RequestSupplierOfferV1 + "/{1}/lines/{2}/children/{3}/{4}",
    GetByIds: Application.RequestSupplierOfferV1 + "/{1}/lines/fetch/{2}",
    GetRequestToSuppliers: Application.RequestSupplierOfferV1 + "/{1}/request-to-suppliers",
    SetSuppliersSource: Application.RequestSupplierOfferV1 + "/{1}/request-to-suppliers/set-supplier-source",
    GetActivityCodes: Application.RequestSupplierOfferV1 + "/{1}/lines/activity-code",
    GetThirdPartyToSelect: Application.RequestSupplierOfferV1 + "/{1}/lines/third-party-to-select",
    GenerateExcel: Application.RequestSupplierOfferV1 + "/{1}/excel/{2}/{3}",
    GetConditions: Application.RequestSupplierOfferV1 + "/{1}/supplier-offer/{2}/conditions",
    SetStatus: Application.RequestSupplierOfferV1 + "/{1}/set-status/{2}",
    SyncProposedQuantity: Application.RequestSupplierOfferV1 + "/{1}/sync-proposed-quantity",
    AddNewLinesToAldreadyCreatedSupplierOffer: Application.RequestSupplierOfferV1 + "/{1}/add-new-lines-to-already-created-supplier-offer"
  }

  public static EmailController = {
    CreateEmail: Application.EmailV1 + "/send-mails",
  }

  public static SupplierOfferController = {
    CreateSupplierOffer: Application.SupplierOfferV1 + "/{1}/{2}",
    CreateLine: Application.SupplierOfferV1 + "/{1}/lines",
    GetAll: Application.SupplierOfferV1 + "/{1}/lines/filter",
    GetChildren: Application.SupplierOfferV1 + "/{1}/lines/{2}/children/{3}/{4}",
    Patch: Application.SupplierOfferV1 + "/{1}/{2}/lines/{3}",
    BulkPatch: Application.SupplierOfferV1 + "/{1}/{2}/lines/bulk",
    GetByIds: Application.SupplierOfferV1 + "/{1}/lines/fetch/{2}",
    PriceOfferVersionTotal: Application.SupplierOfferV1 + "/{1}/total",
    PatchSource: Application.SupplierOfferV1 + "/{1}/patch-source/{2}",
    ImportXls: Application.SupplierOfferV1 + "/{1}/importXls",
    CanDelete: Application.SupplierOfferV1 + "/{1}/can-delete",
    CanDeleteLines: Application.SupplierOfferV1 + "/{1}/lines/can-delete",
    Delete: Application.SupplierOfferV1 + "/{1}/delete",
    DeleteLine: Application.SupplierOfferV1 + "/{1}/lines",
    CreateFromItems: Application.SupplierOfferV1 + "/{1}/lines/fromItem",
    DuplicateLine: Application.SupplierOfferV1 + "/{1}/lines/{2}/duplicate",
    RecomputeLines: Application.SupplierOfferV1 + "/{1}/recompute-lines",
  }

  public static TaskController = {
    NewTaskParent: Application.TasksApiV1 + "/{0}/new",
    NewTask: Application.TasksApiV1 + "/new",
    GetAll: Application.TasksApiV1,
    GetTask: Application.TasksApiV1 + "/{0}",
    GetChildren: Application.TasksApiV1 + "/{0}/tasks",
    UpdateTask: Application.TasksApiV1,
    PatchTask: Application.TasksApiV1 + "/{0}",
    DeleteTask: Application.TasksApiV1 + "/{0}",
    GetFiles: Application.TasksApiV1 + "/{0}/files",
    DeleteFile: Application.TasksApiV1 + "/{0}/files",
    GetCounter: Application.TasksApiV1 + "/counter",
  }

  public static PriceRepartitionController = {
    GetPriceRepartition: Application.PriceRepartitionV1 + "/{1}",
    CreatePriceRepartitions: Application.PriceRepartitionV1 + "/{1}",
    DeletePriceRepartitions: Application.PriceRepartitionV1 + "/{1}",
    ComputePAR: Application.PriceRepartitionV1 + "/compute-par/{1}",
    GetLinesForRepartition: Application.PriceRepartitionV1 + "/{1}/lines-for-repartitions/{2}",
    DeletePriceOfferRepartition: Application.PriceRepartitionV1 + "/{1}",
    HasChildren: Application.PriceRepartitionV1 + "/{1}/has-metering-children",
    CanAddPriceRepartition: Application.PriceRepartitionV1 + "/{1}/can-add-price-repartition",
    GetPercentageRepartition: Application.PriceRepartitionV1 + "/get-percentage-repartition",
  }
  public static ThirdPartyController = {
    InviteToLancelot: Application.ThirdPartyV1 + "/{0}/invite",
    CancelInviteToLancelot: Application.ThirdPartyV1 + "/{0}/invitations/{1}"
  }

  public static MeteringController = {
    GetAllEnum: Application.MeteringV1 + '/get-all-enum'
  }
  public static InvoiceController = {
    GetLastInvoiceDate: Application.InvoiceV1 + "/{0}/get-last-invoice-date/{1}",
    ValidateInvoice: Application.InvoiceV1 + "/{0}/validate-invoice",
    GetAll: Application.InvoiceV1 + "/{0}/lines/filter",
    CreateLine: Application.InvoiceV1 + "/{0}/lines",
    GetChildren: Application.InvoiceV1 + "/{0}/lines/{1}/children/{2}",
    GetByIds: Application.InvoiceV1 + "/{0}/lines/fetch",
    Move: Application.InvoiceV1 + "/{0}/lines/move",
    DuplicateLine: Application.InvoiceV1 + "/{0}/lines/{1}/duplicate",
    Paste: Application.InvoiceV1 + "/{0}/lines/paste",
    DeleteLine: Application.InvoiceV1 + "/{0}/lines",
    CreateFromItems: Application.InvoiceV1 + "/{0}/lines/fromItem",
    Patch: Application.InvoiceV1 + "/{0}/lines/{1}",
    BulkPatch: Application.InvoiceV1 + "/{0}/lines/bulk",
    CreateOrUpdateComment: Application.InvoiceV1 + "/selling-invoice-line-comments/{0}",
    DeleteComment: Application.InvoiceV1 + "/selling-invoice-line-comments/{0}",
    GetComment: Application.InvoiceV1 + "/selling-invoice-line-comments/{0}",
    RecomputeLines: Application.InvoiceV1 + "/{0}/recompute-lines",
    CreateInvoiceFromProgressStatement: Application.InvoiceV1 + "/create-from-progress-statement/{0}",
    GetLastChapterId: Application.InvoiceV1 + "/{0}/get-last-chapter-id",
    CancelInvoice: Application.InvoiceV1 + "/{0}/cancel-invoice/{1}"
  }

  public static PontoController = {
    SyncBankAccounts: Application.Ponto + "/accounts/sync",
    SyncBankTransactionsAndAutoReconcile: Application.Ponto +
      "/accounts/transactions/sync_reconcile",
    Onboarding: Application.Ponto + "/onboarding",
    IgnoreTransactions: Application.Ponto + "/accounts/transactions/ignore",
  };

  public static ReconciliationController = {
    LinkInvoice: Application.ReconciliationV1 + "/{0}/link/{1}",
    LinkInvoices: Application.ReconciliationV1 + "/{0}/links",
    UnlinkInvoice: Application.ReconciliationV1 + "/{0}",
  };

  public static NotificationController = {
    CreateNotifications: Application.NotificationV1 + "/createNotification",
  }
}

export enum UserRoleId {
  Administrator = 60,
  Buyer = 61,
  Worker = 62,
  WorkingSiteManager = 63,
}

export class Roles {
  public static Administrator = "Administrator";
}
export class Extensions {
  public static All = "[a-zA-Z0-9_]*";
  public static File = ".jpeg,.png,.jpg,.svg,.gif,.doc,.docx,.pdf,.ppt,.pptx,.txt,.xls,.xlsx";
  public static Image = ".jpeg,.png,.jpg";
  public static Office = ".doc,.docx,.ppt,.pptx,.xls,.xlsx";
}


export class MerlinCustomEvent {
  public static VersionNameChange = "VERSION_NAME_CHANGE";
  public static CurrentVersionChange = "CURRENT_VERSION_CHANGE";
}
export class AGGridColumnsWidth {
  public static IsSelected = 40;
}

export enum ExternalEmailType {
  In, Out
}

export enum EnumerationTypes {
  Language = 0,
  Civility = 2,
  CompanyType = 3,
  UnitType = 4,
  PriceOfferStatus = 5,
  ThirdPartyType = 6,
  PartnerType = 7,
  ThirdPartyTag = 8,
  SupplierDelivery = 9,
  AddressType = 10,
  CompanyFunction = 11,
  UserRole = 13,
  ItemFeature = 14,
  ContainerType = 15,
  ItemType = 16,
  UnitPriceSourceType = 17,
  PriceOfferLineType = 18,
  ItemDeliveryType = 20,
  ActivityCode = 21,
  PriceOfferLineCategory = 22,
  TaskStatus = 23,
  TaskType = 24,
  TaskCriticityLevel = 25,
  EmailType = 26,
  FileType = 27,
  CheckpointProjectStage = 28,
  BatchProcessResultStatus = 30,
  EmailContext = 31,
  ContactPersonRole = 32,
  SupplierOfferType = 33,
  PriceOfferLineTag = 34,
  SubcontractorOfferSource = 35,
  SupplierOfferLineStatus = 36,
  ModifiedQuantityStatus = 37,
  SupplierOfferLineType = 39,
  TaskTag = 40,
  DocumentTag = 41,
  ProjectPhase = 42,
  ThirdPartyContractStatus = 43,
  BuyingClientValidationStatus = 44,
  ProjectStatus = 45,
  UserFunctions = 46,
  DocumentType = 47,
  ContentType = 48,
  ProjectInternalRole = 49,
  ProjectExternalRole = 50,
  ProjectCancellationReason = 51,
  ProjectType = 52,
  ProjectPartnerRole = 53,
  RequestToSupplierStatus = 54,
  DocumentModule = 55,
  DocumentStatus = 56,
  PriceOfferMobilizationDelay = 57,
  PriceOfferPaymentDelay = 58,
  PriceOfferDeposit = 59,
  PriceOfferPriceRevisionTechnic = 60,
  AdditionalWorkType = 61,
  ClientAdditionalWorkStatusType = 62,
  LancelotInvitationStatus = 63,
  Approval = 64,
  ProjectClass = 65,
  ProgressStatementVersionStatus = 66,
  ThirdPartyContractType = 67,
  FileContext = 68,
  SellingInvoiceType = 69,
  ProgressStatementQuantityType = 70,
  SellingInvoiceStatus = 71,
  PaymentStatus = 72,
  TransactionStatus = 73,
  NotificationType = 74,
  BuildingCosts = 75,
};

export enum ReportType {
  Docx, Pdf, Zip, Xlsx
};

export enum NodeSelectionState {
  None,
  Partial,
  All
}

export enum PriceOfferLineMoveAction {
  Up = "Up",
  Down = "Down",
  Into = "Into"
}

export enum ItemLinkMode {
  MultipleCreation = "MultipleCreation",
  SingleLink = "SingleLink"
}

export enum TreeDisplayMode {
  Tree = "Tree",
  Flat = "Flat"
}

export class Routes {
  public static MenuGroupTitle = {
    Users: "menu.users",
    EnumerationTypes: "menu.enumerationTypes",
    ThirdParties: "menu.thirdParties",
    Projects: "menu.projects",
    Tasks: "menu.tasks",
    ImportMetering: "menu.importMeter",
    NotificationsSent: "menu.notificationSent",
    Data: "menu.data",
    MyAccount: "menu.myaccount",
    ModuleTask: "menu.moduleTask",
    Parameters: "menu.parameters",
    Invoice: "menu.financialCenter",
    Communications: "menu.communications"
  }

  public static MenuSubGroup = {
    Template: "menu.template",
    Items: "menu.items",
    Merlin: "menu.merlin",
    Project: "menu.projects",
    ThirdParty: "menu.thirdParties",
    PriceOffer: "menu.priceOffer",
    Task: "menu.task",
    Other: "menu.other",
    Email: "menu.emails",
    File: "menu.files",
    Purchases: "menu.purchases",
    Invoices: "menu.financialCenter"
  }
  public static RoutesList = {
    ThirdPartyList: "home",
    ProjectList: "Project",
    ItemList: "Item",
    ItemFamiliesList: "ItemFamilies",
    EmailTemplateMerlinList: "EmailTemplateMerlin",
    CheckPointTemplateList: "CheckPointTemplate",
    TaskJobList: "TaskJob",
    NotificationSentList: "NotificationSentList",
    ExternalEmailList: "ExternalEmailList",
    UserList: "UserList",
    ApplicationParametersList: "ApplicationParametersList",
    MyEntreprise: "MyEntreprise",
    FileTypeList: "FileTypeList",
    EmailTypeList: "EmailTypeList",
    CivilityList: "CivilityList",
    CompanyTypeList: "CompanyTypeList",
    ThirdPartyTypeList: "ThirdPartyTypeList",
    SupplierDeliveryList: "SupplierDeliveryList",
    CompanyFunctionList: "CompanyFunctionList",
    UnitTypeList: "UnitTypeList",
    UnitsList: "UnitsList",
    ContainerTypeList: "ContainerTypeList",
    UnitPriceSourceTypeList: "UnitPriceSourceTypeList",
    ItemDeliveryTypeList: "ItemDeliveryTypeList",
    ItemFeatureList: "ItemFeatureList",
    PriceOfferStatusList: "PriceOfferStatusList",
    PriceOfferLineTypeList: "PriceOfferLineTypeList",
    MarketTypeList: "MarketTypeList",
    ActivityCodeList: "ActivityCodeList",
    VatLevelList: "VatLevelList",
    TaskTypeList: "TaskTypeList",
    TaskCriticityLevelList: "TaskCriticityLevelList",
    TaskTagList: "TaskTagList",
    WorkingSitePreparationTemplateList: "WorkingSitePreparationTemplateList",
    PartnerTypeList: "PartnerTypeList",
    ThirdPartyTagList: "ThirdPartyTagList",
    PriceOfferLineTagList: "PriceOfferLineTagList",
    PriceOfferMobilizationDelayList: "PriceOfferMobilizationDelayList",
    PriceOfferPaymentDelayList: "PriceOfferPaymentDelayList",
    PriceOfferDepositList: "PriceOfferDepositList",
    PriceOfferPriceRevisionTechnicList: "PriceOfferPriceRevisionTechnicList",
    TaskList: "TaskList",
    DocumentTag: "DocumentTag",
    DocumentType: "DocumentType",
    ParticularConditionsList: "ParticularConditionsList",
    ContentTemplate: "ContentTemplate",
    UserFunctionList: "UserFunction",
    ProjectExternalRole: "ProjectExternalRole",
    ProjectInternalRole: "ProjectInternalRole",
    ProjectCancellationReason: "ProjectCancellationReason",
    ProjectStatus: "ProjectStatus",
    ProjectType: "ProjectType",
    DefaultInternalProjectUser: "DefaultInternalProjectUser",
    ProjectPartnerRole: "ProjectPartnerRole",
    Parameters: "Parameters",
    InvoiceList: "InvoiceList",
    CreditNoteList: "CreditNoteList",
    BillingVoucherList: "BillingVoucherList",
    BankAccount: "BankAccount",
    PaymentList: "PaymentList",
    BlackListedDomainMatchingList: "BlackListedDomainMatchingList",
    BuildingCostsList: "BuildingCostsList",
  }
  public static RoutesDetail = {
    ImportMetering: "ImportMetering",
    Metadata: 'Metadata',
    MyAccount: "MyAccount"
  }
}

export class Language {
  public static FR = "FR";
  public static NL = "NL";
  public static EN = "EN";
}

export class LanguageId {
  public static FR = 1;
  public static NL = 2;
  public static EN = 3;
}

export class EntityTypeNames {
  public static Language = "Language"
  public static AgGridView = "AgGridView";
  public static EnumerationType = "EnumerationType";
  public static EnumerationTypeMerlin = "EnumerationTypeMerlin";
  public static EnumerationTypeColor = "EnumerationTypeColor";
  public static Locality = "Locality";
  public static Country = "Country";
  public static User = "User";
  public static MerlinUser = "MerlinUser";
  public static ThirdParty = "ThirdParty";
  public static Unit = "Unit";
  public static UnitConvertion = "UnitConvertion";
  public static ApplicationParameter = "ApplicationParameter";
  public static Address = "Address";
  public static ThirdPartyTag = "ThirdPartyTag";
  public static ThirdPartyType = "ThirdPartyType";
  public static Project = "Project";
  public static ProjectUsers = "ProjectUsers";
  public static ProjectContact = "ProjectContact";
  public static PriceOffer = "PriceOffer";
  public static PriceOfferVersion = "PriceOfferVersion";
  public static ContactPerson = "ContactPerson";
  public static TaskJob = "TaskJob";
  public static TaskJobTag: "TaskJobTag"
  public static TaskJobFile = "TaskJobFile";
  public static Action = "Action";
  public static PriceOfferLine = "PriceOfferLine";
  public static VATLevel = "VATLevel";
  public static Item = "Item";
  public static ItemConditioning = "ItemConditioning";
  public static ItemItemFeature = "ItemItemFeature";
  public static ItemPrice = "ItemPrice";
  public static ItemPriceFromDate = "ItemPriceFromDate";
  public static ItemActivityCode = "ItemActivityCode";
  public static ItemItemDeliveryType = "ItemItemDeliveryType";
  public static ItemFamily = "ItemFamily";
  public static ItemItemFamily = "ItemItemFamily";
  public static ItemComposition = "ItemComposition";
  public static ItemCompositionLine = "ItemCompositionLine";
  public static InformationMessage = "InformationMessage";
  public static InformationMessageDistribution = "InformationMessageDistribution";
  public static EmailTemplateMerlin = "EmailTemplateMerlin";
  public static EmailTemplate = "EmailTemplate";
  public static EmailTemplateFile = "EmailTemplateFile";
  public static MergeContentToken = "MergeContentToken";
  public static NotificationSent = "NotificationSent";
  public static NotificationSentFile = "NotificationSentFile";
  public static UnitAlias = "UnitAlias";
  public static MarketType = "MarketType";
  public static MarketTypeAlias = "MarketTypeAlias";
  public static MeasurementsFile = "MeasurementsFile";
  public static CheckPointTemplate = "CheckPointTemplate";
  public static PriceOfferLineDTO = "PriceOfferLineDTO";
  public static ItemDocument = "ItemDocument";
  public static TaskDocument = "TaskDocument";
  public static PriceOfferLineDocument = "PriceOfferLineDocument";
  public static EmailType = "EmailType";
  public static FileType = "FileType";
  public static PriceOfferLineTag = "PriceOfferLineTag";
  public static RequestSupplierOffer = "RequestSupplierOffer";
  public static WorkQuotationVersionRequestSupplierOffer = "WorkQuotationVersionRequestSupplierOffer";
  public static BuyingRequestSupplierOffer = "BuyingRequestSupplierOffer";
  public static RequestSupplierOfferLine = "RequestSupplierOfferLine";
  public static RequestToSupplier = "RequestToSupplier";
  public static SupplierOffer = "SupplierOffer";
  public static SupplierOfferLine = "SupplierOfferLine";
  public static RequestSupplierOfferFile = "RequestSupplierOfferFile";
  public static RequestToSupplierContactPerson = "RequestToSupplierContactPerson";
  public static Role = "Role";
  public static Document = "Document";
  public static DocumentView = "DocumentView";
  public static ParticularCondition = "ParticularCondition";
  public static UserQuote = "UserQuote";
  public static UserWorkingSite = "UserWorkingSite";
  public static UserBuying = "UserBuying";
  public static WorkingSitePreparationTemplate = "WorkingSitePreparationTemplate";
  public static ContentTemplate = "ContentTemplate";
  public static ProjectDocument = "ProjectDocument";
  public static PriceOfferVersionDocument = "PriceOfferVersionDocument";
  public static ThirdPartyContract = "ThirdPartyContract";
  public static ThirdPartyContractLine = "ThirdPartyContractLine";
  public static BuyingContract = "BuyingContract";
  public static ThirdPartyContractContractSpecificCondition = "ThirdPartyContractContractSpecificCondition";
  public static WorkQuotationVersion = "WorkQuotationVersion";
  public static WorkQuotationVersionLine = "WorkQuotationVersionLine";
  public static ThirdPartyContractContentTemplate = "ThirdPartyContractContentTemplate";
  public static PriceOfferVersionLine = "PriceOfferVersionLine";
  public static BuyingLine = "BuyingLine";
  public static DefaultInternalProjectUser = "DefaultInternalProjectUser";
  public static OfferVersionSignatory = "OfferVersionSignatory";
  public static ProjectUserPriceOffer = "ProjectUserPriceOffer";
  public static ProjectUserBuying = "ProjectUserBuying";
  public static ProjectUserExecution = "ProjectUserExecution";
  public static ProjectPartner = "ProjectPartner";
  public static ClientAdditionalWork = "ClientAdditionalWork";
  public static ClientProgressStatementLine = "ClientProgressStatementLine";
  public static ProgressStatement = "ProgressStatement";
  public static ProgressStatementView = "ProgressStatementView";
  public static ProgressStatementVersion = "ProgressStatementVersion";
  public static ComputedRequestToSupplier = "ComputedRequestToSupplier";
  public static AdditionalWork = "AdditionalWork";
  public static ClientAdditionalWorkView = "ClientAdditionalWorkView";
  public static ClientAdditionalWorkVersion = "ClientAdditionalWorkVersion";
  public static AdditionalWorkVersionSignatory = "AdditionalWorkVersionSignatory";
  public static ContractSignatory = "ContractSignatory";
  public static ApplicationParameterProgressStatementDocument = "ApplicationParameterProgressStatementDocument";
  public static SellingInvoice = "SellingInvoice";
  public static ProgressStatementSignatory = "ProgressStatementSignatory";
  public static InvoiceSignatory = "InvoiceSignatory";
  public static BankAccount = "BankAccount";
  public static BankTransaction = "BankTransaction";
  public static ExternalEmail = "ExternalEmail";
  public static BlackListedDomainMatching = "BlackListedDomainMatching";

  public static ResourceNames = {
    Language: "Languages",
    AgGridView: "AgGridViews",
    EnumerationType: "EnumerationTypes",
    EnumerationTypeMerlin: "EnumerationTypeMerlins",
    EnumerationTypeColor: "EnumerationTypeColors",
    Locality: "Localities",
    Country: "Countries",
    User: "Users",
    MerlinUser: "MerlinUsers",
    ThirdParty: "ThirdParties",
    Unit: "Units",
    UnitConvertion: "UnitConvertions",
    ApplicationParameter: "ApplicationParameter",
    Address: "Addresses",
    ThirdPartyTag: "ThirdPartyTags",
    ThirdPartyType: "ThirdPartyTypes",
    Project: "Projects",
    ProjectUsers: "ProjectUsers",
    ProjectContact: "ProjectContacts",
    PriceOffer: "PriceOffers",
    PriceOfferVersion: "PriceOfferVersions",
    ContactPerson: "ContactPersons",
    TaskJob: "TaskJobs",
    TaskJobTag: "TaskJobTags",
    TaskJobFile: "TaskJobFiles",
    Action: "Actions",
    Item: "Items",
    ItemConditioning: "ItemConditionings",
    ItemItemFeature: "ItemItemFeatures",
    ItemPrice: "ItemPrices",
    ItemPriceFromDate: "ItemPriceFromDates",
    ItemActivityCode: "ItemActivityCodes",
    ItemItemDeliveryType: "ItemItemDeliveryTypes",
    ItemFamily: "ItemFamilies",
    ItemItemFamily: "ItemItemFamilies",
    ItemComposition: "ItemCompositions",
    ItemCompositionLine: "ItemCompositionLines",
    InformationMessage: "InformationMessages",
    InformationMessageDistribution: "InformationMessageDistributions",
    PriceOfferLine: "PriceOfferLines",
    VATLevel: "VATLevels",
    EmailTemplateMerlin: "EmailTemplatesMerlin",
    EmailTemplate: "EmailTemplates",
    EmailTemplateFile: "EmailTemplateFiles",
    MergeContentToken: "MergeContentTokens",
    NotificationSent: "NotificationSents",
    NotificationSentFile: "NotificationSentFiles",
    UnitAlias: "UnitAliases",
    MarketType: "MarketTypes",
    MarketTypeAlias: "MarketTypeAliases",
    MeasurementsFile: "MeasurementsFiles",
    CheckPointTemplate: "CheckPointsTemplates",
    PriceOfferLineDTO: "PriceOfferLineDTOs",
    ItemDocument: "ItemDocuments",
    TaskDocument: "TaskDocuments",
    PriceOfferLineDocument: "PriceOfferLineDocuments",
    EmailType: "EmailTypes",
    FileType: "FileTypes",
    PriceOfferLineTag: "PriceOfferLineTags",
    RequestToSupplier: "RequestToSuppliers",
    RequestSupplierOfferFile: "RequestSupplierOfferFiles",
    SupplierOffer: "SupplierOffers",
    RequestToSupplierContactPerson: "RequestToSupplierContactPersons",
    Role: CoreEntityTypeNames.ResourceNames.Role,
    Document: "Documents",
    DocumentView: "DocumentViews",
    ParticularCondition: "ParticularConditions",
    UserQuote: "UserQuotes",
    UserWorkingSite: "UserWorkingSites",
    UserBuying: "UserBuyings",
    WorkingSitePreparationTemplate: "WorkingSitePreparationTemplates",
    ContentTemplate: "ContentTemplates",
    ProjectDocument: "ProjectDocuments",
    PriceOfferVersionDocument: "PriceOfferVersionDocuments",
    ThirdPartyContract: "ThirdPartyContracts",
    ThirdPartyContractLine: "ThirdPartyContractLines",
    BuyingContract: "BuyingContracts",
    ThirdPartyContractContractSpecificCondition: "ThirdPartyContractContractSpecificConditions",
    WorkQuotationVersion: "WorkQuotationVersions",
    WorkQuotationVersionRequestSupplierOffer: "WorkQuotationVersionRequestSupplierOffers",
    BuyingRequestSupplierOffer: "BuyingRequestSupplierOffers",
    WorkQuotationVersionLine: "WorkQuotationVersionLines",
    ThirdPartyContractContentTemplate: "ThirdPartyContractContentTemplates",
    WorkQuotationVersionSupplierOffer: "WorkQuotationVersionSupplierOffers",
    BuyingSupplierOffer: "BuyingSupplierOffers",
    BuyingLine: "BuyingLines",
    PriceOfferVersionLine: "PriceOfferVersionLines",
    DefaultInternalProjectUser: "DefaultInternalProjectUsers",
    OfferVersionSignatory: "OfferVersionSignatories",
    ProjectUserPriceOffer: "ProjectUserPriceOffers",
    ProjectUserBuying: "ProjectUserBuyings",
    ProjectUserExecution: "ProjectUserExecutions",
    ClientAdditionalWork: "ClientAdditionalWorks",
    ClientProgressStatementLine: "ClientProgressStatementLines",
    ProgressStatement: "ProgressStatements",
    ProgressStatementView: "ProgressStatementViews",
    ProgressStatementVersion: "ProgressStatementVersions",
    ComputedRequestToSupplier: "ComputedRequestToSuppliers",
    AdditionalWork: "AdditionalWorks",
    ClientAdditionalWorkView: "ClientAdditionalWorkViews",
    ClientAdditionalWorkVersion: "ClientAdditionalWorkVersions",
    AdditionalWorkVersionSignatory: "AdditionalWorkVersionSignatories",
    ContractSignatory: "ContractSignatories",
    ApplicationParameterProgressStatementDocument: "ApplicationParameterProgressStatementDocuments",
    SellingInvoice: "SellingInvoices",
    ProgressStatementSignatory: "ProgressStatementSignatories",
    InvoiceSignatory: "InvoiceSignatories",
    BankAccount: "BankAccounts",
    BankTransaction: "BankTransactions",
    ExternalEmail: "ExternalEmails",
    BlackListedDomainMatching: "BlackListedDomainMatchings",
    RequestSupplierOfferLine: "RequestSupplierOfferLines",
    SupplierOfferLine: "SupplierOfferLines"
  };
}

export class TimeInMilliseconds {
  public static HourInMilliseconds: number = 3600000;
  public static DayInMilliseconds: number = 86400000;
  public static WeekInMilliseconds: number = 604800000;
  public static MonthInMiliseconds: number = 2592000000;
}

export class ProjectDetailTab {
  public static GeneralInformation: string = "tab1";
  public static InternalTeam: string = "tab2";
  public static ProjectPartners: string = "tab4";
  public static ExternalTeam: string = "tab5";
}

export class PrecisionParameter {
  public static MaxNbDecimalForQuantityDisplay: number = 3;
  public static MaxNbDecimalForPriceDisplay: number = 4;
}

export enum UnitType {
  Time = 23
}

export enum UnitId {
  Hour = 1
}

export enum ThirdPartyType {
  Lead = 30,
  Client = 31,
  Supplier = 32,
  Partner = 33,
  TemporaryCompany = 34,
  Contact = 35,
  SubContractor = 36
}
export enum SupplierDeliveryId {
  ItemDelivery = 40,
  SubContractor = 41
}

export enum AddressType {
  Main = 50,
  Invoicing = 51,
  WorkingSite = 52
}

export enum ItemType {
  Composed = 70,
  Supply = 71,
  Service = 72
}
export enum PriceOfferStatusId {
  Open = 260,
  ToBeValidated = 261,
  ToSend = 262,
  WaitingAcceptation = 263,
  Accepted = 264,
  Rejected = 265,
  Canceled = 266
}

export enum UnitPriceSourceType {
  SubContractorOffer = 80,
  ItemDataBase = 81,
  Manual = 82,
}

export enum PriceOfferLineType {
  Normal = 90,
  Option = 91,
  Variant = 92,
  Omission = 93,
  Reduction = 94,
  FixedPrice = 95,
}


export enum ItemDeliveryType {
  Internal = 110,
  SubContractor = 111,
  Supplier = 112,
}

export enum PriceOfferLineCategory {
  Chapter = 120,
  Comment = 121,
  Data = 122,
  NoCategory = 124
}

export enum NotificationType {
  Email, SMS
}
export enum NotificationStatus {
  ToSend, Sending, Sended, Failed, InvalidAddress, NoTemplate, Cancelled
}

export enum MarketType {
  QP = 1,
  QF = 2,
  SAJ = 3,
  PG = 4,
  PM = 5,
}

export enum EmailContextId {
  ThirdParty = 160,
  Project = 161,
  SupplierOfferRequest = 162,
  SupplierOfferRequestForBuying = 163,
  SupplierOfferConfirmationForBuying = 164,
  SupplierContract = 165,
  SupplierAmendment = 166,
  PriceOffer = 167,
  Additionalwork = 168,
  ClientProgressStatement = 169,
  SubContractorProgressStatement = 550,
  SubContractorContract = 560,
  SubContractorAmendment = 561,
  Invoice = 578,
  BillingVoucher = 612
}

export enum ContactPersonRoleId {
  RequestOffer = 170,
  Invoicing = 171
}
export enum SupplierOfferTypeId {
  ForPriceOffer = 180,
  ForWorkingSite = 181,
}
export enum SubcontractorOfferSourceId {
  Portal = 190,
  Import = 191,
  Manual = 192,
  FromClientOffer = 193,
}
export enum TaskCriticityLevelId {
  Urgent = 240,
  High = 241,
  Medium = 242,
  Low = 243,
}
export enum TaskStatusId {
  Open = 130,
  InProgress = 131,
  Finalized = 132,
  Cancelled = 133,
}

export enum PriceMarginType {
  Percent = "Percent",
  Coeff = "Coeff"
}


export enum ImportContext {
  Metering = "metering"
}

export enum PriceOrigin {
  NoOrigin, Manual, Item, Offer, Estim
}

export enum ProjectPhaseId {
  Project = 250,
  PriceOffer = 251,
  Preparation = 252,
  Buying = 253,
  Planning = 254,
  WorkingSite = 255,
  Reception = 256,
  SAV = 257,
}

export enum ThirdPartyContractStatusId {
  Draft = 270,
  InProcess = 271,
  Validated = 272,
  Cancelled = 273
}

export enum ProjectStatusId {
  Open = 290,
  Offer = 291,
  OfferSigned = 292,
  Lost = 293,
  Abandon = 294,
  StandBy = 295,
  Execution = 296,
  Closed = 297,
  SAV = 298
}

export enum ProjectInternalRoleId {
  Sales = 340,
  PriceOffer = 341,
  Preparation = 342,
  Buying = 343,
  Execution = 344,
  Secretary = 345,
  Finance = 346,
  ExecutiveManagement = 347,
  TechnicalManagement = 348,
  Security = 349
}

export enum ProjectTypeId {
  Public = 358,
  Private = 359
}

export enum ProjectPartnerRoleId {
  Architect = 360,
  IngineeringOffice = 361
}

export enum UserFunctionId {
  Signatory = 300
}

export enum DocumentTypeId {
  ClientOffer = 310,
  ClientOfferFromWorkCotation = 311,
  ClientOfferHeadingPage = 312
}

export enum ContentTypeId {
  ClientOfferCoverLetter = 330,
  SpecificBuyingCondition = 331,
  AnnexOfferClient = 332,
  AdditionalWorkCoverLetter = 333,
  AdditionalWorkAnnex = 334,
  ClientProgressStatementCoverLetter = 335,
  ClientProgressStatementAnnex = 336,
  SubContractorProgressStatementCoverLetter = 337,
  SubContractorProgressStatementAnnex = 338,
  SupplierContractCoverLetter = 339,
  SupplierContractAnnex = 553,
  SupplierAmendmentCoverLetter = 554,
  SupplierAmendmentAnnex = 555,
  SubContractorContractCoverLetter = 556,
  SubContractorContractAnnex = 557,
  SubContractorAmendmentCoverLetter = 558,
  SubContractorAmendmentAnnex = 559,
  InvoiceCoverLetter = 576,
  InvoiceAnnex = 577,
  BillingVoucherCoverLetter = 610,
  BillingVoucherAnnex = 611
}

export enum RequestToSupplierStatusId {
  Unprocessed = 370,
  NotFollowed = 371,
  Followed = 372,
  OfferReceived = 373,
  InProcess = 374,
  Sent = 375,
  Validated = 376,
  PartiallyValidated = 377
}

export enum DocumentModuleId {
  Project = 380,
  Article = 381,
  ThirdParty = 382
}

export enum DocumentStatusId {
  Draft = 390,
  Definitive = 391,
  Obsolete = 392,
  Signed = 393
}


export enum AdditionalWorkTypeId {
  AdditionalWorkToBeValidated = 420,
  AdditionalWorkValidated = 421,
  AdditionalWorkRejected = 422,
  Extra = 423,
}

export enum ClientAdditionalWorkStatusTypeId {
  Draft = 430,
  Sent = 431,
  Validated = 432,
  Rejected = 433,
}

export enum LancelotInvitationStatusId {
  Pending = 440,
  Accepted = 441,
  Expired = 442,
  Sent = 443,
  Failed = 444,
  Canceled = 445,
}

export enum ProgressStatementVersionStatusId {
  InProgress = 530,
  Sent = 531,
  Accepted = 532,
  Disputed = 533
}

export enum ExportExcelMode {
  OriginalExcelModel = 1,
  InternalExcelModel = 2
}

export enum ImportMetering {
  Replace = 1,
  NewFile = 2,
  NewSheet = 3
}

export enum CommonMeterginDataLineMenuItems {
  ADD_LINE = "ADD_LINE",
  ADD_LINE_UP = "ADD_LINE_UP",
  ADD_LINE_DOWN = "ADD_LINE_DOWN",
  ADD_LINE_CHILD = "ADD_LINE_CHILD",
  DUPLICATE = "DUPLICATE",
  COPY = "COPY",
  PASTE = "PASTE",
  MOVES = "MOVES",
  MOVE_UP = "MOVE_UP",
  MOVE_DOWN = "MOVE_DOWN",
  MOVE_CHILD = "MOVE_CHILD",
  LINE_CATEGORY = "LINE_CATEGORY",
  POST_TYPE = "POST_TYPE",
  ADD_DOCUMENTS = "ADD_DOCUMENTS",
  ADD_ITEM = "ADD_ITEM",
  ADD_ITEM_UP = "ADD_ITEM_UP",
  ADD_ITEM_DOWN = "ADD_ITEM_DOWN",
  ADD_ITEM_CHILD = "ADD_ITEM_CHILD",
  ITEM_LINK = "ADD_ITEM_LINK",
  ITEM_UNLINK = "ADD_ITEM_UNLINK",
  PRICE_REPARTITION_ADD = "PRICE_REPARTITION_ADD",
  PRICE_REPARTITION_EDIT = "PRICE_REPARTITION_EDIT",
  PRICE_REPARTITION_DELETE = "PRICE_REPARTITION_DELETE",
  HIDE = "HIDE",
  DELETE = "DELETE",
  COMMENT_CELL = "COMMENT_CELL"
}

export enum Asset {
  PLACEHOLDER_IMAGE = 'images/placeholder-image.png'
}

export enum RefToDisplay {
  MerlinRef, // Calculated ref
  OriginalExcelRef, // Original ref from excel file
  OriginalMerlinRef, // Original ref from a copied line (Supplier or additional work)
  SignedOfferRef // Ref of signed offer (Achat et execution)
}

export enum LocalStorageName {
  PriceOffer = 'priceOffer',
  WorkQuotation = 'workQuotation',
  SellingInvoice = 'sellingInvoce'
}

export enum FileTypeId {
  ClientOffer = 320,
  ClientOfferFromWorkCotation = 321,
  ClientOfferHeadingPage = 322,
  Plan = 323,
  Planning = 324,
  Specifications = 325,
  List = 326,
  Presentation = 327,
  OrderForm = 328,
  DeliveryForm = 329,
  ExitTicket = 400,
  Contract = 401,
  AdministrativeClauses = 402,
  QuantitySurvey = 403,
  CallForTender = 404,
  Comparison = 405,
  Report = 406,
  Invoice = 407,
  CreditNote = 408,
  EA = 409,
  Offer = 410,
  DataSheet = 411,
  Procedure = 412,
  Questionnaire = 413,
  InternalMemo = 414,
  SupplierOffer = 415,
}

export enum ThirdPartyContractTypeId {
  BaseContract = 540,
  Amendment = 541
}
export enum SellingInvoiceTypeId {
  Invoice = 562,
  CreditNote = 563,
  BillingVoucher = 579,
}
export enum SellingInvoiceStatusId {
  Draft = 564,
  Validated = 565,
  Cancelled = 566
}
export enum PaymentStatusId {
  NotPaid = 567,
  Paid = 568,
  PartiallyPaid = 569,
  InCollection = 570,
  Reminder1 = 571,
  Reminder2 = 572,
  Reminder3 = 573,
  Reminder4 = 574,
}

export enum ProgressStatementQuantityTypeId {
  PlannedQuantity = 580,
  DeltaQuantityDone = 581,
  DeltaQuantityDonePercentage = 582,
  AbsoluteQuantityDone = 583,
  AbsoluteQuantityDonePercentage = 584,
  UnitPrice = 585
}

export enum PriceOfferErrorCode {
  NoError,
  Unknown,
  TotalInconsistency
}

export enum ImportLineErrorType {
  NoError,
  ChildrenHasError,
  ChildrenHasWarning,

  //Error
  BrokenOrder = 100,

  //Warning
  EmptyChapter = 200,
  DetailledWithDifferentMarketType,
  DetailledWithFM,
  AutoChapter,
  DetailledWithDifferentUnitType
}

export enum NotificationTypeId {
  Chat = 600,
  ProjectTeamAsssignement = 601,
  TaskResponsibleAssignement = 602,
  ProjectEnterExecution = 603,
  TaskIsDone = 604,
  EmailSentError = 605
}

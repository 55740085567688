$black-100: #000000;
$black-50: #808080;
$black-35: #a6a6a6;
$black-25: #bfbfbf;
$black-10: #e6e6e6;
$black-5: #f2f2f2;
$black-4: #f4f4f4;
$black-3: #f7f7f7;
$black-0: #ffffff;

$blue-darkest: #1f2245;
$merlin-blue-dark: #172aa5;
$blue-mid: #3c54e4;
// $blue-light: #9dc3ea;

$purple-dark: #6b7cea;
$purple-mid: #98a6ff;
$purple-light: #cbd3ff;
$purple-lighter: #e5e9ff;
$purple-very-light: #f2f4fe;
$purple-lightest: #f8f9ff;
$purple-7: #69709c;

$gray-darker: #262626;
$merlin-gray-dark: #3c3c3c;
$gray-mid: #7d7d7d;
$gray-light: #a9a9a9;
$gray-lighter: #dddddd;
$gray-very-light: #efefef;
$gray-lightest: #f8f8f8;

$red-warning: #ff0000;
$red-mid: #ffafaf;
$red-light: #feefef;
$ui-green: #84dc88;
$green-valid: #15ce1e;
$merlin-orange-dark: #ff5c00;

$yellow-warning: #efef8d;
$yellow-warning-light: #ebebc7;
// couleur composant

$background-footer: $purple-very-light;

/* FRAMEWORK */

$base-color: $black-100;
$gray-dark: $black-100;
$font-family: "Inter", sans-serif;
$font-family-mono: $font-family;
$font-family-serif: $font-family;
$base-font-family: $font-family;
$font-family-sans-serif: $font-family;
$navbar-font-family: $font-family;

$ui-viewport-header-height: 0px;

$ribbon-height: 40px;
$min-desktop-width: 1100px;

$icomoon-font-family: "document-type" !default;
$icomoon-font-path: "font" !default;

$font-color: rgb(70, 77, 105);
$no-material: rgb(250, 250, 130);
$planned: lightblue;

$navbar-text-color: $purple-light;
$navbar-active-text-color: white;
$navbar-icon-color: $purple-dark;
$navbar-active-icon-color: white;
$navbar-floating-menu-background: $blue-mid;
//$navbar-background: no-repeat url("../images/bg_menu1.png");
$navbar-background: $blue-darkest;
$navbar-active-background: $blue-mid;
$navbar-hover-background: transparentize(
  $color: white,
  $amount: 0.8
);

$brand-logo-width: 100px;
$brand-logo-width-condensed: 68px;
$brand-logo-margin: 5%;

$primary: $blue-mid;
$primary-reverse: $black-0;
$secondary: $gray-mid;

$ui-trv-background: $blue-darkest;
$ui-trv-tab-background: $blue-darkest;
$ui-trv-tab-background-active: white;
$ui-trv-tab-color: $purple-light;
$ui-trv-tab-color-active: black;
$ui-trv-tab-border: 1px solid $purple-7;
$ui-trv-tab-border-active: 1px solid white;
$ui-trv-tab-border-radius: 16px;
$ui-trv-tab-close-btn-color: $purple-dark;
$ui-trv-tab-close-btn-color-active: $purple-light;
$ui-trv-tab-close-btn-color-hover: $purple-dark;
$ui-trv-tab-height: 38px;

$button-ripple: false;
$white: $black-0;

$floating-box-view-background-footer: $background-footer;

$ux-dialog-background-header: $white;
$ux-dialog-color-header: $black-100;

$radius: 24px;
// $black: $black-100;
$ag-row-disabled-opacity: 0.4 !default;

$menu-hover-color: $blue-mid;
$menu-hover-bg: $purple-lightest;

$left-part-width: 240px !default;
$left-part-condensed-width: 70px !default;

:root {
  --menu-hover-color: #{$menu-hover-color};
  --menu-hover-bg: $purple-lighter;
  // --navbar-background: $blue-darkest;

  // --navbar-text-color: $purple-light;
  // --navbar-active-text-color: white;
  // --navbar-icon-color: $purple-dark;
  // --navbar-active-icon-color: white;
  // --navbar-floating-menu-background: $blue-mid;
  // --navbar-background: $blue-darkest;
  // --navbar-active-background: $blue-mid;
  // --navbar-hover-background: transparentize($color: white, $amount: 0.8);
}

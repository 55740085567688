import { PLATFORM, autoinject, singleton } from 'aurelia-framework';
import { activationStrategy } from 'aurelia-router';
import { applyDefaultPermissionsConfig, Config, ExtendedRouteConfig } from 'digiwall-lib';
import * as Constants from './constants';
import * as toastr from 'toastr';
import environment from './environment';

@autoinject
@singleton(Config)
export class MerlinConfig extends Config {

  // authConfig = {
  //   clientId: environment.auth.clientId,
  //   domain: environment.auth.domain,
  //   audience: environment.auth.audience,
  //   callback_uri: environment.auth.callback_uri,
  //   logout_uri: environment.auth.logout_uri
  // }

  constructor() {
    super(environment);
    this.globalConfig.applicationTitle = "Merlin";
    this.globalConfig.remoteServiceName = environment.remoteApiUrl;
    this.globalConfig.breezeControllerUri = this.globalConfig.remoteServiceName + '/breeze/MerlinBreeze';
    this.globalConfig.resourceNames = Constants.EntityTypeNames.ResourceNames;
    this.globalConfig.googleApiKey = environment.googleApiKey;
    this.globalConfig.uiBagdeDisplay = "bottom";
    this.globalConfig.showPaginationBoundaryLinks = false;
    this.globalConfig.showChat = false;
    this.globalConfig.showHistory = false;
    this.globalConfig.defaultLocale = 'fr-FR';// fr-FR format numbers like this: 15 000 000,56
    this.globalConfig.displayWeekPicker = false;
    this.globalConfig.keyboardShortcutFwBox = true;

    toastr.options.timeOut = 2000;
    toastr.options.positionClass = "toast-bottom-right";
    toastr.options.progressBar = true;

    this.menuConfig.maxLevelVerticalNavbar = 1;

    this.agGridConfig.showViewBox = true;
    this.agGridConfig.showFilterBox = true;
    this.agGridConfig.showParametersViewBox = true;
    this.agGridConfig.showInFloatingBox = true;
    this.agGridConfig.suppressMenu = false;
    this.agGridConfig.allowSharedView = true;
    this.agGridConfig.showUsedViewInList = false;
    this.agGridConfig.hasHyperlinkColumn = false;
    this.agGridConfig.separateViewsByVisibility = true;
    this.agGridConfig.defaultAdjustColumns = false;
    this.agGridConfig.stringSeparatorAgGridFilter = ' ';
    this.agGridConfig.administratorRoles = [Constants.UserRoleId.Administrator];
    this.agGridConfig.showEmptyFieldsCheckbox = true;

    this.communicationConfig.showHistoryChatInButton = true;
    this.communicationConfig.iconDictionnary[Constants.NotificationTypeId.Chat] = "digi-notification"
    this.communicationConfig.iconDictionnary[Constants.NotificationTypeId.ProjectTeamAsssignement] = "digi-team-workers"
    this.communicationConfig.iconDictionnary[Constants.NotificationTypeId.TaskResponsibleAssignement] = "digi-todo-line"
    this.communicationConfig.iconDictionnary[Constants.NotificationTypeId.ProjectEnterExecution] = "digi-play-circle-line"
    this.communicationConfig.iconDictionnary[Constants.NotificationTypeId.TaskIsDone] = "digi-task-line"
    this.communicationConfig.iconDictionnary[Constants.NotificationTypeId.EmailSentError] = "digi-error-warning-line"

    this.defaultRichTextEditorOptions = {
      height: 300,
      menubar: false,
      content_style: "@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap'); body { font-family: Inter; font-size: 11pt;}",
      toolbar: 'undo | redo | bold | italic | underline | superscript | bullist | file',
      powerpaste_allow_local_images: true,
      plugins: [
        'advlist autolink lists link',
        'searchreplace visualblocks code fullscreen',
        'insertdatetime media table powerpaste code'
      ]
    }
    // addUsersToRoutes(this.routes, { before: 'metadata', resource: Constants.EntityTypeNames.MerlinUser }, 'single', 'resources');
    // addRolesToRoutes(this.routes, null, 'resources');

    applyDefaultPermissionsConfig();
  }

  public routes: ExtendedRouteConfig[] = [  //Here are all the routes needed to navigate through the different sections
    // Custom
    { route: ['login/:fragment?'], name: 'login', moduleId: 'auth/login', title: "Login", nav: false },
    { route: 'callback', name: 'callback', moduleId: 'auth/callback' },
    { route: 'ponto/callback', name: 'ponto-callback', moduleId: 'ponto/callback' },

    //#region Hidden Routes

    { route: 'import-data', name: "import-data", moduleId: PLATFORM.moduleName('digiwall-lib/import-data/import-data-index'), title: "importdata.importdata", nav: false, settings: { tab: false } },

    /* --- Third Party --- */
    { route: 'third-parties/:param1', moduleId: 'third-parties/third-party-detail', title: "thirdparty.addThirdParty", nav: false, settings: { resource: Constants.EntityTypeNames.ThirdParty, parentName: Constants.Routes.RoutesList.ThirdPartyList }, href: "/third-parties/-100" },

    /* --- Project --- */
    { route: 'projects/:projectId', activationStrategy: activationStrategy.invokeLifecycle, moduleId: 'projects/project-index', title: "project.informations", nav: false, name: "projects-index", settings: { resource: Constants.EntityTypeNames.Project, parentName: Constants.Routes.RoutesList.ProjectList }, href: "/projects/-100" },

    /* --- Item --- */
    { route: 'items/:param1', moduleId: 'items/item-detail', title: "item.addItem", name: "item-detail", nav: false, settings: { resource: Constants.EntityTypeNames.Item, parentName: Constants.Routes.RoutesList.ItemList }, href: "/items/-100" },
    { route: 'item-families/:param1', moduleId: 'item-families/item-family-details', title: "itemFamily.addItemFamily", nav: false, settings: { resource: Constants.EntityTypeNames.ItemFamily, parentName: Constants.Routes.RoutesList.ItemFamiliesList }, href: "/item-families/-100" },


    /* --- Invoice --- */
    { route: 'invoices/:param1', moduleId: 'invoices/invoice-detail', title: "sellinginvoice.addSellingInvoice", nav: false, settings: { tab: true, resource: Constants.EntityTypeNames.SellingInvoice, parentName: Constants.Routes.RoutesList.InvoiceList }, href: "/invoices/-100" },
    { route: 'credit-notes/:param1', moduleId: 'invoices/invoice-detail', title: "sellinginvoice.addCreditNote", nav: false, settings: { tab: true, resource: Constants.EntityTypeNames.SellingInvoice, parentName: Constants.Routes.RoutesList.CreditNoteList }, href: "/credit-notes/-100" },
    { route: 'billing-vouchers/:param1', moduleId: 'invoices/invoice-detail', title: "sellinginvoice.addBillingVoucher", nav: false, settings: { tab: true, resource: Constants.EntityTypeNames.SellingInvoice, parentName: Constants.Routes.RoutesList.BillingVoucherList }, href: "/billing-vouchers/-100" },
    { route: 'bankaccounts/:param1', moduleId: 'banks/bankaccount-detail', title: "bankaccount.bankaccount", nav: false, settings: { tab: true, resource: Constants.EntityTypeNames.BankAccount, parentName: Constants.Routes.RoutesList.BankAccount } },
    { route: 'transactions/:param1', moduleId: 'banks/transaction-detail', title: "banktransaction.banktransaction", nav: false, settings: { tab: true, resource: Constants.EntityTypeNames.BankTransaction, parentName: Constants.Routes.RoutesList.PaymentList } },


    /* --- Import Metering --- */



    { route: 'external-emails/:param1', name: Constants.EntityTypeNames.ExternalEmail, moduleId: 'external-emails/external-email-detail', title: "externalemail.externalemail", nav: false, href: "/external-emails/-100" },

    //#endregion

    //#region Menu Routes
    /* --- Third Party --- */
    { route: ['', 'third-parties/all'], name: Constants.Routes.RoutesList.ThirdPartyList, moduleId: 'third-parties/third-party-list', title: "thirdparty.thirdParties", nav: true, settings: { group: Constants.Routes.MenuGroupTitle.ThirdParties, resource: Constants.EntityTypeNames.ThirdParty, icon: "fas fa-address-book" }, href: 'third-parties/all' },

    /* --- Project --- */
    { route: 'projects/all', name: Constants.Routes.RoutesList.ProjectList, moduleId: 'projects/project-list', title: "project.projects", nav: true, settings: { group: Constants.Routes.MenuGroupTitle.Projects, resource: Constants.EntityTypeNames.Project, icon: "fa fa-file" } },

    /* --- Import Metering --- */
    // { route: 'import-metering/all', name: Constants.Routes.RoutesDetail.ImportMetering, moduleId: 'import-metering/import-metering-detail', title: "enumerationtype.importmetering", nav: true, settings: { tab: false, group: Constants.Routes.MenuGroupTitle.ImportMetering, resource: Constants.EntityTypeNames.ImportMetering, icon: "fas fa-upload" } },

    //#region data
    /* --- Item --- */
    { route: 'items/all', name: Constants.Routes.RoutesList.ItemList, moduleId: 'items/item-list', title: "item.item", nav: true, settings: { group: Constants.Routes.MenuGroupTitle.Data, subGroup: Constants.Routes.MenuSubGroup.Items, resource: Constants.EntityTypeNames.Item, isDefault: true } },
    { route: 'item-families/all', name: Constants.Routes.RoutesList.ItemFamiliesList, moduleId: 'item-families/item-family-list', title: "item.itemFamilies", nav: true, settings: { group: Constants.Routes.MenuGroupTitle.Data, subGroup: Constants.Routes.MenuSubGroup.Items, resource: Constants.EntityTypeNames.Item } },

    /* --- Invoice --- */
    { route: "financial-hub-summary", moduleId: "projects/realization/realization-summary", name: "summary", title: 'sellinginvoice.summary', nav: true, settings: { tab: true, group: Constants.Routes.MenuGroupTitle.Invoice, subGroup: Constants.Routes.MenuSubGroup.Invoices, resource: Constants.EntityTypeNames.SellingInvoice, isDefault: true } },
    { route: 'invoices/all', name: Constants.Routes.RoutesList.InvoiceList, moduleId: 'invoices/invoice-list', title: "sellinginvoice.sellinginvoices", nav: true, settings: { tab: true, group: Constants.Routes.MenuGroupTitle.Invoice, subGroup: Constants.Routes.MenuSubGroup.Invoices, resource: Constants.EntityTypeNames.SellingInvoice, isDefault: true } },
    { route: 'credit-notes/all', name: Constants.Routes.RoutesList.CreditNoteList, moduleId: 'invoices/invoice-list', title: "sellinginvoice.creditNotes", nav: true, settings: { tab: true, group: Constants.Routes.MenuGroupTitle.Invoice, subGroup: Constants.Routes.MenuSubGroup.Invoices, resource: Constants.EntityTypeNames.SellingInvoice, isDefault: true } },
    { route: 'billing-vouchers/all', name: Constants.Routes.RoutesList.BillingVoucherList, moduleId: 'invoices/invoice-list', title: "sellinginvoice.billingVouchers", nav: true, settings: { tab: true, group: Constants.Routes.MenuGroupTitle.Invoice, subGroup: Constants.Routes.MenuSubGroup.Invoices, resource: Constants.EntityTypeNames.SellingInvoice, isDefault: true } },
    { route: "payment/all", name: Constants.Routes.RoutesList.PaymentList, moduleId: 'banks/transaction-list', title: 'banktransaction.banktransactions', nav: true, settings: { tab: true, group: Constants.Routes.MenuGroupTitle.Invoice, subGroup: Constants.Routes.MenuSubGroup.Invoices, resource: Constants.EntityTypeNames.SellingInvoice, isDefault: true } },
    { route: 'bankaccounts/all', name: Constants.Routes.RoutesList.BankAccount, moduleId: 'banks/bankaccount-list', title: "bankaccount.bankaccounts", nav: true, settings: { group: Constants.Routes.MenuGroupTitle.Invoice, subGroup: Constants.Routes.MenuSubGroup.Invoices, resource: Constants.EntityTypeNames.SellingInvoice } },
    //{ route: 'transactions/all', moduleId: 'banks/transaction-list', title: "banktransaction.transactions", nav: true, settings: { group: Constants.MenuType.Invoice, icon: "digi-currency-line", resource: Constants.EntityTypeNames.BankTransaction, action: Actions.All } },


    /* --- Task --- */
    { route: 'tasks', name: "tasks", activationStrategy: activationStrategy.replace, moduleId: 'module-tasks/module-task', title: "taskjob.taskjobs", nav: false, settings: { tab: false, group: Constants.Routes.MenuGroupTitle.Tasks, resource: Constants.EntityTypeNames.TaskJob } },
    { route: 'module-tasks', name: "module-tasks", activationStrategy: activationStrategy.replace, moduleId: 'module-tasks/module-task', title: "taskjob.taskjobs", nav: true, settings: { tab: false, group: Constants.Routes.MenuGroupTitle.ModuleTask, resource: Constants.EntityTypeNames.TaskJob } },
    /* --- Notification --- */
    { route: 'notifications-sent/all', name: Constants.Routes.RoutesList.NotificationSentList, moduleId: 'notifications-sent/notification-sent-list', title: "notificationsent.notificationsents", nav: true, settings: { tab: false, group: Constants.Routes.MenuGroupTitle.Communications, resource: Constants.EntityTypeNames.NotificationSent } },
    { route: 'external-emails/all', name: Constants.Routes.MenuGroupTitle.Communications + "List", moduleId: 'external-emails/external-email-list', title: "externalemail.externalemails", nav: true, settings: { tab: false, group: Constants.Routes.MenuGroupTitle.Communications, resource: Constants.EntityTypeNames.ExternalEmail } },
    //#region Reference Data 
    { route: 'parameters', name: Constants.Routes.RoutesList.Parameters, moduleId: 'parameters/parameters', title: "parameters.parameters", nav: true, settings: { tab: false, group: Constants.Routes.MenuGroupTitle.Parameters } },



    //#endregion
    //{ route: 'import-metering/all', moduleId: 'import-metering/import-metering-detail', title: "enumerationtype.importmetering", nav: true, settings: { group: Constants.MenuType.EnumerationTypes, resource: Constants.EntityTypeNames.ImportMetering } },
    //#endregion

    { route: 'my-account', name: Constants.Routes.RoutesDetail.MyAccount, moduleId: 'my-account/my-account', title: "myaccount.myaccount", nav: true, settings: { tab: false, group: Constants.Routes.MenuGroupTitle.MyAccount, icon: "fas fa-user" } },

  ];


  public init(): void {
    //empty
  }
}

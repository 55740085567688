import { CustomLogger, ServiceBase } from 'digiwall-lib';
import { autoinject, bindable, BindingEngine, customElement } from "aurelia-framework";
import { Merlin } from "../../../../../generated";
import * as Constants from './../../../../../constants';
import { Predicate, FilterQueryOp } from 'breeze-client';

@autoinject
@customElement("amendment-step-2")
export class AmendmentStep2 {
  public contractSuppliers: Merlin.Web.Model.ThirdParty[] = [];
  @bindable
  public selectedThirdParties: Array<number> = [];
  @bindable
  public contractId: number;
  @bindable
  public projectId: number;
  @bindable
  public companyIds: Array<number> = [];

  private contractService: ServiceBase<Merlin.Web.Model.ThirdPartyContract>;
  private thirdPartyService: ServiceBase<Merlin.Web.Model.ThirdParty>;

  constructor(private logger: CustomLogger, private bindingEngine: BindingEngine) {
    this.contractService = new ServiceBase<Merlin.Web.Model.ThirdPartyContract>(Constants.EntityTypeNames.ThirdPartyContract);
    this.thirdPartyService = new ServiceBase<Merlin.Web.Model.ThirdParty>(Constants.EntityTypeNames.ThirdParty);
  }

  async bind() {
    if (this.contractId != null) {
      await this.getContractSupplier();
    }
    else {
      this.logger.LogError("Error : No contract id", null, null, true);
    }
  }

  private async getContractSupplier() {
    this.contractSuppliers.splice(0);
    this.companyIds.splice(0);
    let contract = await this.contractService.getEntityById(this.contractId);
    if (contract == null) {
      this.logger.LogError("Error : No contract", null, null, true);
      return;
    }

    let thirdParty = await this.thirdPartyService.firstEntity(new Predicate('id', FilterQueryOp.Equals, contract.supplierOrSubcontractorId), ['contactPersons', 'addresses'], { onlyActive: false });

    if (thirdParty.isCompany) {
      (thirdParty as any).selectedThirdPartyIds = new Array<number>();
      (thirdParty as any).allContacts = await this.thirdPartyService.getEntities(null, null, { companyId: thirdParty.id, emailNotNull: true, includeCompany: true });
      (thirdParty as any).computedEmailsAddress = ""
      this.bindingEngine.collectionObserver((thirdParty as any).selectedThirdPartyIds).subscribe((record) => {
        var computedEmailsAddress = "";
        (thirdParty as any).selectedThirdPartyIds.forEach(id => {
          computedEmailsAddress += this.getEmailAdress((thirdParty as any).allContacts, id);
        });
        (thirdParty as any).computedEmailsAddress = computedEmailsAddress;

        this.selectedThirdParties.splice(0);
        this.selectedThirdParties.push(...(thirdParty as any).selectedThirdPartyIds);
      })
    }
    else {
      this.selectedThirdParties.push(thirdParty.id);
    }
    this.contractSuppliers.push(thirdParty);
    this.companyIds.push(thirdParty.id);
  }

  private getEmailAdress(allContacts: Array<Merlin.Web.Model.ThirdParty>, id: number) {
    return allContacts[allContacts.findIndex(x => x.id == id)].emailAddress + ';'
  }
}

import { autoinject } from "aurelia-framework";
import { EnumerationType, EnumerationTypeService, ServiceBase } from "digiwall-lib";
import { ItemApiService } from "services/item-api-service";
import * as Constants from '../../../constants';
import { Merlin } from "generated";
import { DialogController } from "aurelia-dialog";
import { IDataLineApiService } from "services/i-data-line-api-service";
import { I18N } from "aurelia-i18n";


@autoinject
export class CreateItemFromDataline {
  private dtos: Array<LineToItem>
  public activityService: EnumerationTypeService;
  public allPossibleActivity: Array<EnumerationType>;
  public deliveryService: EnumerationTypeService;
  public allPossibleDelivery: Array<EnumerationType>;
  public unitService: ServiceBase<Merlin.Web.Model.Unit>;
  public allPossibleUnit: Array<Merlin.Web.Model.Unit>;
  public projectService: ServiceBase<Merlin.Web.Model.Project>;
  public nbDecimalForPriceDisplay: number;
  public service: IDataLineApiService;
  public versionId: number;
  public title: string = this.i18n.tr("versionmetering.createItem");
  private Constants: any = Constants;

  constructor(private itemApiService: ItemApiService, private dialogController: DialogController, private i18n: I18N) {
    this.activityService = new EnumerationTypeService(Constants.EnumerationTypes.ActivityCode);
    this.deliveryService = new EnumerationTypeService(Constants.EnumerationTypes.ItemDeliveryType);
    this.unitService = new ServiceBase<Merlin.Web.Model.Unit>(Constants.EntityTypeNames.Unit);
    this.projectService = new ServiceBase<Merlin.Web.Model.Project>(Constants.EntityTypeNames.Project);
  }

  async activate(params) {
    this.allPossibleActivity = await this.activityService.getEntities(null, null, { category: Constants.EnumerationTypes.ActivityCode });
    this.allPossibleDelivery = await this.deliveryService.getEntities(null, null, { category: Constants.EnumerationTypes.ItemDeliveryType });
    this.allPossibleUnit = await this.unitService.getAllEntities();

    this.dtos = [this.createDto(params.data)];
    if (params.projectId) {
      this.nbDecimalForPriceDisplay = (await this.projectService.getEntityById(params.projectId)).nbDecimalForPriceDisplay;
    }
    else {
      this.nbDecimalForPriceDisplay = 2
    }
    this.service = params.service;
    this.versionId = params.versionId;
    await this.getChildren(params.data.id)
  }

  public cancel() {
    this.dialogController.cancel()
  }

  public async save() {
    let ids = await this.itemApiService.CreateUnvalidatedItem(this.dtos);
    if (ids) {
      await this.dialogController.close(true, ids);
    }
  }

  public filter(record: KeyValue) {
    return record.itemId == null;
  }

  public async getChildren(id: number) {
    let children = await this.service.children(this.versionId, id, true, Constants.RefToDisplay.MerlinRef)
    children.filter(x => x.priceOfferLineCategoryId == Constants.PriceOfferLineCategory.Data).forEach(async child => {
      this.dtos.push(this.createDto(child));
      if (child.hasChildren) {
        await this.getChildren(child.id);
      }
    });
  }

  public createDto(data: any): LineToItem {
    return {
      description: data.lineDescription ?? null,
      activityIds: this.mergeAndFilterUnique([data.activityCodeId], data.dataLineActivities),
      deliveryIds: this.mergeAndFilterUnique([data.itemDeliveryTypeId], data.dataLineDeliveries),
      unitId: data.unitId ?? null,
      buyingPrice: data.buyingUnitPrice ?? 0,
      itemTypeId: data.hasChildren ? Constants.ItemType.Composed : Constants.ItemType.Supply,
      datalineId: data.id,
      datalineParentId: data.parentId,
      quantity: data.proposedQuantity ?? 0,
      itemId: data.itemId
    }
  }

  public mergeAndFilterUnique(array1: (number | null)[], array2: (number | null)[]): number[] {
    // Merge the two arrays
    const mergedArray = [...array1, ...array2];

    // Filter out null values and remove duplicates
    const filteredUniqueArray = mergedArray
      .filter((item): item is number => item !== null)  // Remove null values
      .filter((item, index, self) => self.indexOf(item) === index);  // Remove duplicates

    return filteredUniqueArray;
  }
}

export interface LineToItem {
  description: string;
  activityIds: number[];
  deliveryIds: number[];
  unitId: number;
  buyingPrice: number | null;
  itemTypeId: number;
  datalineId: number;
  datalineParentId: number | null;
  quantity: number | null;
  itemId: number | null;
}

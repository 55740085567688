import { Router } from 'aurelia-router';
import { PriceOfferLineCategory, AdditionalWorkTypeId } from './../../constants';
import { autoinject } from "aurelia-framework";
import { I18N } from "aurelia-i18n";
import * as Constants from '../../constants';
import { Box, IMenuGroup, GlobalLoaderService, IMenuItems, EnumerationType, EnumerationTypeService, ActionDialogBoxInputParameters, DialogBoxViewModel, ServiceBase, Various } from "digiwall-lib";
import { PriceOfferLineMoveAction } from "../../constants";
import { DialogService } from "aurelia-dialog";
import { ICellRendererParams, RowNode } from "ag-grid-community";
import { IDataLineApiService, MeteringTotalPrices } from "services/i-data-line-api-service";
import { BuyingCommonMetering } from "../metering/buying-common-metering";
import { RealizationApiService } from "services/realization-api-service";
import { AddItems } from "projects/quotes/add-items/add-items";
import { Merlin } from 'generated';
import { ProjectApiService } from 'services/project-api-service';
import { ColumnVisible } from 'module-list-tree-data/module-list-tree-data';
import { ClientAdditionalWorkService } from 'services/client-addition-work-service';
import { AmendmentCreation } from 'projects/buying/third-party-contract/amendment/amendment-creation';
import { UpdateItemType } from 'projects/quotes/update-items/update-items';
import { MeteringMenuItems } from 'resources/metering/metering-menu-items';
import { FullWidthCellRenderer } from 'projects/quotes/version-offer-overlay-comp';

@autoinject
export class RealizationMetering extends BuyingCommonMetering {
  ribbonHeaderText = this.i18n.tr('realizationindex.metering');

  public listIsFlat: boolean = false;
  public footer: MeteringTotalPrices;
  public buyingAdditionalWorkTypeList: Array<EnumerationType>;
  menuItems: IMenuItems[];

  private nbDecimalForPriceDisplay: number;
  private nbDecimalForQuantityDisplay: number;

  public columnVisible: Array<ColumnVisible> =
    [
      { colId: "menuIems", visibility: true },
      { colId: "selected", visibility: true },
      { colId: "merlinRef", visibility: true },
      { colId: "merlinRefMetering", visibility: false },
      { colId: "merlinRefContext", visibility: true },
      { colId: "lineDescription", visibility: true },
      { colId: "lineRef", visibility: true },
      { colId: "originalMeasurementXlsLineNumber", visibility: true },
      { colId: "sheetIndex", visibility: true },
      { colId: "importFileName", visibility: true },
      { colId: "quantityTypeId", visibility: true },
      { colId: "unitId", visibility: true },
      { colId: "proposedQuantity", visibility: true },
      { colId: "proposedQuantityFormula", visibility: true },
      { colId: "priceOfferLineFileCount", visibility: true },
      { colId: "additionalDescriptionMultiline", visibility: true },
      { colId: "requestedQuantity", visibility: true },
      { colId: "acceptedRequestedQuantity", visibility: true },
      { colId: "dataLineDeliveries", visibility: true },
      { colId: "dataLineActivities", visibility: true },
      { colId: "buyingUnitPrice", visibility: true },
      { colId: "definedSellingUnitPrice", visibility: true },
      { colId: "definedDirectMarginPrice", visibility: false },
      { colId: "buyingUnitPrice", visibility: false },
      { colId: "sellingUnitPriceWithIndirectCostFromPAR", visibility: true },
      { colId: "totalBuyingPrice", visibility: true },
      { colId: "totalSellingPrice", visibility: true },
      { colId: "tags", visibility: false },
      { colId: "features", visibility: true },
      { colId: "supplierId", visibility: true },
      { colId: "supplierPrice", visibility: false },
      { colId: "supplierQuantity", visibility: false },
      { colId: "supplierQuantityTypeId", visibility: false },
      { colId: "supplierTotal", visibility: false },
      { colId: "supplierComment", visibility: false },
      { colId: "acceptedPriceOfferBuyingUnitPrice", visibility: true },
      { colId: "buyingClientValidationStatusId", visibility: true },
      { colId: "buyingClientValidationDeadlineDate", visibility: true },
      { colId: "buyingClientValidationDate", visibility: true },
      { colId: "deliveryDeadlineDate", visibility: true },
      { colId: "unitQuantityDetailItemComposed", visibility: true },
      { colId: "unitQuantityFormulaDetailItemComposed", visibility: true },
      { colId: "clientAdditionalWorkNum", visibility: true },
      { colId: "clientAdditionalWorkStatusId", visibility: true },
      { colId: "contractNum", visibility: true },
      { colId: "buildingCostsId", visibility: true },
    ];

  constructor(public realizationApiService: RealizationApiService, private i18n: I18N, box: Box, private meteringMenuItems: MeteringMenuItems, private globalLoaderService: GlobalLoaderService, dialogService: DialogService, router: Router, private projectApiService: ProjectApiService, private additionalWorkService: ClientAdditionalWorkService) {
    super(dialogService, box, realizationApiService, router);
  }

  async activate(params) {
    this.projectId = params.projectId;
    this.realizationApiService.projectId = this.projectId;
    this.getMeteringTotalPrices();
    this.setMenuItems();
    this.buyingAdditionalWorkTypeList = await (new EnumerationTypeService(Constants.EnumerationTypes.AdditionalWorkType)).getAll();

    let precisionParameter = await this.projectApiService.getPrecisionParameter(parseInt(params.projectId));
    this.nbDecimalForPriceDisplay = precisionParameter.nbDecimalForPriceDisplay;
    this.nbDecimalForQuantityDisplay = precisionParameter.nbDecimalForQuantityDisplay;
    this.additionalWorkService.projectId = this.projectId;
  }

  async deactivate() {
    await this.priceOfferLinesGrid?.deactivate()
  }

  getBottomRowRenderer() {
    return FullWidthCellRenderer;
  }

  private setMenuItems(): void {
    this.menuItems = [
      {
        group: "1",
        hiddenLabel: true,
        items: [
          {
            label: this.i18n.tr("realizationmetering.createClientAdditionalWork"),
            icon: "digi-add-circle-line",
            handler: () => {
              this.createClientAdditionalWork(this.projectId);
            }
          }
        ]
      }
    ];
  }

  createClientAdditionalWork(projectId: number) {
    this.router.navigateToRoute('additional-work-detail', { projectId: projectId, additionalWorkId: -100, clientAdditionalWorkVersionId: Various.NewId });
  }

  public getGridMenuItems(params: ICellRendererParams): Array<IMenuGroup> {
    return [
      {
        group: "0",
        hiddenLabel: true,
        items: [
          {
            label: this.i18n.tr("versionmetering.addAdditionalWork"),
            icon: "digi-increase-decrease-line",
            disabled: () => {
              return params.node.data?.priceOfferLineCategoryId != Constants.PriceOfferLineCategory.Data || params.node.data.isSubItem || params.node.data.IsDetailedLine
            },
            handler: async () => {
              await this.addAdditionalWork(params.node.id, Constants.AdditionalWorkTypeId.AdditionalWorkToBeValidated)
            },
          },
          {
            label: this.i18n.tr("versionmetering.addExtra"),
            icon: "digi-file-paper-2-line",
            disabled: () => {
              return params.node.data?.priceOfferLineCategoryId != Constants.PriceOfferLineCategory.Data || params.node.data.isSubItem || params.node.data.IsDetailedLine
            },
            handler: async () => {
              await this.addAdditionalWork(params.node.id, Constants.AdditionalWorkTypeId.Extra)
            },
          },
          ...this.getTypeItems(params),
          {
            label: this.i18n.tr("realizationmetering.validateClientAdditionWorkLine"),
            icon: "digi-duplicate",
            disabled: () => {
              return params.node.data?.priceOfferLineCategoryId != PriceOfferLineCategory.Data || (params.node.data?.priceOfferLineCategoryId == PriceOfferLineCategory.Data && params.node.data?.additionalWorkId != null || params.node.data.clientAdditionalWorkVersionId != null);
            },
            handler: async () => {
              await this.additionalWorkService.createAndAcceptedVersion(this.projectId, params.node.data.id);
              this.priceOfferLinesGrid.refreshVisibleNodes([params.node.data.id]);
            },
          },
        ]
      },
      AmendmentCreation.getCreateAmendmentMenuItem(this.projectId, params.node.data.id, null, !params.node.data?.canCreateAmendment, () => { this.priceOfferLinesGrid.listTreeData.refreshServerSideRows(null, true, true); }),
      {
        group: "0",
        hiddenLabel: true,
        items: [
          {
            label: this.i18n.tr("versionmetering.addPost"),
            icon: "digi-flashlight-line",
            items: this.getCreateLineMenuItem(params, parseInt(params.node.id), Constants.PriceOfferLineCategory.Data)
          },
          {
            id: Constants.CommonMeterginDataLineMenuItems.ADD_ITEM,
            label: this.i18n.tr("versionmetering.addItems"),
            icon: "digi-database-2-line",
            items: [
              {
                id: Constants.CommonMeterginDataLineMenuItems.ADD_ITEM_UP,
                label: this.i18n.tr("versionmetering.onTopOfLine"),
                disabled: () => {
                  return this.cannotCreateLine(parseInt(params.node.id), Constants.PriceOfferLineCategory.Data, Constants.PriceOfferLineMoveAction.Up);
                },
                handler: async () => {
                  this.addItems(parseInt(params.node.id), Constants.PriceOfferLineMoveAction.Up);
                },
                icon: "digi-arrow-up-line"
              },
              {
                id: Constants.CommonMeterginDataLineMenuItems.ADD_ITEM_DOWN,
                label: this.i18n.tr("versionmetering.belowLine"),
                disabled: () => {
                  return this.cannotCreateLine(parseInt(params.node.id), Constants.PriceOfferLineCategory.Data, Constants.PriceOfferLineMoveAction.Down);
                },
                handler: async () => {
                  this.addItems(parseInt(params.node.id), Constants.PriceOfferLineMoveAction.Down);
                },
                icon: "digi-arrow-down-line"
              },
              {
                id: Constants.CommonMeterginDataLineMenuItems.ADD_ITEM_CHILD,
                label: this.i18n.tr("versionmetering.childOfLine"),
                disabled: () => {
                  let targetNode = this.priceOfferLinesGrid.gridOptions.api.getRowNode(params.node.id);
                  return targetNode.data.priceOfferLineCategoryId != Constants.PriceOfferLineCategory.Chapter;
                },
                handler: async () => {
                  this.addItems(parseInt(params.node.id), Constants.PriceOfferLineMoveAction.Into);
                },
                icon: "digi-arrow-right-line"
              },
            ]
          },
          {
            label: this.i18n.tr("versionmetering.addChapter"),
            icon: "digi-list-check",
            items: this.getCreateLineMenuItem(params, parseInt(params.node.id), Constants.PriceOfferLineCategory.Chapter)
          },
          {
            label: this.i18n.tr("versionmetering.addComment"),
            icon: "digi-chat-4-line",
            items: this.getCreateLineMenuItem(params, parseInt(params.node.id), Constants.PriceOfferLineCategory.Comment)
          },
        ]
      },
      {
        group: "4",
        hiddenLabel: true,
        items: [
          {
            label: this.i18n.tr("versionmetering.duplicate"),//dupliquer la ligne
            icon: "digi-duplicate",
            disabled: () => {
              return params.node.data?.additionalWorkTypeId == null;
            },
            handler: () => {
              this.duplicateLine(params.node.id);
            }
          },
          {
            label: this.i18n.tr("versionmetering.goToContract"),
            icon: "digi-duplicate",
            disabled: () => {
              return params.node.data?.priceOfferLineCategoryId != PriceOfferLineCategory.Data || (params.node.data?.priceOfferLineCategoryId == PriceOfferLineCategory.Data && params.node.data.contractId == null);
            },
            handler: async () => {
              this.router.navigate(`/projects/${this.projectId}/buying/contract/${params.node.data.contractId}`)
            },
          },
          {
            label: this.i18n.tr("realizationmetering.goToClientAdditionalWork"),
            icon: "digi-duplicate",
            disabled: () => {
              return params.node.data?.priceOfferLineCategoryId != PriceOfferLineCategory.Data || (params.node.data?.priceOfferLineCategoryId == PriceOfferLineCategory.Data && params.node.data?.additionalWorkId == null);
            },
            handler: async () => {
              this.router.navigate(`/projects/${this.projectId}/realization/additional-work/${params.node.data?.additionalWorkId}/${params.node.data.clientAdditionalWorkVersionId}`)
            },
          },
        ]
      },
      {
        group: "2",
        hiddenLabel: true,
        items: [
          {
            id: Constants.CommonMeterginDataLineMenuItems.ADD_DOCUMENTS,
            label: this.i18n.tr("versionmetering.adddocument"),
            icon: "digi-folder-add-line",
            disabled: () => params.node.data?.additionalWorkTypeId == null,
            handler: () => {
              this.addPriceOfferLineDocument(params.node.data.id)
            }
          },
          this.meteringMenuItems.getUpdateItems(params, this.realizationApiService, this.priceOfferLinesGrid, this.projectId.toString(), this.projectId.toString(), this.columnVisible, UpdateItemType.Other, this.router.currentInstruction.config.moduleId, false, this.computeMeteringTotalPrice, this),
        ]
      },
      {
        group: "4",
        hiddenLabel: true,
        items: [
          {
            id: Constants.CommonMeterginDataLineMenuItems.DELETE,
            label: this.i18n.tr("versionmetering.deleteLine"),
            icon: "digi-trash",
            disabled: () => params.node.data?.additionalWorkTypeId == null || params.node.data.originalMeasurementXlsLineNumber != null,
            handler: async () => {
              await this.deleteLine(params.node.data.id)
            }
          }
        ]
      }
    ]
  }

  canEditCell(line, colField) {
    if (line == null || colField == null /*|| /*(line.additionalWorkTypeId != null && line.additionalWorkId != null)*/) return false;
    if (line.additionalWorkTypeId == null && colField == "lineDescription" && line.priceOfferLineCategoryId != Constants.PriceOfferLineCategory.Data) return true;
    if (line.additionalWorkTypeId == null) {
      return false;
    }
    switch (colField) {
      case "buyingUnitPrice":
        return line.priceOfferLineCategoryId == Constants.PriceOfferLineCategory.Data
          && line.useInCompleteness;
      case "buyingClientValidationStatusId":
        return line.buyingClientValidationDeadlineDate != null;
      case "lineDescription":
      case "additionalDescriptionMultiline":
      case "buyingClientValidationDeadlineDate":
      case "buyingClientValidationDate":
      case "deliveryDeadlineDate":
        return true;
      case "quantityTypeId":
      case "unitId":
      case "proposedQuantity":
      case "unitQuantityFormulaDetailItemComposed":
      case "unitQuantityDetailItemComposed":
        return line.priceOfferLineCategoryId == Constants.PriceOfferLineCategory.Data && (line.useInCompleteness || line.hasChildrenSubItem)
      case "dataLineActivities":
      case "dataLineDeliveries":
        return line.priceOfferLineCategoryId == Constants.PriceOfferLineCategory.Data
          || line.priceOfferLineCategoryId == Constants.PriceOfferLineCategory.Chapter
      case "buyingUnitPrice":
        return line.priceOfferLineCategoryId == Constants.PriceOfferLineCategory.Data
          && line.useInCompleteness;
      case "buyingClientValidationStatusId":
        return line.buyingClientValidationDeadlineDate != null;
      case "marginCoefficient":
        return line.priceOfferLineCategoryId != Constants.PriceOfferLineCategory.Comment && !line.isSubItem;
      default:
        return false;
    }
  }

  public async getMeteringTotalPrices() {
    this.footer = await this.realizationApiService.getMeteringTotalPrices(parseInt(this.projectId.toString()));
  }

  private getTypeItems(params: ICellRendererParams): Array<IMenuItems> {
    let arrayItems = new Array<IMenuItems>();
    if (params.node.data.priceOfferLineCategoryId == Constants.PriceOfferLineCategory.Comment || params.node.data.isSubItem || params.node.data.priceOfferLineCategoryId == Constants.PriceOfferLineCategory.Chapter) {
      return arrayItems;
    }

    arrayItems.push(this.getMenuItemType(this.buyingAdditionalWorkTypeList.find(x => x.id == Constants.AdditionalWorkTypeId.AdditionalWorkToBeValidated), params));
    arrayItems.push(this.getMenuItemType(this.buyingAdditionalWorkTypeList.find(x => x.id == Constants.AdditionalWorkTypeId.Extra), params));
    return arrayItems;
  }

  private getMenuItemType(enumeration: EnumerationType, params: ICellRendererParams): IMenuItems {
    let patchLine = async (enumeration: EnumerationType, rowParams) => {
      let result: number[] = []

      this.priceOfferLinesGrid.gridOptions.api.showLoadingOverlay();
      result.push(...await this.realizationApiService.patch(this.projectId, parseInt(rowParams.node.data.id), "additionalWorkTypeId", enumeration.id));
      this.priceOfferLinesGrid.gridOptions.api.hideOverlay();
      let nodes = await this.priceOfferLinesGrid.refreshVisibleNodes(result);
      if (rowParams.node?.childStore?.forEachNodeDeep != null) {
        let childsNodes = [];
        (<RowNode>rowParams.node).childStore.forEachNodeDeep(node => childsNodes.push(node));
        if (childsNodes.length > 0) nodes = nodes.concat(childsNodes);
      }
      this.priceOfferLinesGrid.gridOptions.api.redrawRows({ rowNodes: nodes });
      this.priceOfferLinesGrid.callResizeAgCellLastLeftPinned();
      this.getMeteringTotalPrices();
      params.api.deselectAll();
    };

    let html = `<div style="display:flex; gap:10px; align-items:center;justify-content: space-between;">`;
    let icon = "";
    switch (enumeration.id) {
      case AdditionalWorkTypeId.AdditionalWorkToBeValidated:
        html += `<div>
                ${this.i18n.tr("realizationmetering.transformToAdditionalWorkToBeValidated")}
                </div>`;
        icon = "digi-increase-decrease-line";
        break;
      case AdditionalWorkTypeId.Extra:
        html += `<div>
                  ${this.i18n.tr("realizationmetering.transformToExtraLinkClientAdditionalWork")}
                  </div>`;
        icon = "digi-file-paper-2-line";
        break;
    }
    html += `</div>`;

    let temp = {
      label: html,
      icon: icon,
      handler: async () => {
        await patchLine(enumeration, params);
      },
      disabled: () => {
        if (params.node.data?.additionalWorkId != null || params.node.data?.additionalWorkTypeId == null) {
          return true;
        }
        if (enumeration.id == AdditionalWorkTypeId.AdditionalWorkToBeValidated) {
          return !(params.node.data?.additionalWorkTypeId == AdditionalWorkTypeId.Extra);
        } else if (enumeration.id == AdditionalWorkTypeId.Extra) {
          return !(params.node.data?.additionalWorkTypeId == AdditionalWorkTypeId.AdditionalWorkToBeValidated); //TODO check si la ligne n'est pas liées à un doc de décompte
        }
        return false
      }
    };
    return temp;
  }
  private getCreateLineMenuItem(params, targetId: number, category: Constants.PriceOfferLineCategory) {
    return [
      {
        label: this.i18n.tr("versionmetering.onTopOfLine"),
        handler: () => {
          this.createLine(targetId, category, Constants.PriceOfferLineMoveAction.Up);
        },
        disabled: () => {
          return this.cannotCreateLine(targetId, category, Constants.PriceOfferLineMoveAction.Up);
        },
        icon: "digi-arrow-up-line"
      },
      {
        label: this.i18n.tr("versionmetering.belowLine"),
        handler: () => {
          this.createLine(targetId, category, Constants.PriceOfferLineMoveAction.Down);
        },
        disabled: () => {
          return this.cannotCreateLine(targetId, category, Constants.PriceOfferLineMoveAction.Down);
        },
        icon: "digi-arrow-down-line"
      },
      {
        label: this.i18n.tr("versionmetering.childOfLine"),
        handler: () => {
          this.createLine(targetId, category, Constants.PriceOfferLineMoveAction.Into);
        },
        disabled: () => {
          let targetNode = this.priceOfferLinesGrid.gridOptions.api.getRowNode(params.node.id);
          return targetNode.data.priceOfferLineCategoryId != Constants.PriceOfferLineCategory.Chapter;
        },
        icon: "digi-arrow-right-line"
      },
    ]
  }

  private async createLine(targetId: number, categoryId: number, action: Constants.PriceOfferLineMoveAction) {
    let targetNode = this.priceOfferLinesGrid.gridOptions.api.getRowNode(targetId.toString());
    if (!targetNode) {
      console.warn('Target node not found!');
      return;
    }

    targetNode.data.isSelected = false;

    this.priceOfferLinesGrid.gridOptions.api.showLoadingOverlay();
    this.globalLoaderService.allow();
    let ids = await this.realizationApiService.create(this.projectId, targetId, categoryId, action);
    this.priceOfferLinesGrid.gridOptions.api.hideOverlay();

    this.priceOfferLinesGrid.refreshServerSideRows(ids, targetNode.data.parentId == null && action != PriceOfferLineMoveAction.Into);
    if (action == PriceOfferLineMoveAction.Into) {
      targetNode.setExpanded(true);
    }
  }

  private async duplicateLine(targetRowId: string) {
    let targetNode = this.priceOfferLinesGrid.gridOptions.api.getRowNode(targetRowId.toString());
    if (!targetNode) {
      console.warn('Target node not found!');
      return;
    }

    targetNode.data.isSelected = false;

    this.globalLoaderService.allow();
    this.priceOfferLinesGrid.gridOptions.api.showLoadingOverlay();
    let ids = await this.realizationApiService.duplicate(this.projectId, parseInt(targetNode.data.id));
    this.priceOfferLinesGrid.gridOptions.api.hideOverlay();

    this.priceOfferLinesGrid.refreshServerSideRows(ids, targetNode.data.parentId == null);
  }

  private cantMoveToThisNodeChildren(targetRowId: string) {
    let targetNode = this.priceOfferLinesGrid.gridOptions.api.getRowNode(targetRowId.toString());
    if (!targetNode) {
      console.warn('Target node not found!');
      return true;
    }

    if (targetNode.data.priceOfferLineCategoryId == Constants.PriceOfferLineCategory.Data) {
      return true;
    }
    if (targetNode.data.priceOfferLineCategoryId == Constants.PriceOfferLineCategory.Comment && targetNode.parent.data.priceOfferLineCategoryId == Constants.PriceOfferLineCategory.Data) {
      return true;
    }
    if (targetNode.data.priceOfferLineCategoryId == Constants.PriceOfferLineCategory.Comment && targetNode.parent.data.priceOfferLineCategoryId == Constants.PriceOfferLineCategory.Chapter) {
      return true;
    }

    return false;
  }

  private async deleteLine(id: number) {
    this.globalLoaderService.allow();
    this.priceOfferLinesGrid.gridOptions.api.showLoadingOverlay();

    let idsToDelete = this.priceOfferLinesGrid.gridOptions.api.getSelectedNodes().map(x => x.data.id);
    if (idsToDelete.length == 0 || idsToDelete.findIndex(x => x == id) == -1)
      idsToDelete.push(id);

    let ids = await this.realizationApiService.delete(this.projectId, idsToDelete);
    this.priceOfferLinesGrid.gridOptions.api.hideOverlay();

    idsToDelete.forEach(id => {
      let node = this.priceOfferLinesGrid.gridOptions.api.getRowNode(id.toString());
      this.priceOfferLinesGrid.refreshServerSideRows(ids, node?.data?.parentId == null);
    });

    await this.getMeteringTotalPrices();
  }

  public async addItems(targetId: number, action: Constants.PriceOfferLineMoveAction) {
    let targetNode = this.priceOfferLinesGrid.gridOptions.api.getRowNode(targetId.toString());
    if (!targetNode) {
      console.warn('Target node not found!');
      return;
    }

    await this.box.showCustomDialog(AddItems, targetId, null, {
      model: {
        action: action,
        projectId: this.projectId,
        api: this.realizationApiService
      }, size: "xl"
    }).whenClosed(async (result) => {
      if (result.output) {
        this.priceOfferLinesGrid.refreshServerSideRows(result.output, targetNode.data.parentId == null && action != PriceOfferLineMoveAction.Into);
        if (action == PriceOfferLineMoveAction.Into) {
          targetNode.setExpanded(true);
        }
      }
    });
  }

  private cannotCreateLine(targetId, categoryId, moveType) {
    let targetNode = this.priceOfferLinesGrid.gridOptions.api.getRowNode(targetId.toString());
    if (targetNode.data.priceOfferLineCategoryId == Constants.PriceOfferLineCategory.Data) {
      if (moveType == Constants.PriceOfferLineMoveAction.Into &&

        (((!targetNode.data.isDetailedLine || !targetNode.data.isSubItem) && categoryId == Constants.PriceOfferLineCategory.Chapter) ||

          (targetNode.data.hasChildrenDetailed && categoryId != Constants.PriceOfferLineCategory.Comment) ||

          (targetNode.data.originalMeasurementXlsLineNumber == null && categoryId != Constants.PriceOfferLineCategory.Comment))) {

        return true;
      }
      if (targetNode.data.isDetailedLine && categoryId != Constants.PriceOfferLineCategory.Comment) {
        return true;
      }
      if (targetNode.data.isSubItem && (categoryId == Constants.PriceOfferLineCategory.Chapter || (moveType == Constants.PriceOfferLineMoveAction.Into && categoryId != Constants.PriceOfferLineCategory.Comment))) {
        return true;
      }
    }
    if (targetNode.data.priceOfferLineCategoryId == Constants.PriceOfferLineCategory.Comment && categoryId == Constants.PriceOfferLineCategory.Chapter && targetNode.parent.data?.priceOfferLineCategoryId == Constants.PriceOfferLineCategory.Data) {
      return true;
    }
    return false;
  }

  private async addAdditionalWork(targetRowId: string, addAdditionalWorkId: number) {
    let targetNode = this.priceOfferLinesGrid.gridOptions.api.getRowNode(targetRowId.toString());
    if (!targetNode) {
      console.warn('Target node not found!');
      return;
    }

    targetNode.data.isSelected = false;

    this.globalLoaderService.allow();
    this.priceOfferLinesGrid.gridOptions.api.showLoadingOverlay();
    let ids = await this.realizationApiService.addAdditionalWork(this.projectId, parseInt(targetNode.data.id), addAdditionalWorkId);
    this.priceOfferLinesGrid.gridOptions.api.hideOverlay();

    this.priceOfferLinesGrid.refreshServerSideRows(ids, targetNode.data.parentId == null);
  }

  public async computeMeteringTotalPrice(id: string, dataLineApiService: IDataLineApiService, _this: any) {
    _this.footer = await dataLineApiService.getMeteringTotalPrices(parseInt(id));
  }
  public async getExcelFileName() {
    return (await this.projectService.getEntityById(this.projectId)).projectName + "_" + this.i18n.tr("projectindex.realization") + "_" + this.i18n.tr("realizationindex.metering")
  }
}

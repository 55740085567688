import { ReportResult } from './../../../services/report-api-service';
import { Merlin } from 'generated';
import { I18N } from 'aurelia-i18n';
import { autoinject } from "aurelia-dependency-injection";
import { DialogController } from 'aurelia-dialog';
import { CustomLogger, IFile, StepElement } from 'digiwall-lib';
import * as Constants from '../../../constants';
import { NotificationLanguageDTO } from 'email-sender/email-sender';
import { HttpClient } from 'aurelia-fetch-client';
import { DocumentTypeDiscriminator } from 'request-supplier-offers/request-supplier-offer-detail';
import { RefToDisplay } from '../../../constants';

@autoinject
export class SendOffer {
  public stepElements: Array<StepElement> = [
    {
      id: "coverLetter",
      step: 1,
      label: this.i18n.tr("priceofferversion.step1"),
      disabledClick: () => { return false; }
    },
    {
      id: "annex",
      step: 2,
      label: this.i18n.tr("priceofferversion.step2"),
      disabledClick: () => { return false; }
    },
    {
      id: "document",
      step: 3,
      label: this.i18n.tr("priceofferversion.step3"),
      disabledClick: () => { return false; }
    },
    {
      id: "mail",
      step: 4,
      label: this.i18n.tr("priceofferversion.step4"),
      disabledClick: () => { return false; }
    }
  ] as Array<StepElement>;
  public currentElement: StepElement;

  public generationParams: GenerationParams;
  public returnParams: ReturnParams;

  private hasGeneratedFiles: boolean = false;

  private hasCoverLetter: boolean;
  private hasAnnex: boolean;

  private files: IFile[] = [];
  public previewRequestParams: Merlin.Web.Model.EmailTemplatePreviewRequest = {};
  private receiverIds: Array<number> = []
  private companyIds: Array<number> = []
  private notification: Map<number, NotificationLanguageDTO> = new Map<number, NotificationLanguageDTO>();
  private projectId: number
  private title: string;

  constructor(private i18n: I18N, private dialogController: DialogController, private httpClient: HttpClient, private logger: CustomLogger) {
  }

  async activate(params: any) {
    this.title = params.title;
    this.currentElement = this.stepElements[0];
    this.currentElement.isActif = true;

    if (params.generationParams) {
      this.generationParams = params.generationParams;

      this.companyIds.push(...this.generationParams.companyIds);
      this.previewRequestParams = this.generationParams.previewRequestParams;
      this.previewRequestParams.projectId = this.generationParams.projectId;
      this.previewRequestParams.toThirdPartyId = this.companyIds[0];

      this.hasAnnex = SendOffer.htmlIsNotEmpty(this.generationParams.annexContentHtml);
      this.hasCoverLetter = SendOffer.htmlIsNotEmpty(this.generationParams.coverLetterContentHtml);

      this.returnParams = {
        notificationId: null,
        coverLetterContentHtml: this.generationParams.coverLetterContentHtml ?? "",
        annexContentHtml: this.generationParams.annexContentHtml ?? "",
        signatoryIds: this.generationParams.signatoryIds ?? [],
        files: []
      }
    }
    else {
      await this.close();
    }
  }

  private async nextStep() {
    let newIndex = this.stepElements.findIndex(x => x == this.currentElement) + 1;
    this.currentElement.isValidStep = true;
    let newCurrentElement = this.stepElements[newIndex]
    if (newCurrentElement) {
      if (this.currentElement.step == 1 && !this.hasCoverLetter) {
        this.returnParams.coverLetterContentHtml = null;
      }
      if (this.currentElement.step == 2) {
        if (!this.hasAnnex) {
          this.returnParams.annexContentHtml = null;
        }
        if (this.generationParams.afterStep2 != null) {
          await this.generationParams.afterStep2(this.returnParams);
        }
      }
      this.currentElement = newCurrentElement;
      this.currentElement.isActif = true;
    }
  }

  private get canFinishStep1() {
    return !this.hasCoverLetter || (this.returnParams.signatoryIds.length > 0 && this.returnParams.signatoryIds.length <= 3 && SendOffer.htmlIsNotEmpty(this.returnParams.coverLetterContentHtml))
  }

  private get canFinishStep2() {
    return !this.hasCoverLetter || SendOffer.htmlIsNotEmpty(this.returnParams.annexContentHtml);
  }

  async endProcess() {
    await this.dialogController.close(true, this.returnParams);
  }

  async close() {
    await this.dialogController.close(false);
  }

  async sendMail() {
    if (this.receiverIds.length == 0) {
      this.logger.LogError(this.i18n.tr("requestsupplieroffer.toIsRequired"), null, null, true);
      return false;
    }
    let result = await this.httpClient.post(Constants.Application.EmailController.CreateEmail, JSON.stringify({ IdsToSend: this.receiverIds, NotifsLang: Object.fromEntries(this.notification.entries()), PreviewRequestParams: this.previewRequestParams }));

    if (result.ok) {
      let output = await result.json();
      let firstElem = true
      let notifId;
      output.forEach((value, key) => {
        if (firstElem) {
          notifId = value;
          firstElem = false;
        }
      });
      this.returnParams.notificationId = notifId;
      this.logger.Log(this.i18n.tr("requestsupplieroffer.mailSended"), null, null, true);
      await this.endProcess();
    }
    else {
      let error = await result.text();
      this.logger.LogError(error, null, null, true)
    }
  }

  public static htmlIsNotEmpty(html: string) {
    if (html != null) {
      const parser = new DOMParser();
      const { textContent } = parser.parseFromString(html, "text/html").documentElement;
      return textContent.trim().replace(new RegExp("(?=.ql-editor)(.*)(?:})", 's'), "").length > 0;
    }
    return false;
  }
}
export class GenerationParams {
  projectTypeId: number;
  projectId: number;
  companyIds: number[];
  coverLetterContentHtml: string;
  annexContentHtml: string;
  coverLetterContext: number;
  annexContext: number;
  emailContext: number;
  languageId: number;
  signatoryIds: number[] = [];
  previewRequestParams: Merlin.Web.Model.EmailTemplatePreviewRequest;
  afterStep2: (data: ReturnParams) => void | Promise<void>;
  hasSignatoryExternalStep3: boolean = false;
  hasSignatoryInternalStep3: boolean = false;
  items: GenerationOptionGroup[];
}

export class GenerationOptionGroup {
  id: number;
  name: string;
  items?: GenerationOptionButton[];
  defaultHandler?: (data?: GenerationApiParams) => Promise<ReportResult>;
}

export class GenerationOptionButton {
  id: number;
  icon?: string;
  title?: string;
  description?: string;
  selected?: boolean = false;
  disabled?: boolean | (() => boolean);
  handler?: (data?: GenerationApiParams) => Promise<ReportResult>;
}


export class ReturnParams {
  notificationId: number | null;
  coverLetterContentHtml: string;
  annexContentHtml: string;
  signatoryIds: number[];
  files: IFile[];
}

export class GenerationApiParams {
  includeTotalInLetter: boolean;
  displayIncluded: boolean;
  selectedRefToDisplay: RefToDisplay;
  currentSelectionState: { [id: number]: number } //Dictionnary representing current selection state. Key is the id of the group and the value is the id of the selected option. If null, no option selected
  architectSelectedId: number
  internSelectedId: number
}

import { ListViewModelBase, CustomLogger, ServiceBase, FieldType, EnumerationTypeService } from 'digiwall-lib';
import { Router } from 'aurelia-router';
import { autoinject } from 'aurelia-framework';
import { Merlin } from "../generated";
import { ColDef, FilterManager } from "ag-grid-community";
import * as Constants from '../constants';
import { GridHelperMerlin } from 'resources/elements/grid-helper';


@autoinject
export class ThirdPartyList extends ListViewModelBase<Merlin.Web.Model.ThirdParty> {
  constructor(router: Router, logger: CustomLogger) {

    super(router, logger, new ServiceBase<Merlin.Web.Model.ThirdParty>(Constants.EntityTypeNames.ThirdParty));


    this.service.gridDataSource.expands = ['civility', 'companyType', 'spokenLanguage',
      'thirdPartyTypes.thirdPartyTypeEnum', 'thirdPartyTags.thirdPartyTagEnum',
      'thirdPartyActivityCodes.activityCode', 'subType', 'mainAddress.country', 'mainAddress.locality'];
    this.service.gridDataSource.queryParameters = { onlyActive: false }

  }

  public buttonLabel: string = this.i18n.tr("thirdparty.addThirdParty");
  public ressourceName: string = Constants.EntityTypeNames.ThirdParty;
  public pathDetail: string;
  // public currentFilter: Array<CurrentSelectedFilter>;
  private thirdPartyTypeService: EnumerationTypeService;
  private thirdPartyTagService: EnumerationTypeService;
  private activityCodeService: EnumerationTypeService;

  public getDetailsUrl(self, entity: Merlin.Web.Model.ThirdParty) {
    if (entity?.id) {
      return this.pathDetail + entity.id;
    }
    return null;
  }

  public initializeGridOptions() {
    this.pathDetail = '/third-parties/';
    super.initializeGridOptions(false, false);
  }

  public getDataGridColumns() {
    this.thirdPartyTypeService = new EnumerationTypeService(Constants.EnumerationTypes.ThirdPartyType);
    this.thirdPartyTagService = new EnumerationTypeService(Constants.EnumerationTypes.ThirdPartyTag);
    this.activityCodeService = new EnumerationTypeService(Constants.EnumerationTypes.ActivityCode);

    let defs: ColDef[] = [
      ...new GridHelperMerlin().contextMenuColdDef(this),
      {
        headerName: this.i18n.tr("enumerationtype.active"),
        field: "active",
        type: FieldType.Boolean,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("thirdparty.isCompany"),
        field: "isCompany",
        type: FieldType.Boolean,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("thirdparty.companyTypeId"),
        field: "companyType.denomination._translation",
        type: FieldType.Enumeration,
        filterParams: {
          category: Constants.EnumerationTypes.CompanyType,
        },
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          category: Constants.EnumerationTypes.CompanyType,
        },
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("thirdparty.vatNumber"),
        field: "vatNumber",
        type: FieldType.String,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("thirdparty.lastNameCompanyName"),
        field: "lastNameCompanyName",
        type: FieldType.String,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("thirdparty.firstName"),
        field: "firstName",
        type: FieldType.String,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("thirdparty.civilityId"),
        field: "civility.denomination._translation",
        type: FieldType.Enumeration,
        filterParams: {
          category: Constants.EnumerationTypes.Civility,
        },
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          category: Constants.EnumerationTypes.Civility,
        },
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("thirdparty.spokenLanguageId"),
        field: "spokenLanguage.denomination._translation",
        type: FieldType.Enumeration,
        filterParams: {
          category: Constants.EnumerationTypes.Language,
        },
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          category: Constants.EnumerationTypes.Language,
        },
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("thirdparty.phoneNumber"),
        field: "phoneNumber",
        type: FieldType.String,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("thirdparty.mobilePhoneNumber"),
        field: "mobilePhoneNumber",
        type: FieldType.String,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("thirdparty.uniqueCode"),
        field: "uniqueCode",
        hide: true,
        type: FieldType.String,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("thirdparty.emailAddress"),
        field: "emailAddress",
        hide: true,
        type: FieldType.String,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("thirdparty.website"),
        field: "website",
        hide: true,
        type: FieldType.String,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("thirdparty.thirdPartyTypeId"),
        field: "thirdPartyTypes.thirdPartyTypeEnum.denomination._translation",
        type: FieldType.ManyToMany,
        filterParams: {
          service: this.thirdPartyTypeService
        },
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          service: this.thirdPartyTypeService,
        },
        cellRenderer: "manyToManyTagRenderer",
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("thirdparty.thirdPartyTagId"),
        field: "thirdPartyTags.thirdPartyTagEnum.denomination._translation",
        type: FieldType.ManyToMany,
        filterParams: {
          service: this.thirdPartyTagService
        },
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          service: this.thirdPartyTagService,
        },
        cellRenderer: "manyToManyTagRenderer",
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("thirdparty.cotations"),
        field: "cotations",
        hide: true,
        type: FieldType.Number,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("thirdparty.thirdPartyActivityCodes"),
        field: "thirdPartyActivityCodes.activityCode.denomination._translation",
        type: FieldType.ManyToMany,
        filterParams: {
          service: this.activityCodeService
        },
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          service: this.activityCodeService,
        },
        cellRenderer: "manyToManyTagRenderer",
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("thirdparty.partnerTypeId"),
        field: "subType.denomination._translation",
        type: FieldType.Enumeration,
        filterParams: {
          category: Constants.EnumerationTypes.PartnerType,
        },
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          category: Constants.EnumerationTypes.PartnerType,
        },
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("thirdparty.noteMultiline"),
        field: "noteMultiline",
        hide: true,
        type: FieldType.String,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("address.streetMain"),
        field: "mainAddress.streetMain",
        type: FieldType.String,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("address.postalCode"),
        field: "mainAddress.postalCode",
        type: FieldType.String,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("address.localityId"),
        field: "mainAddress.city",
        type: FieldType.String,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("address.countryId"),
        field: "mainAddress.country.name._translation",
        type: FieldType.String,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      ...new GridHelperMerlin().baseEntityColDef(this),
    ];
    return defs;
  }

  importThirdParty() {
    this.manager.clear(); //Clear cache breeze for entities delete in backend
    this.router.navigateToRoute('import-data', { "context": "thirdparty", "errorView": "third-parties/import-third-party-error-table.html", configView: "third-parties/thirdparty-config.html" });
  }
} 

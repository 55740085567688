import { EnumerationType } from 'digiwall-lib';
import { Merlin } from './../generated.d';
import { HttpClient } from 'aurelia-fetch-client';
import * as Constants from '../constants';
import { autoinject } from 'aurelia-framework';

@autoinject
export class MeteringApiService {

  constructor(private httpClient: HttpClient) {

  }

  async getAllEnum(): Promise<MeteringEnum> {
    let requestUri = Constants.Application.MeteringController.GetAllEnum;
    let query = await this.httpClient.get(requestUri);
    if (query.ok) {
      return await query.json();
    }
  }
}


export interface MeteringEnum {
  quantityTypes: Merlin.Web.Model.MarketType[];
  units: Merlin.Web.Model.Unit[];
  itemDeliveryType: EnumerationType[];
  activityCodes: EnumerationType[];
  priceOfferLineTypes: EnumerationType[];
  priceOfferLineTags: EnumerationType[];
  dataLineFeatures: EnumerationType[];
  buyingClientValidationStatus: EnumerationType[];
  clientAdditionalWorkStatus: EnumerationType[];
  buildingCosts: EnumerationType[];
}

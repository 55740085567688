import { Predicate, FilterQueryOp } from 'breeze-client';
import { Router } from 'aurelia-router';
import { CustomLogger, EditingModeEnum, ServiceBase, EntityDetailViewModelBase, Various, PictureHelper } from 'digiwall-lib';
import { Merlin } from "../generated";
import { autoinject } from 'aurelia-framework';
import * as Constants from '../constants';
import { AttachExternalEmailDetail } from './attach-external-emails/attach-external-email-detail';

@autoinject
export class ExternalEmailDetail extends EntityDetailViewModelBase<Merlin.Web.Model.ExternalEmail> {
  public ressourceName: string = Constants.EntityTypeNames.ExternalEmail;

  constructor(router: Router, logger: CustomLogger, private pictureHelper: PictureHelper) {
    super(router, logger);

    super.initialize(new ServiceBase<Merlin.Web.Model.ExternalEmail>(Constants.EntityTypeNames.ExternalEmail));
  }

  public async activate(params: any) {
    super.activate(params);
    var id = params.param1;
    if (id != Various.NewId) {
      this.editingMode = EditingModeEnum.Update;
      this.entity = await this.service.getEntityById(id, "externalEmailFiles");
      this.controller.addObject(this.entity);
    }
  }

  public get documentTitle() {
    if (this.isCreationMode) {
      return this.i18n.tr("externalemail.externalemail");
    }
    else {
      return '';
    }
  }

  public async attachExternalEmail() {
    await this.dialogService.open({ viewModel: AttachExternalEmailDetail, model: { param1: this.entity.id } });
  }

}

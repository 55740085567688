import { HttpClient } from 'aurelia-fetch-client';
import { autoinject } from 'aurelia-framework';
import * as Constants from '../constants';
import { ActionDialogBoxInputParameters, Box, CustomLogger, DialogBoxViewModel } from 'digiwall-lib';
import { I18N } from 'aurelia-i18n';
import { MeteringTotalPrices, IPriceOfferLineGridService, RequestCreatePriceOfferLine, RequestFilterPriceOfferLine, RequestMovePriceOfferLine, BulkUpdateOperation } from './i-data-line-api-service';
import { PriceOfferLineFromItem } from 'projects/quotes/add-items/add-items';
import { JSONPatchOperation } from './json-patch-operation';
import { RefToDisplay } from '../constants';

@autoinject
export class ClientProgressStatementLineApiService implements IPriceOfferLineGridService {
  protected callback: () => void;

  public projectId: number;

  constructor(protected httpClient: HttpClient, private box: Box, private i18n: I18N, private logger: CustomLogger) { }
  createFromItems(versionId: number, selectedItems: PriceOfferLineFromItem[]): Promise<number[]> | Error {
    throw new Error('Method not implemented.');
  }
  subscribe(callback: () => void) {
    this.callback = callback;
  }


  public async select(lineId: number) {
    return null;
  }
  public async unselect(lineId: number) {
    return null;
  }

  public async children(versionId: number, lineId: number, displayHidden: boolean, refToDisplay: RefToDisplay): Promise<Array<any>> {
    let requestUri = Constants.Application.ClientProgressStatementLineController.Children.format(this.projectId.toString(), lineId.toString(), displayHidden.toString(), refToDisplay.toString());
    let query = await this.httpClient.get(requestUri);
    if (query.ok) {
      return await query.json();
    }
  }

  public async filter(versionId: number, filterParams: any, quickFilter: string, displayMode: Constants.TreeDisplayMode, displayHidden: boolean, refToDisplay: RefToDisplay): Promise<Array<any>> {
    let requestParams: RequestFilterPriceOfferLine = {
      filterModel: filterParams,
      quickFilter: quickFilter,
      displayMode: displayMode,
      displayHidden: displayHidden,
      refToDisplay: refToDisplay
    };
    let requestUri = Constants.Application.ClientProgressStatementLineController.Filter.format(this.projectId.toString());
    let response = await this.httpClient.post(requestUri, JSON.stringify(requestParams));
    if (response.ok) {
      return await response.json();
    }
  }

  public async fetch(versionId: number, ids: number[], displayHidden: boolean, refToDisplay: RefToDisplay) {
    let requestUri = Constants.Application.ClientProgressStatementLineController.Fetch.format(this.projectId.toString(), refToDisplay.toString());
    let result = await this.httpClient.post(requestUri, JSON.stringify(ids));
    if (result.ok) {
      let entities: Array<any> = await result.json();
      return entities;
    }
    else {
      return null;
    }
  }

  public async patch(versionId: number, lineId: number, propertyName: string, propertyValue: any): Promise<Array<any>> {
    let requestUri = Constants.Application.ClientProgressStatementLineController.PatchLine.format(this.projectId.toString(), lineId.toString());
    let patchResponse = await this.httpClient.patch(requestUri, JSON.stringify(JSONPatchOperation.operateReplace(propertyName, propertyValue)));
    if (patchResponse.ok) {
      return await patchResponse.json();
    } else {
      if (patchResponse.status == 400) {
        return [lineId];
      }
    }
    return [];
  }

  public async bulkPatch(versionId: number, bulkUpdateOperation: BulkUpdateOperation[]): Promise<Array<any>> {
    let requestUri = Constants.Application.ClientProgressStatementLineController.BulkPatch.format(versionId.toString());
    const params = bulkUpdateOperation.map(x => {
      return {
        lineId: x.lineId,
        patchDoc: JSONPatchOperation.operateReplace(x.propertyName, x.propertyValue)
      }
    });
    let patchResponse = await this.httpClient.patch(requestUri, JSON.stringify(params));
    if (patchResponse.ok) {
      return await patchResponse.json();
    } else {
      if (patchResponse.status == 400) {
        return bulkUpdateOperation.map(x => x.lineId);
      }
    }
    return [];
  }

  async CheckCanCreateProgressStatement(projectId: number): Promise<boolean> {
    let query = await this.httpClient.get(Constants.Application.ClientProgressStatementLineController.CheckCanCreateProgressStatement.format(projectId.toString()));
    if (query.ok) {
      let result = await query.json();
      return result;
    }
  }



  public async getLastProgressStatementId(projectId: number) {
    let query = await this.httpClient.get(Constants.Application.ClientProgressStatementLineController.GetLastProgressStatementId.format(projectId.toString()));
    if (query.ok) {
      let result = await query.json();
      return result;
    }
  }

  public async synchroWithSubContractor(projectId: number, subContractorIds: number[]) {
    let query = await this.httpClient.post(Constants.Application.ClientProgressStatementLineController.SynchroData.format(projectId.toString()), JSON.stringify(subContractorIds));
    if (query.ok) {
      let result = await query.json();
      return result;
    }
  }

  public async getTotals() {
    let requestUri = Constants.Application.ClientProgressStatementLineController.GetTotals.format(this.projectId.toString());
    let result = await this.httpClient.get(requestUri);
    if (result.ok) {
      return await result.json();
    }
    else {
      let text = await result.text();
      this.box.showWarning(this.i18n.tr(text), this.i18n.tr('general.errorTitle'));
      return null;
    }
  }

}
@autoinject
export class SubContractorProgressStatementLineApiService implements IPriceOfferLineGridService {
  protected callback: () => void;

  public projectId: number;
  public subContractorId: number;

  constructor(protected httpClient: HttpClient, private box: Box, private i18n: I18N, private logger: CustomLogger) { }
  createFromItems(versionId: number, selectedItems: PriceOfferLineFromItem[]): Promise<number[]> | Error {
    throw new Error('Method not implemented.');
  }
  subscribe(callback: () => void) {
    this.callback = callback;
  }


  public async select(lineId: number) {
    return null;
  }
  public async unselect(lineId: number) {
    return null;
  }

  public async children(versionId: number, lineId: number, displayHidden: boolean, refToDisplay: RefToDisplay): Promise<Array<any>> {
    let requestUri = Constants.Application.SubContractorProgressStatementLineController.Children.format(this.projectId.toString(), lineId.toString(), this.subContractorId.toString(), displayHidden.toString(), refToDisplay.toString());
    let query = await this.httpClient.get(requestUri);
    if (query.ok) {
      return await query.json();
    }
  }

  public async filter(versionId: number, filterParams: any, quickFilter: string, displayMode: Constants.TreeDisplayMode, displayHidden: boolean, refToDisplay: RefToDisplay): Promise<Array<any>> {
    let requestParams: RequestFilterPriceOfferLine = {
      filterModel: filterParams ?? {},
      quickFilter: quickFilter,
      displayMode: displayMode,
      displayHidden: displayHidden,
      refToDisplay: refToDisplay
    };
    let requestUri = Constants.Application.SubContractorProgressStatementLineController.Filter.format(this.projectId.toString(), this.subContractorId.toString());
    let response = await this.httpClient.post(requestUri, JSON.stringify(requestParams));
    if (response.ok) {
      return await response.json();
    }
  }

  public async fetch(versionId: number, ids: number[], displayHidden: boolean, refToDisplay: RefToDisplay) {
    let requestUri = Constants.Application.SubContractorProgressStatementLineController.Fetch.format(this.projectId.toString(), refToDisplay.toString()) + "?subContractorId=" + this.subContractorId.toString();;
    let result = await this.httpClient.post(requestUri, JSON.stringify(ids));
    if (result.ok) {
      let entities: Array<any> = await result.json();
      return entities;
    }
    else {
      return null;
    }
  }

  public async patch(versionId: number, lineId: number, propertyName: string, propertyValue: any): Promise<Array<any>> {
    let requestUri = Constants.Application.SubContractorProgressStatementLineController.PatchLine.format(this.projectId.toString(), lineId.toString()) + "?subContractorId=" + this.subContractorId.toString();
    let patchResponse = await this.httpClient.patch(requestUri, JSON.stringify(JSONPatchOperation.operateReplace(propertyName, propertyValue)));
    if (patchResponse.ok) {
      return await patchResponse.json();
    } else {
      if (patchResponse.status == 400) {
        return [lineId];
      }
    }
    return [];
  }


  public async bulkPatch(versionId: number, bulkUpdateOperation: BulkUpdateOperation[]): Promise<Array<any>> {
    let requestUri = Constants.Application.SubContractorProgressStatementLineController.BulkPatch.format(versionId.toString());
    const params = bulkUpdateOperation.map(x => {
      return {
        lineId: x.lineId,
        patchDoc: JSONPatchOperation.operateReplace(x.propertyName, x.propertyValue)
      }
    });
    let patchResponse = await this.httpClient.patch(requestUri, JSON.stringify(params));
    if (patchResponse.ok) {
      return await patchResponse.json();
    } else {
      if (patchResponse.status == 400) {
        return bulkUpdateOperation.map(x => x.lineId);
      }
    }
    return [];
  }

  async CheckCanCreateProgressStatement(projectId: number): Promise<boolean> {
    let query = await this.httpClient.get(Constants.Application.SubContractorProgressStatementLineController.CheckCanCreateProgressStatement.format(projectId.toString()));
    if (query.ok) {
      let result = await query.json();
      return result;
    }
  }



  public async getLastProgressStatementId(projectId: number, subContractorId: number) {
    let query = await this.httpClient.get(Constants.Application.SubContractorProgressStatementLineController.GetLastProgressStatementId.format(projectId.toString(), subContractorId.toString()));
    if (query.ok) {
      let result = await query.json();
      return result;
    }
  }

  public async synchroWithSubContractor(projectId: number, subContractorId: number) {
    let query = await this.httpClient.get(Constants.Application.SubContractorProgressStatementLineController.SynchroData.format(projectId.toString(), subContractorId.toString()));
    if (query.ok) {
      let result = await query.json();
      return result;
    }
  }

  public async getTotals(projectId: number, subContractorId: number) {
    let requestUri = Constants.Application.SubContractorProgressStatementLineController.GetTotals.format(projectId.toString(), subContractorId.toString());
    let result = await this.httpClient.get(requestUri);
    if (result.ok) {
      return await result.json();
    }
    else {
      let text = await result.text();
      this.box.showWarning(this.i18n.tr(text), this.i18n.tr('general.errorTitle'));
      return null;
    }
  }

}
@autoinject
export class ClientProgressStatementApiService implements IPriceOfferLineGridService {

  protected callback: () => void;

  public projectId: number;
  public progressStatementId: number;
  public progressStatementVersionId: number;

  constructor(protected httpClient: HttpClient, private box: Box, private i18n: I18N, private logger: CustomLogger) { }
  createFromItems(versionId: number, selectedItems: PriceOfferLineFromItem[]): Promise<number[]> | Error {
    throw new Error('Method not implemented.');
  }
  subscribe(callback: () => void) {
    this.callback = callback;
  }


  public async select(lineId: number) {
    return null;
  }
  public async unselect(lineId: number) {
    return null;
  }

  public async children(versionId: number, lineId: number, displayHidden: boolean, refToDisplay: RefToDisplay): Promise<Array<any>> {
    let requestUri = Constants.Application.ClientProgressStatementVersionController.Children.format(this.projectId.toString(), lineId.toString(), displayHidden.toString(), refToDisplay.toString());
    let query = await this.httpClient.get(requestUri);
    if (query.ok) {
      return await query.json();
    }
  }

  public async filter(versionId: number, filterParams: any, quickFilter: string, displayMode: Constants.TreeDisplayMode, displayHidden: boolean, refToDisplay: RefToDisplay): Promise<Array<any>> {
    let requestParams: RequestFilterPriceOfferLine = {
      filterModel: filterParams,
      quickFilter: quickFilter,
      displayMode: displayMode,
      displayHidden: displayHidden,
      refToDisplay: refToDisplay
    };
    let requestUri = Constants.Application.ClientProgressStatementVersionController.Filter.format(this.projectId.toString(), this.progressStatementVersionId.toString());
    let response = await this.httpClient.post(requestUri, JSON.stringify(requestParams));
    if (response.ok) {
      return await response.json();
    }
  }

  public async fetch(versionId: number, ids: number[], displayHidden: boolean, refToDisplay: RefToDisplay) {
    let requestUri = Constants.Application.ClientProgressStatementVersionController.Fetch.format(this.projectId.toString(), this.progressStatementVersionId.toString(), refToDisplay.toString());
    let result = await this.httpClient.post(requestUri, JSON.stringify(ids));
    if (result.ok) {
      let entities: Array<any> = await result.json();
      return entities;
    }
    else {
      return null;
    }
  }

  public async patch(versionId: number, lineId: number, propertyName: string, propertyValue: any): Promise<Array<any>> {
    let requestUri = Constants.Application.ClientProgressStatementVersionController.PatchLine.format(this.projectId.toString(), lineId.toString());
    let patchResponse = await this.httpClient.patch(requestUri, JSON.stringify(JSONPatchOperation.operateReplace(propertyName, propertyValue)));
    if (patchResponse.ok) {
      return await patchResponse.json();
    } else {
      if (patchResponse.status == 400) {
        return [lineId];
      }
    }
    return [];
  }


  public async bulkPatch(versionId: number, bulkUpdateOperation: BulkUpdateOperation[]): Promise<Array<any>> {
    let requestUri = Constants.Application.ClientProgressStatementVersionController.BulkPatch.format(versionId.toString());
    const params = bulkUpdateOperation.map(x => {
      return {
        lineId: x.lineId,
        patchDoc: JSONPatchOperation.operateReplace(x.propertyName, x.propertyValue)
      }
    });
    let patchResponse = await this.httpClient.patch(requestUri, JSON.stringify(params));
    if (patchResponse.ok) {
      return await patchResponse.json();
    } else {
      if (patchResponse.status == 400) {
        return bulkUpdateOperation.map(x => x.lineId);
      }
    }
    return [];
  }

  public async afterCreateProgressStatement() {
    let requestUri = Constants.Application.ClientProgressStatementVersionController.AfterCreateProgressStatement.format(this.projectId.toString(), this.progressStatementVersionId.toString());
    let result = await this.httpClient.get(requestUri);
    if (!result.ok) {
      let text = await result.text();
      this.box.showWarning(this.i18n.tr(text), this.i18n.tr('general.errorTitle'));
      return null;
    }
  }

  public async afterValidatingProgressStatement() {
    let requestUri = Constants.Application.ClientProgressStatementVersionController.AfterValidatingProgressStatementVersion.format(this.projectId.toString(), this.progressStatementVersionId.toString());
    let result = await this.httpClient.get(requestUri);
    if (!result.ok) {
      let text = await result.text();
      this.box.showWarning(this.i18n.tr(text), this.i18n.tr('general.errorTitle'));
      return null;
    }
  }

  public async deleteCurrentVersion() {
    let requestUri = Constants.Application.ClientProgressStatementVersionController.DeleteProgressStatementVersion.format(this.projectId.toString(), this.progressStatementVersionId.toString());
    let result = await this.httpClient.delete(requestUri);
    if (result.ok) {
      return await result.json();
    }
    else {
      let text = await result.text();
      this.box.showWarning(this.i18n.tr(text), this.i18n.tr('general.errorTitle'));
      return null;
    }
  }

  public async getTotals() {
    let requestUri = Constants.Application.ClientProgressStatementVersionController.GetTotals.format(this.projectId.toString(), this.progressStatementVersionId.toString());
    let result = await this.httpClient.get(requestUri);
    if (result.ok) {
      return await result.json();
    }
    else {
      let text = await result.text();
      this.box.showWarning(this.i18n.tr(text), this.i18n.tr('general.errorTitle'));
      return null;
    }
  }
}

@autoinject
export class SubContractorProgressStatementApiService implements IPriceOfferLineGridService {

  protected callback: () => void;

  public projectId: number;
  public progressStatementId: number;
  public progressStatementVersionId: number;
  public subContractorId: number;

  constructor(protected httpClient: HttpClient, private box: Box, private i18n: I18N, private logger: CustomLogger) { }
  createFromItems(versionId: number, selectedItems: PriceOfferLineFromItem[]): Promise<number[]> | Error {
    throw new Error('Method not implemented.');
  }
  subscribe(callback: () => void) {
    this.callback = callback;
  }


  public async select(lineId: number) {
    return null;
  }
  public async unselect(lineId: number) {
    return null;
  }

  public async children(versionId: number, lineId: number, displayHidden: boolean, refToDisplay: RefToDisplay): Promise<Array<any>> {
    let requestUri = Constants.Application.SubContractorProgressStatementVersionController.Children.format(this.projectId.toString(), lineId.toString(), displayHidden.toString(), refToDisplay.toString());
    let query = await this.httpClient.get(requestUri);
    if (query.ok) {
      return await query.json();
    }
  }

  public async filter(versionId: number, filterParams: any, quickFilter: string, displayMode: Constants.TreeDisplayMode, displayHidden: boolean, refToDisplay: RefToDisplay): Promise<Array<any>> {
    let requestParams: RequestFilterPriceOfferLine = {
      filterModel: filterParams,
      quickFilter: quickFilter,
      displayMode: displayMode,
      displayHidden: displayHidden,
      refToDisplay: refToDisplay
    };
    let requestUri = Constants.Application.SubContractorProgressStatementVersionController.Filter.format(this.projectId.toString(), this.progressStatementVersionId.toString(), this.subContractorId.toString());
    let response = await this.httpClient.post(requestUri, JSON.stringify(requestParams));
    if (response.ok) {
      return await response.json();
    }
  }

  public async fetch(versionId: number, ids: number[], displayHidden: boolean, refToDisplay: RefToDisplay) {
    let requestUri = Constants.Application.SubContractorProgressStatementVersionController.Fetch.format(this.projectId.toString(), this.progressStatementVersionId.toString(), refToDisplay.toString());
    let result = await this.httpClient.post(requestUri, JSON.stringify(ids));
    if (result.ok) {
      let entities: Array<any> = await result.json();
      return entities;
    }
    else {
      return null;
    }
  }

  public async patch(versionId: number, lineId: number, propertyName: string, propertyValue: any): Promise<Array<any>> {
    let requestUri = Constants.Application.SubContractorProgressStatementVersionController.PatchLine.format(this.projectId.toString(), lineId.toString());
    let patchResponse = await this.httpClient.patch(requestUri, JSON.stringify(JSONPatchOperation.operateReplace(propertyName, propertyValue)));
    if (patchResponse.ok) {
      return await patchResponse.json();
    } else {
      if (patchResponse.status == 400) {
        return [lineId];
      }
    }
    return [];
  }

  public async bulkPatch(versionId: number, bulkUpdateOperation: BulkUpdateOperation[]): Promise<Array<any>> {
    let requestUri = Constants.Application.SubContractorProgressStatementVersionController.BulkPatch.format(versionId.toString());
    const params = bulkUpdateOperation.map(x => {
      return {
        lineId: x.lineId,
        patchDoc: JSONPatchOperation.operateReplace(x.propertyName, x.propertyValue)
      }
    });
    let patchResponse = await this.httpClient.patch(requestUri, JSON.stringify(params));
    if (patchResponse.ok) {
      return await patchResponse.json();
    } else {
      if (patchResponse.status == 400) {
        return bulkUpdateOperation.map(x => x.lineId);
      }
    }
    return [];
  }

  public async afterCreateProgressStatement() {
    let requestUri = Constants.Application.SubContractorProgressStatementVersionController.AfterCreateProgressStatement.format(this.projectId.toString(), this.progressStatementVersionId.toString(), this.subContractorId.toString());
    let result = await this.httpClient.get(requestUri);
    if (!result.ok) {
      let text = await result.text();
      this.box.showWarning(this.i18n.tr(text), this.i18n.tr('general.errorTitle'));
      return null;
    }
  }

  public async afterValidatingProgressStatement() {
    let requestUri = Constants.Application.SubContractorProgressStatementVersionController.AfterValidatingProgressStatementVersion.format(this.projectId.toString(), this.progressStatementVersionId.toString());
    let result = await this.httpClient.get(requestUri);
    if (!result.ok) {
      let text = await result.text();
      this.box.showWarning(this.i18n.tr(text), this.i18n.tr('general.errorTitle'));
      return null;
    }
  }

  public async deleteCurrentVersion() {
    let requestUri = Constants.Application.SubContractorProgressStatementVersionController.DeleteProgressStatementVersion.format(this.projectId.toString(), this.progressStatementVersionId.toString());
    let result = await this.httpClient.delete(requestUri);
    if (result.ok) {
      return await result.json();
    }
    else {
      let text = await result.text();
      this.box.showWarning(this.i18n.tr(text), this.i18n.tr('general.errorTitle'));
      return null;
    }
  }

  public async getTotals() {
    let requestUri = Constants.Application.SubContractorProgressStatementVersionController.GetTotals.format(this.projectId.toString(), this.progressStatementVersionId.toString(), this.subContractorId.toString());
    let result = await this.httpClient.get(requestUri);
    if (result.ok) {
      return await result.json();
    }
    else {
      let text = await result.text();
      this.box.showWarning(this.i18n.tr(text), this.i18n.tr('general.errorTitle'));
      return null;
    }
  }
}

import { ICellRendererParams, RowNode } from "ag-grid-community";
import * as Constants from '../../../constants';
import { ActionDialogBoxInputParameters, Box, DialogBoxViewModel, EnumerationType, IMenuItems } from "digiwall-lib";
import { autoinject, Container } from "aurelia-framework";
import { I18N } from "aurelia-i18n";
import { IDataLineApiService, MeteringTotalPrices } from "services/i-data-line-api-service";
import { PriceOfferLinesGrid } from "price-offer-lines/price-offer-lines-grid";

@autoinject
export class MenuPostType {
  constructor() { }
  public getMenuItems(params: ICellRendererParams, priceOfferLineTypeList: Array<EnumerationType>, dataLineApiService: IDataLineApiService, versionId: string, priceOfferLinesGrid: PriceOfferLinesGrid, getMeteringTotalPrices: (id: string, dataLineApiService: IDataLineApiService, _this: any) => any, _this: any): IMenuItems {
    let i18n = Container.instance.get(I18N);

    let menuItems =
    {
      id: Constants.CommonMeterginDataLineMenuItems.POST_TYPE,
      label: i18n.tr("versionmetering.type"),
      icon: "digi-price-tag-3-line",
      items: [
        {
          group: i18n.tr("versionmetering.type"),
          items: this.getTypeItems(params, priceOfferLineTypeList, dataLineApiService, versionId, priceOfferLinesGrid, getMeteringTotalPrices, _this)
        }
      ]
    };
    return menuItems;
  }


  private getTypeItems(params: ICellRendererParams, priceOfferLineTypeList: Array<EnumerationType>, dataLineApiService: IDataLineApiService, versionId: string, priceOfferLinesGrid: PriceOfferLinesGrid, getMeteringTotalPrices: (id: string, dataLineApiService: IDataLineApiService, _this: any) => any, _this: any): Array<IMenuItems> {
    let arrayItems = new Array<IMenuItems>();
    arrayItems.push(this.getMenuItemType(priceOfferLineTypeList.find(x => x.id == Constants.PriceOfferLineType.Normal), params, dataLineApiService, versionId, priceOfferLinesGrid, getMeteringTotalPrices, _this));
    if (params.node.data.priceOfferLineCategoryId == Constants.PriceOfferLineCategory.Comment || params.node.data.isSubItem) {
      return arrayItems;
    }
    arrayItems.push(this.getMenuItemType(priceOfferLineTypeList.find(x => x.id == Constants.PriceOfferLineType.Option), params, dataLineApiService, versionId, priceOfferLinesGrid, getMeteringTotalPrices, _this));
    arrayItems.push(this.getMenuItemType(priceOfferLineTypeList.find(x => x.id == Constants.PriceOfferLineType.Variant), params, dataLineApiService, versionId, priceOfferLinesGrid, getMeteringTotalPrices, _this));

    if (params.node.data.priceOfferLineCategoryId == Constants.PriceOfferLineCategory.Chapter) {
      return arrayItems;
    }
    arrayItems.push(this.getMenuItemType(priceOfferLineTypeList.find(x => x.id == Constants.PriceOfferLineType.Omission), params, dataLineApiService, versionId, priceOfferLinesGrid, getMeteringTotalPrices, _this));
    arrayItems.push(this.getMenuItemType(priceOfferLineTypeList.find(x => x.id == Constants.PriceOfferLineType.Reduction), params, dataLineApiService, versionId, priceOfferLinesGrid, getMeteringTotalPrices, _this));
    arrayItems.push(this.getMenuItemType(priceOfferLineTypeList.find(x => x.id == Constants.PriceOfferLineType.FixedPrice), params, dataLineApiService, versionId, priceOfferLinesGrid, getMeteringTotalPrices, _this));
    return arrayItems;
  }

  private getMenuItemType(enumeration: EnumerationType, params: ICellRendererParams, dataLineApiService: IDataLineApiService, versionId: string, priceOfferLinesGrid: PriceOfferLinesGrid, getMeteringTotalPrices: (id: string, dataLineApiService: IDataLineApiService, _this: any) => any, _this: any): IMenuItems {
    let i18n = Container.instance.get(I18N);
    let box = Container.instance.get(Box);
    let patchLine = async (enumeration: EnumerationType, rowParams) => {
      let result: number[] = []
      if (params.node.data.lineTypeId == Constants.PriceOfferLineType.Variant && enumeration.id != Constants.PriceOfferLineType.Variant) {
        if (typeof dataLineApiService.deleteVariantGroup == 'function') {
          let buttonYes: ActionDialogBoxInputParameters =
          {
            label: i18n.tr("general.yes", { ns: "common" }),
            title: i18n.tr("general.yes", { ns: "common" }),
            theme: 'primary',
            type: 'solid',
            disabled: false,
            fn: (thisBox: DialogBoxViewModel) => {
              thisBox.controller.ok();
            }
          };
          let buttonNo: ActionDialogBoxInputParameters =
          {
            label: i18n.tr("general.no", { ns: "common" }),
            title: i18n.tr("general.no", { ns: "common" }),
            theme: 'dark',
            type: 'ghost',
            disabled: false,
            fn: (thisBox: DialogBoxViewModel) => {
              thisBox.controller.cancel();
            }
          };
          await box.showQuestion(i18n.tr('versionmetering.deleteVariantGroupQuestion'), i18n.tr('menu.question'), [buttonNo, buttonYes]).whenClosed(
            async (resultQuestion) => {
              if (!resultQuestion.wasCancelled) {
                result.push(...await dataLineApiService.deleteVariantGroup(parseInt(versionId), parseInt(rowParams.node.data.id)));
              }
            }
          )
        } else {
          console.warn('API deleteVariantGroup not implemented !');
        }
      }

      //params.node.data.lineTypeId = enumeration.id;
      priceOfferLinesGrid.gridOptions.api.showLoadingOverlay();

      if (enumeration.id == Constants.PriceOfferLineType.Variant) {
        if (!(typeof dataLineApiService.createVariantGroup == "function")) {
          console.warn('API createVariantGroup not implemented !');
        } else {
          let selectedRows = params.api.getSelectedRows() as any[];
          result.push(...await dataLineApiService.createVariantGroup(parseInt(versionId), selectedRows.map(x => x.id)));
        }
      }
      else {
        result.push(...await dataLineApiService.patch(parseInt(versionId), parseInt(rowParams.node.data.id), "lineTypeId", enumeration.id));
      }
      priceOfferLinesGrid.gridOptions.api.hideOverlay();
      let nodes = await priceOfferLinesGrid.refreshVisibleNodes(result);
      if (rowParams.node?.childStore?.forEachNodeDeep != null) {
        let childsNodes = [];
        (<RowNode>rowParams.node).childStore.forEachNodeDeep(node => childsNodes.push(node));
        if (childsNodes.length > 0) nodes = nodes.concat(childsNodes);
      }
      priceOfferLinesGrid.gridOptions.api.redrawRows({ rowNodes: nodes });
      priceOfferLinesGrid.callResizeAgCellLastLeftPinned();
      getMeteringTotalPrices(versionId, dataLineApiService, _this);
      params.api.deselectAll();
    };

    if (enumeration.id == Constants.PriceOfferLineType.Normal) {
      let temp = {
        label: enumeration.denomination._translation,
        handler: async () => {
          await patchLine(enumeration, params);
        }
      };
      return temp;
    }
    let temp = {
      label:
        `<div style="display:flex; gap:10px; align-items:center;justify-content: space-between;">
              <div>${enumeration.denomination._translation}</div>
              <div style='background-color:${enumeration.backgroundColor}; color:${enumeration.textColor};padding: 5px 15px;border-radius: 20px;'>
              ${enumeration.id == Constants.PriceOfferLineType.FixedPrice ? "FIX" : enumeration.denomination._translation.substring(0, 3).toUpperCase()}
              </div>
            </div>`,
      handler: async () => {
        await patchLine(enumeration, params);
      },
      disabled: () => {
        if (enumeration.id == Constants.PriceOfferLineType.Variant) {
          let selectedRows = params.api.getSelectedRows() as any[];
          if (selectedRows.length < 2) {
            return true
          }
          /*else {
            let parentId = selectedRows[0]?.parentId ?? null;
            return !selectedRows.every(x => x.parentId == parentId && x.lineTypeId != Constants.PriceOfferLineType.Variant);
          }*/
        }
        return false
      }
    };
    return temp;
  }
}

import { RequestSupplierOfferServiceStep1, RequestSupplierOfferService2Step1 } from './../../services/request-supplier-api-service';
import { HttpClient } from 'aurelia-fetch-client';
import { PriceOfferLinesGrid } from '../../price-offer-lines/price-offer-lines-grid';
import * as Constants from '../../constants';
import { Merlin } from 'generated';
import { autoinject, bindable, bindingMode, customElement, TaskQueue, BindingEngine } from "aurelia-framework";
import { RequestFilterPriceOfferLine } from 'services/i-data-line-api-service';
import { WorkQuotationVersionApiService } from 'services/work-quotation-version-api-service';
import { DocumentTypeDiscriminator } from 'request-supplier-offers/request-supplier-offer-detail';
import { ColumnVisible } from 'module-list-tree-data/module-list-tree-data';
import { RefToDisplay } from '../../constants';

@autoinject
@customElement("add-request-supplier-offer-step-1")
export class AddRequestSupplierOfferStep {

  @bindable
  public entity: Merlin.Web.Model.RequestSupplierOffer;
  @bindable
  private showRowSelected: boolean = false;
  @bindable()
  private type: any;
  public getGridMenuItems = () => [];
  public canEditCell() { return false; };
  public selectedLinesGrid: PriceOfferLinesGrid;
  @bindable({ defaultBindingMode: bindingMode.fromView })
  selectedCount: number;
  priceOfferLinesGrid: PriceOfferLinesGrid;

  private agGridViewModuleView = "add-request-supplier-offer-step-1";

  public columnVisible: Array<ColumnVisible> =
    [
      { colId: "menuIems", visibility: true },
      { colId: "selected", visibility: true },
      { colId: "merlinRef", visibility: true },
      { colId: "merlinRefMetering", visibility: true },
      { colId: "lineDescription", visibility: true },
      { colId: "lineRef", visibility: true },
      { colId: "originalMeasurementXlsLineNumber", visibility: true },
      { colId: "sheetIndex", visibility: true },
      { colId: "importFileName", visibility: true },
      { colId: "quantityTypeId", visibility: true },
      { colId: "unitId", visibility: true },
      { colId: "proposedQuantity", visibility: true },
      { colId: "proposedQuantityFormula", visibility: true },
      { colId: "originalProposedQuantity", visibility: false },
      { colId: "priceOfferLineFileCount", visibility: true },
      { colId: "additionalDescriptionMultiline", visibility: true },
      { colId: "requestedQuantity", visibility: true },
      { colId: "dataLineDeliveries", visibility: true },
      { colId: "dataLineActivities", visibility: true },
      { colId: "buyingUnitPrice", visibility: true },
      { colId: "sellingUnitPriceWithIndirectCostFromPAR", visibility: true },
      { colId: "diffIndirectCostFromPAR", visibility: true },
      { colId: "definedSellingUnitPrice", visibility: true },
      { colId: "definedDirectMarginPrice", visibility: false },
      { colId: "indirectCostFromPAR", visibility: false },
      { colId: "totalBuyingPrice", visibility: true },
      { colId: "totalSellingPrice", visibility: true },
      { colId: "tags", visibility: true },
      { colId: "supplierId", visibility: false },
      { colId: "supplierPrice", visibility: false },
      { colId: "supplierQuantity", visibility: false },
      { colId: "supplierQuantityTypeId", visibility: false },
      { colId: "supplierTotal", visibility: false },
      { colId: "supplierComment", visibility: false },
      { colId: "acceptedPriceOfferBuyingUnitPrice", visibility: false },
      { colId: "clientAdditionalWorkStatusId", visibility: false },
      { colId: "clientAdditionalWorkNum", visibility: false },
      { colId: "contractNum", visibility: false },
      { colId: "buildingCostsId", visibility: true },
    ];

  constructor(private service: RequestSupplierOfferServiceStep1, private service2: RequestSupplierOfferService2Step1, private httpClient: HttpClient, private taskQueue: TaskQueue, private bindingEngine: BindingEngine) {
    this.service.subscribe(() => this.fetchCount());
  }

  entityChanged() {
    switch (this.entity.entityType.shortName) {
      case 'WorkQuotationVersionRequestSupplierOffer':
        this.type = DocumentTypeDiscriminator.WorkQuotationVersion;
        break;
      case 'BuyingRequestSupplierOffer':
        this.type = DocumentTypeDiscriminator.Buying;
        break;
    }

    if (this.type != DocumentTypeDiscriminator.Buying) {
      this.columnVisible.find(x => x.colId == "acceptedPriceOfferBuyingUnitPrice").visibility = true;
    }
    else {
      this.columnVisible.find(x => x.colId == "supplierId").visibility = true;
      this.columnVisible.find(x => x.colId == "clientAdditionalWorkStatusId").visibility = true;
      this.columnVisible.find(x => x.colId == "clientAdditionalWorkNum").visibility = true;
      this.columnVisible.find(x => x.colId == "contractNum").visibility = true;
    }
    this.service.entity = this.entity;
    this.service2.entity = this.entity;
    this.service.type = this.type;
    this.service2.type = this.type;
    this.fetchCount();
  }

  showRowSelectedChanged() {
    if (this.showRowSelected === true) {
      this.selectedLinesGrid.listTreeData.quickFilterChanged(null, null);
    }
  }

  async fetchCount() {
    let requestUri = Constants.Application.RequestSupplierController.GetSelectedLinesCount.format(this.type.toString(), this.entity.id.toString());
    let response = await this.httpClient.get(requestUri);
    if (response.ok) {
      const result = await response.json();
      this.selectedCount = result;
    }

  }

  attached() {
    if (this.priceOfferLinesGrid == null) {
      this.bindingEngine.propertyObserver(this, 'priceOfferLinesGrid').subscribe((newValue, oldValue) => {
        if (newValue != oldValue && newValue != null) this.observablePriceOfferLinesGrid();
      })
    } else {
      this.observablePriceOfferLinesGrid();
    }
  }

  observablePriceOfferLinesGrid() {
    if (this.priceOfferLinesGrid.listTreeData != null) {
      this.observableListTreeData()
    }
    this.bindingEngine.propertyObserver(this.priceOfferLinesGrid, "listTreeData").subscribe((newValue, oldValue) => {
      if (newValue != oldValue && this.priceOfferLinesGrid.listTreeData != null) {
        this.observableListTreeData()
      }
    });

  }

  observableListTreeData() {
    this.bindingEngine.propertyObserver(this.priceOfferLinesGrid.listTreeData, "dataLines").subscribe((newValue, oldValue) => {
      if (newValue != oldValue) {
        this.service.dataLines = this.priceOfferLinesGrid.listTreeData.dataLines;
      }
    });
    this.bindingEngine.propertyObserver(this.priceOfferLinesGrid.listTreeData, 'flattenList').subscribe((newVal, oldVal) => {
      if (newVal) {
        setTimeout(() => {
          this.priceOfferLinesGrid.listTreeData.gridOptions.api.forEachNode(node => {
            node.setSelected(node.data.isSelected)
          })
        }, 100);
      }
    })
  }
}

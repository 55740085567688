import { EditingModeEnum, ServiceBase, FileFlow, EnumerationTypeService, IMenuGroup, Role, EntityTypeNames, CompressorOptions, UserDetailBase } from 'digiwall-lib';
import { Merlin } from "../generated";
import { autoinject, computedFrom } from 'aurelia-framework';
import * as Constants from '../constants';
import { FluentRuleCustomizer } from 'aurelia-validation';
import { DataFormat } from 'select2';
import { UserRole } from 'digiwall-lib';

@autoinject
export class UserDetail extends UserDetailBase<Merlin.Web.Model.MerlinUser> {
  private roleValid: boolean = true;

  constructor() {
    super('multiple', Constants.EntityTypeNames.MerlinUser);
    this.languageService = new EnumerationTypeService(Constants.EnumerationTypes.Language);
    this.userFunctionService = new EnumerationTypeService(Constants.EnumerationTypes.UserFunctions);
  }
  private languageService: EnumerationTypeService;
  private merlinMenuItems: Array<IMenuGroup>;
  private emailDisabled: boolean = false;

  protected userRoleService: ServiceBase<UserRole>;
  private singleRoleSelection: DataFormat;
  protected roleService: ServiceBase<Role>;
  private originalUserRole: UserRole;
  private userFunctionService: EnumerationTypeService;

  attached() {
    this.roleService = new ServiceBase<Role>(EntityTypeNames.Role);
    this.roleService.gridDataSource.expands = ['permissions'];
    this.userRoleService = new ServiceBase<UserRole>(EntityTypeNames.UserRole);
    this.originalUserRole = null;
  }

  public async activate(params) {

    let id = params.param1;
    await super.activate(params);
    if (this.isCreationMode) {
      this.entity.favoriteRefToDisplay = Constants.RefToDisplay.MerlinRef;
    }
    if (this.entity.emailProvider == null || this.entity.emailProvider.length == 0) {
      this.entity.emailProvider = "None";
    }

    this.setMenuItems();
  }

  activateExpands() {
    return ['language', 'userFunctions.userFunctionEnum'];
  }

  protected getValidationRules(): FluentRuleCustomizer<unknown, any> {
    let rules = super.getValidationRules();
    rules.ensure('firstName').required();
    rules.ensure('lastName').required();
    return rules;
  }

  private setMenuItems() {
    this.merlinMenuItems = [
      {
        group: "1",
        hiddenLabel: true,
        items: [
          {
            label: this.i18n.tr("menu.delete"),
            icon: "digi-trash",
            handler: () => {
              this.delete()
            }
          }
        ]
      },
      {
        group: "2",
        hiddenLabel: true,
        items: [
          {
            label: this.i18n.tr("common:user.resetPassword"),
            icon: "digi-key-2-line",
            handler: () => {
              this.resetPassword();
            }
          }
        ]
      }
    ];
  }

  @computedFrom('editingMode', 'entity.firstName', 'entity.lastName')
  public get documentTitle() {
    if (this.editingMode === EditingModeEnum.Create) {
      return this.i18n.tr("common:user.addUser");
    } else {
      return this.entity.firstName + " " + this.entity.lastName;
    }
  }

  public compressorOptions: CompressorOptions = {
    strict: true,
    quality: 1,
    maxWidth: 100,
    maxHeight: 100,
    minWidth: 15,
    minHeight: 15,
  };

  @computedFrom("entity.picture")
  public get currentLogo(): string {
    return this.entity.avatarUri;
  }

  public async pictureUploaded(fileUploaded: FileFlow) {
    this.entity.picture = fileUploaded.url;
    await this.save();
  }

  public async deletePicture() {
    this.entity.picture = null;
    await this.save();
  }

  public async signatureUploaded(fileUploaded: FileFlow) {
    this.entity.signature = fileUploaded.url;
    await this.save();
  }

  public async deleteSignature() {
    this.entity.signature = null;
    await this.save();
  }

  private onSingleRoleSelectReady() {
    if (this.entity?.roles?.length > 0) {
      this.originalUserRole = this.entity?.roles[0];
      this.singleRoleSelection = {
        id: this.originalUserRole.roleId,
        text: this.originalUserRole.role.name._translation
      }
    }
  }

  private async onSingleRoleSelected() {
    const selectedId = this.singleRoleSelection?.id || this.singleRoleSelection;
    // Do nothing on init (re-selecting existing role)
    if (this.entity?.roles?.find(x => x.roleId == selectedId)) {
      return;
    }

    // Delete and re-create as userId-roleId is PK (update of either field possible)
    if (this.entity?.roles?.length > 0) {
      this.entity.roles.forEach(x => x.entityAspect.entityState.isAdded() ? x.entityAspect.setDetached() : x.entityAspect.setDeleted());
    }
    // If going back to original value, recover previous entity
    if (this.originalUserRole != null && selectedId == this.originalUserRole?.roleId) {
      this.originalUserRole.entityAspect.setUnchanged();
      this.entity.roles.push(this.originalUserRole);
    } else {
      let newRole = await this.userRoleService.createEntity({ userId: this.entity.id, roleId: selectedId as number });
      this.entity.roles.push(newRole);
    }
  }
  async save() {
    this.roleValid = true;
    if (this.entity.roles.length == 0) {
      this.roleValid = false;

    }
    await super.save()
  }
}

import { autoinject, bindable, BindingEngine } from "aurelia-framework";
import { SummaryLineCoefficient } from "services/price-offer-version-api-model";
import { Subscription } from "aurelia-event-aggregator";
import { PriceOfferVersionApiService } from "services/price-offer-version-api-service";

@autoinject
export class SummaryLine {

  @bindable
  public nameToTranslated: string = null;

  @bindable
  public name: string = null;

  @bindable
  public line: SummaryLineCoefficient;

  @bindable
  public priceOfferVersionId: number;
  @bindable
  public refresh: () => void;

  private disposables: Array<Subscription> = [];

  constructor(private bindingEngine: BindingEngine, private priceOfferVersionService: PriceOfferVersionApiService) {

  }

  bind() {
    this.createObservable();
  }

  private createObservable() {
    this.disposables.push(
      this.bindingEngine.propertyObserver(this.line, 'overheadMarginCoefficient')
        .subscribe(async (newValue, oldValue) => {
          if (newValue != oldValue) {
            this.priceOfferVersionService.updateSummaryLine(this.priceOfferVersionId, this.line);
            await this.priceOfferVersionService.updateCoefficient(this.priceOfferVersionId, 'OverheadMarginCoefficient', newValue, this.line.ids);
            this.refresh();
          }
        }),
      this.bindingEngine.propertyObserver(this.line, 'benefitMarginCoefficient')
        .subscribe(async (newValue, oldValue) => {
          if (newValue != oldValue) {
            this.priceOfferVersionService.updateSummaryLine(this.priceOfferVersionId, this.line);
            await this.priceOfferVersionService.updateCoefficient(this.priceOfferVersionId, 'BenefitMarginCoefficient', newValue, this.line.ids);
            this.refresh();
          }
        }),
      this.bindingEngine.propertyObserver(this.line, 'riskMarginCoefficient')
        .subscribe(async (newValue, oldValue) => {
          if (newValue != oldValue) {
            this.priceOfferVersionService.updateSummaryLine(this.priceOfferVersionId, this.line);
            await this.priceOfferVersionService.updateCoefficient(this.priceOfferVersionId, 'RiskMarginCoefficient', newValue, this.line.ids);
            this.refresh();
          }
        })
    );
  }

  private disposeObservable() {
    this.disposables.forEach(d => d.dispose());
  }

  lineChanged(newValue, oldValue) {
    if (newValue != oldValue) {
      this.disposeObservable();
      this.createObservable();
    }

  }

  public unbind() {
    this.disposeObservable();
  }
}

import { RefToDisplay } from './../constants';
import { HttpClient } from 'aurelia-fetch-client';
import { DocumentTypeDiscriminator } from "request-supplier-offers/request-supplier-offer-detail";
import * as Constants from '../constants';
import { GlobalLoaderService } from 'digiwall-lib';
import { autoinject } from 'aurelia-framework';

@autoinject
export class PriceRepartitionService {

  type: DocumentTypeDiscriminator;

  constructor(protected httpClient: HttpClient, private globalLoaderService: GlobalLoaderService) { }

  async hasChildren(id: number, priceOfferLineId: string): Promise<boolean> {
    let requestUri = Constants.Application.PriceRepartitionController.HasChildren.format(this.type.toString(), priceOfferLineId.toString());
    let response: Response = await this.httpClient.get(requestUri);
    if (response.ok) {
      return await response.json();
    }
  }

  async canAddPriceRepartition(id: number, priceOfferLineId: string): Promise<boolean> {
    let requestUri = Constants.Application.PriceRepartitionController.CanAddPriceRepartition.format(this.type.toString(), priceOfferLineId.toString());
    let response: Response = await this.httpClient.get(requestUri);
    if (response.ok) {
      return await response.json();
    }
  }

  async createPriceRepartitions(priceOfferLineId: number, bodyList: Repartition[]): Promise<Response> {
    let requestUri = Constants.Application.PriceRepartitionController.CreatePriceRepartitions.format(this.type.toString(), priceOfferLineId.toString());
    let response: Response = await this.httpClient.post(requestUri, JSON.stringify(bodyList));
    return response;
  }

  async getPriceRepartition(priceOfferLineId: number): Promise<PriceOfferLineRepartition> {
    let requestUri = Constants.Application.PriceRepartitionController.GetPriceRepartition.format(this.type.toString(), priceOfferLineId.toString());
    let response = await this.httpClient.get(requestUri);
    if (response.ok) {
      return await response.json();
    }
    return null;
  }

  async getLinesForRepartition(workQuotationVersionId: number, refToDisplay: RefToDisplay): Promise<DataLineDTO[]> {
    let requestUri = Constants.Application.PriceRepartitionController.GetLinesForRepartition.format(this.type.toString(), workQuotationVersionId.toString(), refToDisplay.toString());
    let response = await this.httpClient.get(requestUri);
    if (response.ok) {
      return await response.json();
    }
    return null;
  }

  async getPercentageRepartition(ids: number[]): Promise<any> {
    let requestUri = Constants.Application.PriceRepartitionController.GetPercentageRepartition.format(this.type.toString());
    let response = await this.httpClient.post(requestUri, JSON.stringify(ids));
    if (response.ok) {
      return await response.json();
    }
    return null;
  }


  public computePAR(versionId: number): Promise<boolean> {
    return new Promise<boolean>(async (res, rej) => {
      this.globalLoaderService.allow("pricerepartition.computing", 0);
      let requestUri = Constants.Application.PriceRepartitionController.ComputePAR.format(this.type.toString(), versionId.toString());
      let query = await this.httpClient.get(requestUri);
      res(query.ok);
    });
  }

  public deletePAR(versionId: number, lineId: number): Promise<boolean> {
    return new Promise<boolean>(async (res, rej) => {
      this.globalLoaderService.allow("pricerepartition.computing", 0);
      let requestUri = Constants.Application.PriceRepartitionController.DeletePriceOfferRepartition.format(this.type.toString(), lineId.toString());
      let result = await this.httpClient.delete(requestUri);
      res(result.ok);
    });
  }
}

export interface PriceOfferLineRepartition {
  id: number;
  description: string;
  totalBuyingPrice: number | null;
  workQuotationVersionId: number;

  repartitions: Repartition[];
}

export interface Repartition {
  dataLine: DataLineDTO
  percentage: number;
  repartitionOnError: boolean;
}


export interface DataLineDTO {
  id: number;
  fullName: string;
  parentId: number | null;
}

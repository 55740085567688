@import "../../../css/scss/variables";

dw-quill {
  margin-top: 0.5em;
  display: block;
  min-height: 300px;
  font-family: Arial;

  .ql-font-inter {
    font-family: "Inter", sans-serif;
  }
  .ql-font-arial {
    font-family: Arial, Helvetica, sans-serif;
  }
  .ql-font-serif {
    font-family:
      Georgia,
      Times New Roman,
      serif;
  }
  .ql-font-monospace {
    font-family:
      Monaco,
      Courier New,
      monospace;
  }
  .ql-font-sans-serif {
    font-family: sans-serif;
  }

  .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="small"]::before {
    font-size: 8pt !important;
  }

  .ql-snow .ql-picker.ql-size .ql-picker-item::before {
    font-size: 10pt !important;
  }

  .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="large"]::before {
    font-size: 12pt !important;
  }

  .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="huge"]::before {
    font-size: 14pt !important;
  }

  .ql-snow .ql-picker.ql-font .ql-picker-label::before,
  .ql-snow .ql-picker.ql-font .ql-picker-item::before {
    content: attr(data-value) !important;
  }

  .ql-editor .ql-size-small {
    font-size: 8pt;
  }
  .ql-editor .ql-size-large {
    font-size: 14pt;
  }
  .ql-editor .ql-size-huge {
    font-size: 18pt;
  }
  .ql-editor a {
    color: blue;
  }

  .actions {
    padding: 0.2em 0;

    a {
      margin-right: 0.3em;
      padding: 0.2em 0;
      color: $gray-light;
      user-select: none;

      &.active {
        color: $blue-mid;
      }
    }
  }

  .ql-editor {
    min-height: 200px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 10pt;
  }

  .ql-toolbar {
    border-radius: 5px 5px 0 0;
  }

  .ql-container {
    border-radius: 0 0 5px 5px;
  }
}

// MODULE MENTIONS
.ql-mention-list-container {
  min-width: 270px;
  border: 1px solid #f0f0f0;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 4px 20px 0 rgba(30, 30, 30, 0.08);
  z-index: 9001;
  overflow: auto;
}

.ql-mention-loading {
  line-height: 44px;
  padding: 0 20px;
  vertical-align: middle;
  font-size: 16px;
}

.ql-mention-list {
  list-style: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.ql-mention-list-item {
  cursor: pointer;
  font-size: 12px;
  padding: 10px 20px;
  display: flex;

  .placeholder-name {
    font-size: 14px;
    font-weight: normal;
  }
  .placeholder-key {
    margin-left: 40px;
    color: $gray-mid;
    flex: 1;
    text-align: right;
    font-weight: normal;
  }
}

.ql-mention-list-item.disabled {
  cursor: auto;
}

.ql-mention-list-item.selected {
  background-color: $purple-lighter;
  text-decoration: none;
}

.mention {
  height: 24px;
  width: 65px;
  border-radius: 6px;
  background-color: $purple-lighter;
  padding: 3px 0;
  margin-right: 2px;
  user-select: all;
}

.mention > span {
  margin: 0 3px;
}
// EO. MODULE MENTIONS

dw-quill-ml.quill-multilangue {
  .footer-picture {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  ui-button {
    position: absolute;
    bottom: 20px;
    right: 20px;
    z-index: 1;
  }
}

import { I18N } from 'aurelia-i18n';
import { EventAggregator } from 'aurelia-event-aggregator';
import { DataFormat } from 'select2';
import { HttpClient } from 'aurelia-fetch-client';
import { Router } from 'aurelia-router';
import { CustomLogger, EntityDetailViewModelBase, ServiceBase, Various, GlobalLoaderService, UIInternal } from 'digiwall-lib';
import { autoinject, observable, PassThroughSlot } from "aurelia-framework";
import { Merlin } from 'generated';
import * as Constants from '../constants';
import { DialogController, DialogService } from 'aurelia-dialog';
import { isThisSecond } from 'date-fns';
import { FilterQueryOp, Predicate } from 'breeze-client';
import * as toastr from 'toastr';



@autoinject
export class AddWorkQuotationVersion {

  private workQuotationVersionService: ServiceBase<Merlin.Web.Model.WorkQuotationVersion>;
  private workQuotationVersionSelected: DataFormat;
  private workQuotationVersionSelectedText: string;
  private projectId: number;
  private workQuotationVersionName: string;
  private isCurrentVersion: boolean = true;
  @observable
  private copyExistingVersion: boolean = true;
  @observable
  private copyRequestSupplier: boolean = false;
  @observable
  private copySupplierOffer: boolean = false;

  constructor(router: Router, logger: CustomLogger, private dialogController: DialogController, private httpClient: HttpClient, private globalLoaderService: GlobalLoaderService, private i18n: I18N) {
    this.workQuotationVersionService = new ServiceBase<Merlin.Web.Model.WorkQuotationVersion>(Constants.EntityTypeNames.WorkQuotationVersion);
  }

  documentTitle = "";
  ressourceName = "";

  async activate(params: { projectId: number }) {
    if (params.projectId != null) {
      this.projectId = params.projectId;

      let projectPredicate = new Predicate("projectId", FilterQueryOp.Equals, this.projectId)
      this.workQuotationVersionService.gridDataSource.customSelect2Predicates = () => {
        return projectPredicate;
      }
      let workQuotationVersion = (await this.workQuotationVersionService.firstEntity(projectPredicate.and("isCurrentVersion", FilterQueryOp.Equals, true)));

      this.workQuotationVersionSelected = { id: workQuotationVersion.id, text: workQuotationVersion.fullName };
    }
    else {
      return false;
    }

  }

  async close() {
    await this.dialogController.close(false);
  }

  async save() {
    if (this.workQuotationVersionSelected.id != null) {
      this.globalLoaderService.allow();
      let newVersion: NewVersion = { versionName: this.workQuotationVersionName, copyExistingVersion: this.copyExistingVersion, isCurrentVersion: this.isCurrentVersion, copyRequestSupplier: this.copyRequestSupplier, copySupplierOffer: this.copySupplierOffer };
      let response = await this.httpClient.post(Constants.Application.WorkQuotationController.NewVersion.format(this.workQuotationVersionSelected.id.toString()), JSON.stringify(newVersion));
      if (response.ok) {
        const newVersionId = await response.json();
        const newVersion = await this.workQuotationVersionService.getEntityById(newVersionId);
        this.closeAndNavigateToNewVersion(newVersion);
      }
      else {
        toastr.error(this.i18n.tr("workquotationversion.errorCopyingVersion"))
      }
    } else {
      this.closeAndNavigateToNewVersion();
    }
  }

  private async closeAndNavigateToNewVersion(newVersion = null) {
    if (newVersion != null) {
      if (newVersion.isCurrentVersion) {
        UIInternal.broadcast(Constants.MerlinCustomEvent.CurrentVersionChange, { id: newVersion.id, text: newVersion.fullName, isCurrentVersion: newVersion.isCurrentVersion });
      }

      this.dialogController.close(true, newVersion);
    }
    else {
      this.dialogController.close(false);
    }
  }

  copyExistingVersionChanged(newVal, oldVal) {
    if (newVal == false) {
      this.copyRequestSupplier = false;
      this.copySupplierOffer = false;
    }
  }
  copyRequestSupplierChanged(newVal, oldVal) {
    if (newVal == false) {
      this.copySupplierOffer = false;
    }
  }
  copySupplierOfferChanged(newVal, oldVal) {
    if (newVal == true) {
      this.copyRequestSupplier = true;
    }
  }
}

class NewVersion {
  versionName: string;
  copyExistingVersion: boolean;
  isCurrentVersion: boolean;
  copyRequestSupplier: boolean;
  copySupplierOffer: boolean;
}

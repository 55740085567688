import { CustomLogger, FieldType, GridHelper, ListViewModelBase, ServiceBase } from 'digiwall-lib';
import { Router } from 'aurelia-router';
import { autoinject } from 'aurelia-framework';
import { ColDef } from "ag-grid-community";
import * as Constants from '../constants';
import { Merlin } from 'generated';
import { GridHelperMerlin } from 'resources/elements/grid-helper';

@autoinject
export class BlackListedDomainMatchingList extends ListViewModelBase<Merlin.Web.Model.BlackListedDomainMatching> {
  public ressourceName: string = Constants.EntityTypeNames.BlackListedDomainMatching;

  constructor(router: Router, logger: CustomLogger) {
    super(router, logger, new ServiceBase<Merlin.Web.Model.BlackListedDomainMatching>(Constants.EntityTypeNames.BlackListedDomainMatching));
  }

  public getDetailsUrl(self: any, entity: Merlin.Web.Model.BlackListedDomainMatching): string {
    if (entity && entity.id) {
      return this.router.baseUrl + 'black-listed-domain-matchings/' + entity.id;
    }
    return null;
  }

  public getDataGridColumns() {
    super.getDataGridColumns();
    let defs: ColDef[] = [
      ...new GridHelperMerlin().contextMenuColdDef(this),
      {
        headerName: this.i18n.tr("blacklisteddomainmatching.name"),
        field: "name",
        type: FieldType.String
      },
      ...new GridHelperMerlin().baseEntityColDef(this)
    ];
    return defs;
  }
} 

import { I18N } from 'aurelia-i18n';
import { computedFrom } from 'aurelia-binding';
import { RouterConfiguration, Router } from 'aurelia-router';
import { ServiceBase } from 'digiwall-lib';
import * as Constants from '../constants';
import { Merlin } from 'generated';
import { autoinject } from 'aurelia-framework';
import { activationStrategy } from 'aurelia-router';

@autoinject
export class ProjectIndex {
  router: Router;
  entityId: number = null;
  priceOfferId: number;
  projectService: ServiceBase<Merlin.Web.Model.Project>;
  refreshMenu = true;
  projectStarted: boolean = false;

  constructor(private i18n: I18N) {
    this.projectService = new ServiceBase<Merlin.Web.Model.Project>(Constants.EntityTypeNames.Project);
  }

  async activate(params) {
    this.entityId = params.projectId;

    if (this.entityId > 0) {
      let ent = await this.projectService.getEntityById(this.entityId);
      this.projectStarted = ent.projectStarted;
      this._ribbonHeaderText = ent.projectName + ' (' + this.entityId + ')';
    } else {
      this._ribbonHeaderText = this.i18n.tr('project.newProject');
    }

    this.router.routes.forEach(r => {
      r.disabled = this.entityId < 0;
      r.params = {
        projectId: this.entityId
      }

      if (r.settings?.hideIfProjectNotStarted == true) {
        r.disabled = !this.projectStarted;
      }
    });
  }

  private _ribbonHeaderText: string;

  @computedFrom("_ribbonHeaderText")
  public get ribbonHeaderText(): string {
    return this._ribbonHeaderText;
  }

  configureRouter(config: RouterConfiguration, router: Router, params, routeConfig, currentInstruction): void {
    this.router = router;

    config.map([
      {
        route: "",
        moduleId: "projects/project-resume/project-resume",
        name: "home",
        title: this.i18n.tr('projectindex.home'),
        nav: true
      },
      {
        route: ["quote"],
        moduleId: "projects/quotes/version-index",
        name: "quote",
        title: this.i18n.tr('projectindex.quote'),
        nav: true
      },
      {
        route: ["preparation"],
        moduleId: "projects/preparation/preparation-index",
        name: "preparation",
        title: this.i18n.tr('projectindex.preparation'),
        nav: true,
        settings: {
          hideIfProjectNotStarted: true
        }
      },
      {
        route: ["buying"],
        moduleId: "projects/buying/buying-index",
        name: "buying",
        title: this.i18n.tr('projectindex.buying'),
        nav: true,
        settings: {
          hideIfProjectNotStarted: true
        }
      },
      {
        route: ["realization"],
        moduleId: "projects/realization/realization-index",
        name: "realization",
        title: this.i18n.tr('projectindex.realization'),
        nav: true,
        settings: {
          hideIfProjectNotStarted: true
        }
      },
      {
        route: ["module-tasks"],
        moduleId: "module-tasks/module-task",
        name: "module-tasks",
        title: this.i18n.tr('projectindex.task'),
        nav: true,
        activationStrategy: activationStrategy.replace
      },
      {
        route: ["communication"],
        moduleId: "projects/communication/communication-index",
        name: "communication",
        title: this.i18n.tr('menu.communications'),
        nav: true
      },
      {
        route: ["invoicing"],
        moduleId: "projects/invoices/invoice-index",
        name: "invoices",
        title: this.i18n.tr('projectindex.invoicing'),
        nav: true
      },
      {
        route: ["tasks"],
        moduleId: "module-tasks/module-task",
        name: "task",
        title: this.i18n.tr('projectindex.task'),
        nav: false,
        activationStrategy: activationStrategy.replace
      },
      {
        route: ["project-detail"],
        moduleId: "projects/project-detail",
        name: "project-detail",
        title: this.i18n.tr("menu.edit"),
        nav: false
      }
    ]);
  }
}

import { TreeDisplayMode, RefToDisplay } from './../constants';
import { Predicate, FilterQueryOp } from 'breeze-client';
import { HttpClient } from 'aurelia-fetch-client';
import { DialogController } from 'aurelia-dialog';
import { CustomLogger, ServiceBase, EntityDetailViewModelBase, ActionDialogBoxInputParameters, DialogBoxViewModel, IFile, GlobalLoaderService, StepElement, EditingModeEnum } from 'digiwall-lib';
import { Router } from 'aurelia-router';
import { autoinject, observable, Container } from 'aurelia-framework';
import { Merlin } from "../generated";
import * as Constants from '../constants';
import { ValidationRules } from 'aurelia-validation';
import * as toastr from 'toastr';
import { NotificationLanguageDTO } from 'email-sender/email-sender';
import { DocumentTypeDiscriminator } from './request-supplier-offer-detail';
import moment = require('moment');
import { RequestSupplierApiService, RequestSupplierOfferServiceStep1 } from 'services/request-supplier-api-service';

@autoinject
export class AddRequestSupplierOffer extends EntityDetailViewModelBase<Merlin.Web.Model.RequestSupplierOffer> {
  workQuotationVersionId: number;
  projectId: number;
  requestSupplierOfferId: number;
  documentTitle = "";
  private notification: Map<number, NotificationLanguageDTO> = new Map<number, NotificationLanguageDTO>();
  private notificationService: ServiceBase<Merlin.Web.Model.NotificationSent>;
  private notificationFileService: ServiceBase<Merlin.Web.Model.NotificationSentFile>;
  private receiverIds: Array<number> = []
  private companyIds: Array<number> = []
  public stepElements: Array<StepElement> = [
    {
      id: "selection",
      step: 1,
      label: this.i18n.tr("requestsupplieroffer.step1"),
      disabledClick: () => { return this.requestSupplierOfferId != null; }
    },
    {
      id: "supplier",
      step: 2,
      label: this.i18n.tr("requestsupplieroffer.step2"),
      disabledClick: () => { return false; }
    },
    {
      id: "contactPerson",
      step: 3,
      label: this.i18n.tr("requestsupplieroffer.step3"),
      disabledClick: () => { return false; }
    },
    {
      id: "parameters",
      step: 4,
      label: this.i18n.tr("requestsupplieroffer.step4"),
      disabledClick: () => { return this.requestSupplierOfferId != null; }
    },
    {
      id: "message",
      step: 5,
      label: this.i18n.tr("requestsupplieroffer.step5"),
      disabledClick: () => { return false; }
    },
    {
      id: "mail",
      step: 6,
      label: this.i18n.tr("requestsupplieroffer.step6")
    }
  ] as Array<StepElement>;
  public currentElement: StepElement;

  public selectedEntitiesStep2: Array<Merlin.Web.Model.ThirdParty> = [];
  public showAllRowSelected2: boolean = false;
  public showAllRowSelected1: boolean = false;
  @observable
  public selectedLineCount: number = 0;
  public requestToSupplierService: ServiceBase<Merlin.Web.Model.RequestToSupplier>;
  public requestSupplierOfferFileService: ServiceBase<Merlin.Web.Model.RequestSupplierOfferFile>;
  public applicationParameterService: ServiceBase<Merlin.Web.Model.ApplicationParameter>;
  public thirdPartyService: ServiceBase<Merlin.Web.Model.ThirdParty>;
  private globalLoader: GlobalLoaderService;

  private fileToSend: Array<IFile> = [];
  type: DocumentTypeDiscriminator;

  public previewRequestParams: Merlin.Web.Model.EmailTemplatePreviewRequest = {};

  constructor(router: Router, logger: CustomLogger, private dialogController: DialogController, private httpClient: HttpClient, private requestSupplierApiService: RequestSupplierApiService, private requestSupplierOfferService: RequestSupplierOfferServiceStep1) {
    super(router, logger);
    this.requestSupplierOfferFileService = new ServiceBase<Merlin.Web.Model.RequestSupplierOfferFile>(Constants.EntityTypeNames.RequestSupplierOfferFile);
    this.notificationService = new ServiceBase<Merlin.Web.Model.NotificationSent>(Constants.EntityTypeNames.NotificationSent);
    this.notificationFileService = new ServiceBase<Merlin.Web.Model.NotificationSentFile>(Constants.EntityTypeNames.NotificationSentFile);
    this.applicationParameterService = new ServiceBase<Merlin.Web.Model.ApplicationParameter>(Constants.EntityTypeNames.ApplicationParameter);
    this.thirdPartyService = new ServiceBase<Merlin.Web.Model.ThirdParty>(Constants.EntityTypeNames.ThirdParty);
    this.globalLoader = Container.instance.get(GlobalLoaderService);

    ValidationRules.customRule('validation-id', (value, obj) => {
      if (value != null && value.trim() != "") {
        return true;
      }
      return false;
    }, 'requestsupplieroffer.idError');
    ValidationRules.customRule('validation-title', (value, obj) => {
      if (value != null && value.trim() != "") {
        return true;
      }
      return false;
    }, 'requestsupplieroffer.titleError');
    ValidationRules.customRule('validation-mail-to', (value, obj) => {
      if (value != null && value > 0) {
        return true;
      }
      return false;
    }, 'requestsupplieroffer.mailToError');
  }

  public ressourceName: string = Constants.EntityTypeNames.RequestSupplierOffer;

  public async activate(params: any) {
    await super.activate(params);
    if (this.isDialogView) {
      this.workQuotationVersionId = params.param1.workQuotationVersionId;
      this.projectId = params.param1.projectId;
      this.requestSupplierOfferId = params.param1.requestSupplierOfferId;
    } else {
      this.workQuotationVersionId = params.workQuotationVersionId;
      this.projectId = params.projectId;
      this.requestSupplierOfferId = params.requestSupplierOfferId;
    }

    this.requestToSupplierService = new ServiceBase<Merlin.Web.Model.RequestToSupplier>(Constants.EntityTypeNames.RequestToSupplier);
    let predicate: Predicate;
    if (this.workQuotationVersionId != null) {

      this.type = DocumentTypeDiscriminator.WorkQuotationVersion;

      predicate = new Predicate("workQuotationVersionId", FilterQueryOp.Equals, this.workQuotationVersionId);
      super.initialize(new ServiceBase<Merlin.Web.Model.WorkQuotationVersionRequestSupplierOffer>(Constants.EntityTypeNames.WorkQuotationVersionRequestSupplierOffer));
    } else if (this.projectId != null) {
      this.type = DocumentTypeDiscriminator.Buying;

      predicate = new Predicate("projectId", FilterQueryOp.Equals, this.projectId);
      super.initialize(new ServiceBase<Merlin.Web.Model.BuyingRequestSupplierOffer>(Constants.EntityTypeNames.BuyingRequestSupplierOffer));
    }
    if (this.requestSupplierOfferId != null) {
      this.editingMode = EditingModeEnum.Update;
      this.entity = await this.service.getEntityById(this.requestSupplierOfferId);
      this.entity.step = 2
    }
    else {
      this.editingMode = EditingModeEnum.Create;
      predicate = predicate.and(new Predicate("createdById", FilterQueryOp.Equals, this.authService.currentUser.id));
      predicate = predicate.and(new Predicate("requestOfferName", FilterQueryOp.Equals, null).or(new Predicate("requestOfferName", FilterQueryOp.Equals, "")));
      predicate = predicate.and(new Predicate("step", FilterQueryOp.NotEquals, 4));
      predicate = predicate.and(new Predicate("step", FilterQueryOp.NotEquals, 5));
      predicate = predicate.and(new Predicate("step", FilterQueryOp.NotEquals, 6));
      let createEntity: boolean = true;

      const list = await this.service.getEntities(predicate, null, null, { propertyPaths: 'createdTime', isDescending: true }, { take: 1 });
      if (list?.length > 0) {
        this.entity = list[0];
        let buttonYes: ActionDialogBoxInputParameters =
        {
          label: this.i18n.tr("general.yes", { ns: "common" }),
          title: this.i18n.tr("general.yes", { ns: "common" }),
          theme: 'primary',
          type: 'solid',
          disabled: false,
          fn: (thisBox: DialogBoxViewModel) => {
            thisBox.controller.ok(true);
          }
        };
        let buttonNo: ActionDialogBoxInputParameters =
        {
          label: this.i18n.tr("general.no", { ns: "common" }),
          title: this.i18n.tr("general.no", { ns: "common" }),
          theme: 'dark',
          type: 'ghost',
          disabled: false,
          fn: (thisBox: DialogBoxViewModel) => {
            thisBox.controller.ok(false);
          }
        };
        await this.box.showQuestion(this.i18n.tr('requestsupplieroffer.questionNewOffer'), this.i18n.tr('menu.question'), [buttonNo, buttonYes]).whenClosed(
          async (result) => {
            if (!result.wasCancelled) {
              if (result.output == true) {
                await this.service.save();
                createEntity = false;
              } else {
                this.globalLoader.allow();
                await this.httpClient.delete(Constants.Application.RequestSupplierController.Delete.format(this.type.toString(), this.entity.id.toString()));
              }
            }
          }
        )
      }
      if (createEntity) {
        this.entity = await this.service.createEntity();
        let applicationParameter = await this.applicationParameterService.firstEntity();
        if (applicationParameter.defaultNbDayOfferSendingDeadlineDate != null) {
          this.entity.offerSendingDeadlineDate = moment().add(applicationParameter.defaultNbDayOfferSendingDeadlineDate, 'day').toDate();
        }
        if (applicationParameter.defaultNbDayRequestReminderPlannedDate != null) {
          this.entity.requestReminderPlannedDate = moment().add(applicationParameter.defaultNbDayRequestReminderPlannedDate, 'day').toDate();
        }

        if (this.workQuotationVersionId != null) {
          (this.entity as Merlin.Web.Model.WorkQuotationVersionRequestSupplierOffer).workQuotationVersionId = this.workQuotationVersionId;
          this.entity.requestOfferTypeId = Constants.SupplierOfferTypeId.ForPriceOffer;
        } else if (this.projectId != null) {
          (this.entity as Merlin.Web.Model.BuyingRequestSupplierOffer).projectId = this.projectId;
          this.entity.requestOfferTypeId = Constants.SupplierOfferTypeId.ForWorkingSite;
        }
        this.entity.step = 0;
        await this.saveCurrentEntity();
        this.entity = await this.service.getEntityById(this.entity.id);
      }
    }

    let step = this.entity.step - 1;
    if (step < 0) {
      step = 0;
    }
    this.stepElements[step].isActif = true;
    this.currentElement = this.stepElements[step];
    this.stepElements.filter(x => x.step < (step + 1)).forEach(x => x.isValidStep = true);
    if ((this.entity.step == 2 || this.entity.step == 3) && this.editingMode == EditingModeEnum.Create) {
      let predicate: Predicate = new Predicate("requestSupplierOfferId", FilterQueryOp.Equals, this.entity.id);

      let requestToSupplier = (await this.requestToSupplierService.getEntities(predicate, ['toSupplierOrSubContractor']));
      this.selectedEntitiesStep2 = requestToSupplier.map(x => x.toSupplierOrSubContractor);
    }


  }

  public detached(): void {
    ValidationRules.off(this.entity);
    super.detached()
  }

  async step4() {
    let result = (await this.controller.validate({
      object: this.entity,
      propertyName: 'requestOfferName',
      rules: 'validation-title'
    })).results.filter(x => !x.valid);
    if (result.length > 0) {
      result.forEach(invalid => {
        toastr.error(this.i18n.tr(invalid.message));
      });
      return false;
    }
    else {
      this.resetSelectedSupplier();
      await this.nextStep();
      return true;
    }
  }

  private resetSelectedSupplier() {
    this.companyIds.splice(0);
    this.receiverIds.splice(0);

    this.entity.requestToSuppliers?.forEach(supplier => {
      if (supplier.toSupplierContactPersons != null && supplier.toSupplierContactPersons.length > 0) {
        this.receiverIds.push(...supplier.toSupplierContactPersons.map(x => x.toSupplierContactPersonId));
      }
      else {
        this.receiverIds.push(supplier.toSupplierOrSubContractorId);
      }
      this.companyIds.push(supplier.toSupplierOrSubContractorId);
    });
  }

  async step5() {
    let result = await this.httpClient.post(Constants.Application.EmailController.CreateEmail, JSON.stringify({ IdsToSend: this.receiverIds, NotifsLang: Object.fromEntries(this.notification.entries()), PreviewRequestParams: this.previewRequestParams }));
    if (result.ok) {
      let output = await result.json();

      for (const supplier of this.entity.requestToSuppliers) {
        if (supplier.toSupplierContactPersons != null && supplier.toSupplierContactPersons.length > 0) {
          supplier.toSupplierContactPersons.forEach(x => {
            if (supplier.requestMailId == null)
              supplier.requestMailId = output[x.toSupplierContactPersonId]
          })
        }
        else {
          supplier.requestMailId = output[supplier.toSupplierOrSubContractorId];
        }
        let tempContactPerson = supplier.toSupplierContactPersons;
        for (let index = 0; index < tempContactPerson.length; index++) {
          const element = tempContactPerson[index];
          if (element.toSupplierContactPerson.isCompany) {
            let index = supplier.toSupplierContactPersons.findIndex(x => x.id == element.toSupplierContactPersonId);
            if (index != -1) {
              supplier.toSupplierContactPersons.splice(index, 1);
            }
          }
        }
        await this.requestSupplierApiService.setStatus(this.type, supplier.id, Constants.RequestToSupplierStatusId.Sent);
        supplier.statusId = Constants.RequestToSupplierStatusId.Sent;
      }
      await this.nextStep(true);
    }
    else {
      let error = await result.text();
      this.logger.LogError(this.i18n.tr("requestsupplieroffer.ccError", { mail: error }), null, null, true)
    }
  }

  async nextStep(fullSave: boolean = false) {
    if (this.currentElement.step == 1) {
      this.fileToSend.slice(0)

      let response = await this.httpClient.get(Constants.Application.RequestSupplierController.GenerateExcel.format(this.type.toString(), this.entity.id.toString(), "0"));
      if (response.ok) {
        let url = await response.json();
        let urlSplit = url.url.split("/");
        let name = urlSplit[urlSplit.length - 1];
        this.fileToSend.splice(0);
        this.fileToSend.push({
          url: url.url,
          name: name,
          description: null,
          fileTypeId: null,
          size: 0,
        } as IFile);
      }
    }

    let newIndex = this.stepElements.findIndex(x => x == this.currentElement) + 1;
    if (this.requestSupplierOfferId && newIndex == 3) {
      this.stepElements[newIndex].isValidStep = true;
      newIndex++;
      this.resetSelectedSupplier();
      this.entityManager.manager.clear();
    }
    this.currentElement.isValidStep = true;
    let newCurrentElement = this.stepElements[newIndex]
    if (newCurrentElement) {
      this.currentElement = newCurrentElement;
      this.currentElement.isActif = true;
      this.entity.step = newIndex;
      if (fullSave) {
        await this.service.save(true);
      }
      else {
        await this.service.saveEntity(this.entity, true);
      }

      this.previewRequestParams.requestSupplierOfferId = this.entity.id;
      this.previewRequestParams.projectId = this.projectId;
    }
  }

  async close() {
    if (this.isCreationMode && this.entity.step <= 4) {
      let buttonYes: ActionDialogBoxInputParameters =
      {
        label: this.i18n.tr("general.save", { ns: "common" }),
        title: this.i18n.tr("general.save", { ns: "common" }),
        theme: 'primary',
        type: 'solid',
        disabled: false,
        fn: (thisBox: DialogBoxViewModel) => {
          thisBox.controller.ok(true);
        }
      };
      let buttonNo: ActionDialogBoxInputParameters =
      {
        label: this.i18n.tr("general.cancel", { ns: "common" }),
        title: this.i18n.tr("general.cancel", { ns: "common" }),
        theme: 'dark',
        type: 'ghost',
        disabled: false,
        fn: (thisBox: DialogBoxViewModel) => {
          thisBox.controller.ok(false);
        }
      };
      await this.box.showQuestion(this.i18n.tr('requestsupplieroffer.questionSaveOffer'), this.i18n.tr('menu.question'), [buttonNo, buttonYes]).whenClosed(
        async (result) => {
          if (!result.wasCancelled) {
            if (result.output == true) {
              this.entity.step = this.currentElement.step;
              if (this.entity.step == 2) {
                await this.actionBeforeStep2();
              }
              await this.service.save();
            } else {
              this.globalLoader.allow();
              await this.httpClient.delete(Constants.Application.RequestSupplierController.Delete.format(this.type.toString(), this.entity.id.toString()));
            }
          }
        }
      )
    } else {
      await this.service.saveEntity(this.entity);
    }
    await this.dialogController.close(false);
  }

  async addNewRequestSupplierOffer() {
    await this.service.save()
    if (this.isDialogView) {
      await this.dialogController.ok({ newRequestSupplierOffer: true });
    }
    else {
      this.router.navigateToRoute("add-offer", { workQuotationVersionId: this.workQuotationVersionId, projectId: this.projectId }, { replace: true });
    }
  }

  async afterStep1() {
    if (this.type == DocumentTypeDiscriminator.Buying) {
      let result: Array<number>[] = await this.requestSupplierApiService.GetThirdPartyToSelect(this.type, this.entity.id);
      if (result.length > 0) {
        this.selectedEntitiesStep2 = await this.thirdPartyService.getEntities(null, null, { ids: result });
      }
    }
    await this.nextStep();
  }

  async actionBeforeStep2() {
    let ids = this.selectedEntitiesStep2.map(x => x.id);
    let result = await this.httpClient.post(Constants.Application.RequestSupplierController.Create.format(this.type.toString(), this.entity.id.toString(), (this.requestSupplierOfferId == null).toString()), JSON.stringify(ids));
    if (result.ok) {
      let newIds: Array<any> = await result.json();
      if (ids.length == newIds.length) {
        return true;
      }
    } else {
      toastr.error(this.i18n.tr('common:serverExceptions.unattendedError'));
    }
  }

  async step2Next() {
    let goToNextStep = await this.actionBeforeStep2();
    if (goToNextStep) {
      await this.nextStep();
    }
  }

  showRowSelectedStep1() {
    this.showAllRowSelected1 = !this.showAllRowSelected1;
  }

  showRowSelectedStep2() {
    this.showAllRowSelected2 = !this.showAllRowSelected2;
  }

  async goToRequestSupplierOffer() {
    if (this.entity.step == 3 && await this.step4()) {
      this.entity.step = 6;
      await this.service.save(true);
    }
    let params = this.router.currentInstruction.queryParams;
    Object.assign(params, {
      param1: this.entity.id
    })
    if (this.isDialogView) {
      let endUrl = "request-supplier-offer-detail/" + this.entity.id + (this.workQuotationVersionId ? "?workQuotationVersionId=" + this.workQuotationVersionId : "");
      this.dialogController.close(true);
      this.router.navigate("projects/" + this.projectId + "/quote/" + endUrl);
    } else {
      this.router.navigateToRoute("request-supplier-offer-detail", params);
    }
  }
} 

@import "../css/scss/variables";
ui-page.request-supplier-offer-detail.ui-page {
  > .ui-page__ribbon-content {
    //border-bottom: solid 1px $gray-lighter;
    margin: 0 40px;
    padding: 0 !important;
  }
  .request-supplier-offer-detail__request-offer-name {
    > ui-input.ui-input {
      border: none;
      padding: 0;
    }
  }
  ui-button-detail {
    display: block;
    height: 60px;
  }
  > .ui-page__body {
    > ui-content {
      > email-sender-supplier-offer > div > .email-sender-supplier-offer__template-with-language > ui-button {
        margin-top: 10px;
      }

      .request-supplier-offer-detail__request-offer-id {
        > ui-field {
          > label {
            > span {
              color: $black-100;
              font-weight: bold;
            }
          }
          > ui-input {
            border: none !important;
            background-color: $black-0;
            > input {
              color: $black-100 !important;
            }
          }
        }
      }
    }
  }
  custom-ag-grid {
    grid-header-bar.grid-header-bar {
      height: 70px !important;

      .header-title {
        ui-button {
          border: solid 1px #e5e9ff !important;
          border-radius: 50px;
          width: 40px;
          height: 40px;
          margin-left: 15px;
        }
      }
    }
    .ag-row-disabled .ag-cell .ag-cell-wrapper .ag-cell-value {
      opacity: $ag-row-disabled-opacity !important;
    }
  }
}

ui-files.general-conditions {
  thead {
    display: none;
  }
  tbody {
    td:nth-child(1),
    td:nth-child(2) {
      width: 50px;
    }
    td:nth-child(1) {
      display: none;
    }
  }
}

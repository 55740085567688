import { IGetRowsParams } from 'ag-grid-community';
import { StartProject } from './../utils/start-project';
import { HttpClient } from 'aurelia-fetch-client';
import { Merlin } from 'generated';
import { Router } from 'aurelia-router';
import { EntityDetailViewModelBase, CustomLogger, EditingModeEnum, ServiceBase, Various, IAnchorTab, IMenuGroup, EnumerationTypeService, EnumerationType, UIInternal, IMenuItems, GlobalLoaderService } from 'digiwall-lib';
import * as Constants from '../constants';
import { autoinject, BindingEngine, computedFrom, TaskQueue } from 'aurelia-framework';
import { FilterQueryOp, Predicate } from 'breeze-client';
import { CustomThirdPartiesSourceService } from 'services/custom-third-party-source-service';
import { NotificationApiService } from 'services/notification-api-service';
import { NotificationInternalTeamService } from './internal-team/notification-internal-team-service';
import { ProjectApiService } from 'services/project-api-service';

@autoinject
export class ProjectDetail extends EntityDetailViewModelBase<Merlin.Web.Model.Project> {
  public ressourceName: string = Constants.EntityTypeNames.Project;
  private thirdPartiesService: ServiceBase<Merlin.Web.Model.ThirdParty>;
  private thirdPartiesPartnersService: ServiceBase<Merlin.Web.Model.ThirdParty>;
  private thirdPartiesTempoparyCompanyService: ServiceBase<Merlin.Web.Model.ThirdParty>;

  private languageService: EnumerationTypeService;
  private projectStatusService: EnumerationTypeService;
  private projectCancellationReasonService: EnumerationTypeService;
  private projectExternalRoleService: EnumerationTypeService;
  private projectPartnerRoleService: EnumerationTypeService;
  private projectTypeService: EnumerationTypeService;
  private projectClassService: EnumerationTypeService;
  private approvalService: EnumerationTypeService;
  private projectUsersService: ServiceBase<Merlin.Web.Model.ProjectUsers>;
  private projectContactsService: ServiceBase<Merlin.Web.Model.ProjectContact>;
  private projectPartnersService: ServiceBase<Merlin.Web.Model.ProjectPartner>;
  private customProjectInternalThirdPartiesService: CustomThirdPartiesSourceService;
  private addressService: ServiceBase<Merlin.Web.Model.Address>;
  private workQuotationVersionService: ServiceBase<Merlin.Web.Model.WorkQuotationVersion>;
  private applicationParameterService: ServiceBase<Merlin.Web.Model.ApplicationParameter>;
  private applicationParameter: Merlin.Web.Model.ApplicationParameter;

  private loadingPriceOfferVersions = true;
  private readonly ProjectStatusId_Lost = Constants.ProjectStatusId.Lost;
  private readonly ProjectStatusId_Abandon = Constants.ProjectStatusId.Abandon;
  private readonly ProjectStatusId_StandBy = Constants.ProjectStatusId.StandBy;

  public tabList: Array<IAnchorTab>;
  private menuItems: Array<IMenuGroup>;
  private languages: Array<EnumerationType>;
  private canBeStarted: boolean = false;
  private hasMetering: boolean = false;
  private showInternalTeam = false;
  public Constants: any = Constants;
  private priceToSquareMeter: number;

  constructor(router: Router, logger: CustomLogger, private bindingEngine: BindingEngine, private gls: GlobalLoaderService, private httpClient: HttpClient, private taskQueue: TaskQueue, private notificationService: NotificationInternalTeamService, private projectApiService: ProjectApiService) {
    super(router, logger);
    this.thirdPartiesService = new ServiceBase<Merlin.Web.Model.ThirdParty>(Constants.EntityTypeNames.ThirdParty);
    this.thirdPartiesPartnersService = new ServiceBase<Merlin.Web.Model.ThirdParty>(Constants.EntityTypeNames.ThirdParty);
    this.thirdPartiesTempoparyCompanyService = new ServiceBase<Merlin.Web.Model.ThirdParty>(Constants.EntityTypeNames.ThirdParty);
    this.workQuotationVersionService = new ServiceBase<Merlin.Web.Model.WorkQuotationVersion>(Constants.EntityTypeNames.WorkQuotationVersion);
    this.languageService = new EnumerationTypeService(Constants.EnumerationTypes.Language);
    this.projectStatusService = new EnumerationTypeService(Constants.EnumerationTypes.ProjectStatus);
    this.projectCancellationReasonService = new EnumerationTypeService(Constants.EnumerationTypes.ProjectCancellationReason);
    this.projectExternalRoleService = new EnumerationTypeService(Constants.EnumerationTypes.ProjectExternalRole);
    this.projectPartnerRoleService = new EnumerationTypeService(Constants.EnumerationTypes.ProjectPartnerRole);
    this.projectTypeService = new EnumerationTypeService(Constants.EnumerationTypes.ProjectType);
    this.projectClassService = new EnumerationTypeService(Constants.EnumerationTypes.ProjectClass);
    this.approvalService = new EnumerationTypeService(Constants.EnumerationTypes.Approval);
    this.projectContactsService = new ServiceBase<Merlin.Web.Model.ProjectContact>(Constants.EntityTypeNames.ProjectContact);
    this.projectPartnersService = new ServiceBase<Merlin.Web.Model.ProjectPartner>(Constants.EntityTypeNames.ProjectPartner);
    this.projectUsersService = new ServiceBase<Merlin.Web.Model.ProjectUsers>(Constants.EntityTypeNames.ProjectUsers);
    this.addressService = new ServiceBase<Merlin.Web.Model.Address>(Constants.EntityTypeNames.Address);
    this.applicationParameterService = new ServiceBase<Merlin.Web.Model.ApplicationParameter>(Constants.EntityTypeNames.ApplicationParameter);

    super.initialize(new ServiceBase<Merlin.Web.Model.Project>(Constants.EntityTypeNames.Project));
  }

  @computedFrom('editingMode', 'entity.projectName')
  public get documentTitle() {
    if (this.editingMode === EditingModeEnum.Create) {
      return this.i18n.tr("project.project");
    }
    else {
      return this.entity.projectName;
    }
  }
  attached() {
    if (this.isCreationMode) {
      this.disposables.push(
        this.bindingEngine.propertyObserver(this.entity, "mainThirdPartyId").subscribe(async (newValue, oldValue) => {
          if (newValue != oldValue && this.isCreationMode) {
            this.entity.communicationLanguageId = this.entity.mainThirdParty.spokenLanguageId;
            this.entity.communicationLanguage = this.languages.find(x => x.id == this.entity.communicationLanguageId);
          }
        })
      );
    }
    this.entity.projectContacts.forEach(pc => this.observeProjectContact(pc));
  }

  public async activate(params: any) {
    this.languages = await this.languageService.getAll();
    let id = params.projectId;
    await super.activate(params);

    this.thirdPartiesService.gridDataSource.queryParameters = { onlyActive: false, thirdPartyTypeIds: [Constants.ThirdPartyType.Client, Constants.ThirdPartyType.Partner] };
    this.thirdPartiesPartnersService.gridDataSource.queryParameters = { onlyActive: false, onlyCompany: true, onlyPartner: true };
    this.thirdPartiesTempoparyCompanyService.gridDataSource.queryParameters = { onlyActive: false, onlyCompany: true, onlyTemporaryCompany: true };

    this.applicationParameter = await this.applicationParameterService.firstEntity();

    if (id == Various.NewId) {
      this.editingMode = EditingModeEnum.Create;
      this.entity = await this.service.createEntity();
      this.entity.mainThirdParty = await this.thirdPartiesService.firstEntity();
      this.entity.communicationLanguageId = this.entity.mainThirdParty.spokenLanguageId;
      this.entity.communicationLanguage = this.languages.find(x => x.id == this.entity.communicationLanguageId);
      this.entity.projectStatus = await this.projectStatusService.getEntityById(Constants.ProjectStatusId.Open);
      let workQuotationVersion: Merlin.Web.Model.WorkQuotationVersion = await this.workQuotationVersionService.createEntity();
      workQuotationVersion.project = this.entity;
      workQuotationVersion.quotationVersionName = "1";
      workQuotationVersion.isCurrentVersion = true;

      this.entity.projectType = await this.projectTypeService.getEntityById(Constants.ProjectTypeId.Public);
      this.entity.nbDecimalForPriceDisplay = this.applicationParameter.nbDecimalForPriceDisplay;
      this.entity.nbDecimalForQuantityDisplay = this.applicationParameter.nbDecimalForQuantityDisplay;
    }
    else {
      this.editingMode = EditingModeEnum.Update;
      await this.getEntity(id);
      this.controller.addObject(this.entity);
      let result = await this.httpClient.get(Constants.Application.ProjectController.ProjectCanBeStarted.format(this.entity.id.toString()))
      if (result.ok) {
        this.canBeStarted = await result.json()
      }
      else {
        this.canBeStarted = false
      }

      let resultMetering = await this.httpClient.get(Constants.Application.ProjectController.HasMetering.format(this.entity.id.toString()))
      if (resultMetering.ok) {
        this.hasMetering = await resultMetering.json();
      }
      else {
        this.hasMetering = false
      }
      await this.computePriceToSquareMeter();
      this.bindingEngine.propertyObserver(this.entity, 'totalSurfaceProject').subscribe(async (newVal, oldVal) => {
        await this.computePriceToSquareMeter();
      });
    }
    this.notificationService.setPreviousInternalTeams(this.entity.projectUsers);
    await this.setDefaultInternalTeam();
    this.showInternalTeam = true;

    this.setTabList();
    await this.setMenuItems();
    this.configAddressService();
    this.configCustomProjectInternalThirdPartiesService();

    if (params.tab != null) {
      setTimeout(() => {
        document.getElementById(params.tab).scrollIntoView({ behavior: "auto", block: "start" });
      }, 500)
    }
  }

  private async getEntity(id) {
    this.entity = await this.service.getEntityById(id, 'mainThirdParty', 'communicationLanguage', 'projectStatus',
      'projectType', 'temporaryCompanyPartner', 'temporaryCompany', 'projectOwner', 'workingSiteAddress', 'projectPartners.thirdParty', 'projectPartners.role',
      'projectContacts.projectExternalRole', 'projectContacts.thirdParty', 'projectContacts.contactPerson.person',
      'projectUsers.projectUserSales', 'projectUsers.projectUserPreparation', 'projectUsers.projectUserSecretary',
      'projectUsers.projectUserFinance', 'projectUsers.projectUserExecutiveManagement', 'projectUsers.projectUserTechnicalManagement',
      'projectUsers.projectUserSecurity', 'projectUsers.projectUserPriceOffer.merlinUser', 'projectUsers.projectUserBuyings.merlinUser', 'projectUsers.projectUserExecutions.merlinUser');
  }

  private async setDefaultInternalTeam() {
    if (this.entity.projectUsers == null) {
      let appParamsService = new ServiceBase<Merlin.Web.Model.ApplicationParameter>(Constants.EntityTypeNames.ApplicationParameter);
      let appParams = await appParamsService.firstEntity(null,
        ['defaultProjectUsers.projectUserSales', 'defaultProjectUsers.projectUserPreparation', 'defaultProjectUsers.projectUserSecretary',
          'defaultProjectUsers.projectUserFinance', 'defaultProjectUsers.projectUserExecutiveManagement', 'defaultProjectUsers.projectUserTechnicalManagement',
          'defaultProjectUsers.projectUserSecurity', 'defaultProjectUsers.projectUserPriceOffer.merlinUser', 'defaultProjectUsers.projectUserBuyings.merlinUser', 'defaultProjectUsers.projectUserExecutions.merlinUser']
      );
      if (appParams != null && appParams.defaultProjectUsers != null) {
        this.entity.projectUsers = await this.projectUsersService.createEntity({
          projectUserExecutiveManagementId: appParams.defaultProjectUsers.projectUserExecutiveManagementId,
          projectUserFinanceId: appParams.defaultProjectUsers.projectUserFinanceId,
          projectUserPreparationId: appParams.defaultProjectUsers.projectUserPreparationId,
          projectUserSalesId: appParams.defaultProjectUsers.projectUserSalesId,
          projectUserSecretaryId: appParams.defaultProjectUsers.projectUserSecretaryId,
          projectUserSecurityId: appParams.defaultProjectUsers.projectUserSecurityId,
          projectUserTechnicalManagementId: appParams.defaultProjectUsers.projectUserTechnicalManagementId,
        });

        let projectUserPriceOfferService = new ServiceBase<Merlin.Web.Model.ProjectUserPriceOffer>(Constants.EntityTypeNames.ProjectUserPriceOffer);
        let projectUserBuyingsService = new ServiceBase<Merlin.Web.Model.ProjectUserBuying>(Constants.EntityTypeNames.ProjectUserBuying);
        let projectUserExecutionsService = new ServiceBase<Merlin.Web.Model.ProjectUserExecution>(Constants.EntityTypeNames.ProjectUserExecution);

        if (Array.isArray(appParams.defaultProjectUsers.projectUserPriceOffer)) {
          for (let user of appParams.defaultProjectUsers.projectUserPriceOffer) {
            if (this.entity.projectUsers.projectUserPriceOffer == null)
              this.entity.projectUsers.projectUserPriceOffer = [];
            this.entity.projectUsers.projectUserPriceOffer.push(await projectUserPriceOfferService.createEntity({ merlinUserId: user.merlinUserId }));
          }
        }

        if (Array.isArray(appParams.defaultProjectUsers.projectUserBuyings)) {
          for (let user of appParams.defaultProjectUsers.projectUserBuyings) {
            if (this.entity.projectUsers.projectUserBuyings == null)
              this.entity.projectUsers.projectUserBuyings = [];
            this.entity.projectUsers.projectUserBuyings.push(await projectUserBuyingsService.createEntity({ merlinUserId: user.merlinUserId }));
          }
        }

        if (Array.isArray(appParams.defaultProjectUsers.projectUserExecutions)) {
          for (let user of appParams.defaultProjectUsers.projectUserExecutions) {
            if (this.entity.projectUsers.projectUserExecutions == null)
              this.entity.projectUsers.projectUserExecutions = [];
            this.entity.projectUsers.projectUserExecutions.push(await projectUserExecutionsService.createEntity({ merlinUserId: user.merlinUserId }));
          }
        }
      } else {
        this.entity.projectUsers = await this.projectUsersService.createEntity();
      }
    }
  }

  public async save() {
    await super.save();
    await this.notificationService.sendNotificationToInternalTeam(this.entity);
  }

  public async cancel(silentCancel?: boolean, onlyCurrentEntity?: boolean) {
    this.showInternalTeam = false;
    let r = await super.cancel(silentCancel, onlyCurrentEntity);
    this.showInternalTeam = true;
    return r;
  }

  private configAddressService() {
    this.addressService.gridDataSource.expands = ['country', 'locality', 'addressType'];

    const setCustPred = () => {
      this.addressService.gridDataSource.customSelect2Predicates = (filterText, filterProperties) => {
        let pred = new Predicate("thirdPartyId", FilterQueryOp.Equals, this.entity.mainThirdPartyId)
        return pred.and(new Predicate("addressTypeId", FilterQueryOp.Equals, Constants.AddressType.WorkingSite));
      };
    }

    setCustPred();

    this.disposables.push(this.bindingEngine.propertyObserver(this.entity, 'mainThirdPartyId').subscribe((newValue: number, oldValue: number) => {
      if (newValue != oldValue) {
        this.entity.workingSiteAddressId = null;
        setCustPred();
      }
    }));
  }

  private setTabList() {
    this.tabList = new Array<IAnchorTab>(
      {
        id: Constants.ProjectDetailTab.GeneralInformation,
        name: this.i18n.tr("groupTabPanel.generalInformation"),
        isVisible: true
      },
      {
        id: Constants.ProjectDetailTab.InternalTeam,
        name: this.i18n.tr("project.internalTeam"),
        isVisible: true
      },
      {
        id: Constants.ProjectDetailTab.ProjectPartners,
        name: this.i18n.tr("project.projectPartners"),
        isVisible: true
      },
      {
        id: Constants.ProjectDetailTab.ExternalTeam,
        name: this.i18n.tr("project.externalTeam"),
        isVisible: true
      }
    );
  }

  private async setMenuItems() {
    this.menuItems = [
      {
        group: "1",
        hiddenLabel: true,
        items: [
          {
            label: this.i18n.tr("project.visualising"),
            icon: "digi-eye",
            handler: async () => {
              this.navigateToResume();
            }
          },
          {
            label: this.i18n.tr("project.startProject"),
            icon: "digi-play-circle-line",
            handler: async () => {
              await StartProject.startProject(this.entity.id);
            },
            disabled: !this.canBeStarted || this.entity.projectStarted
          },
          // { //ATTENTION problème avec les différents sous router
          //   label: this.i18n.tr("menu.delete"),
          //   icon: "digi-trash",
          //   handler: async () => {
          //     this.gls.allow();
          //     await this.delete();
          //   }
          // },
          {
            label: this.i18n.tr("project.projectStatusId"),
            icon: "digi-exchange-line",
            items: await this.getStatusItem()
          }
        ]
      },
    ];
  }

  private async getStatusItem() {
    let itemsStatus: Array<IMenuItems> = new Array();
    itemsStatus.push({
      label: (await this.projectStatusService.getEntityById(Constants.ProjectStatusId.StandBy)).denomination._translation,
      handler: async () => {
        await this.changeStatus(Constants.ProjectStatusId.StandBy);
      },
      hidden: this.entity.projectStatusId == Constants.ProjectStatusId.StandBy
    });
    itemsStatus.push({
      label: (await this.projectStatusService.getEntityById(Constants.ProjectStatusId.Lost)).denomination._translation,
      handler: async () => {
        await this.changeStatus(Constants.ProjectStatusId.Lost);
      },
      hidden: this.entity.projectStatusId == Constants.ProjectStatusId.Lost
    });
    itemsStatus.push({
      label: (await this.projectStatusService.getEntityById(Constants.ProjectStatusId.Abandon)).denomination._translation,
      handler: async () => {
        await this.changeStatus(Constants.ProjectStatusId.Abandon);
      },
      hidden: this.entity.projectStatusId == Constants.ProjectStatusId.Abandon
    });
    itemsStatus.push({
      label: (await this.projectStatusService.getEntityById(Constants.ProjectStatusId.Offer)).denomination._translation,
      handler: async () => {
        await this.changeStatus(Constants.ProjectStatusId.Offer);
      },
      hidden: this.entity.projectStatusId != Constants.ProjectStatusId.Abandon && this.entity.projectStatusId != Constants.ProjectStatusId.Lost && this.entity.projectStatusId != Constants.ProjectStatusId.StandBy
    });
    return itemsStatus;
  }

  private async changeStatus(statusId: number) {
    await this.httpClient.get(Constants.Application.ProjectController.SetStatus.format(this.entity.id.toString(), statusId.toString()));
    await this.getEntity(this.entity.id);
    await this.setMenuItems();
  }

  protected changeRouteAfterCreation() {
    if (!this.dialogService.hasOpenDialog) {
      let fragment = this.router.baseUrl;
      let newRoute = fragment.replace('-100', (<any>this.entity).id);
      this.router.navigate(newRoute, { replace: true, trigger: true });
    }
  }

  public navigateToResume() {
    let url = this.router.generate("home");
    if (this.router?.currentInstruction?.queryString?.length > 0) {
      url += '?' + this.router.currentInstruction.queryString;
    }
    this.router.navigate(url);
  }

  //#region External Team

  private get projectThirdParties(): Array<Merlin.Web.Model.ThirdParty> {
    let values = [];

    this.thirdPartiesToObserve.forEach(property => {
      values.push(this.entity[property]);
    })

    this.entity.projectPartners.forEach(partner => {
      values.push(partner.thirdParty);
    });

    return values.filter(x => x != null);
  };

  private readonly thirdPartiesToObserve = ['mainThirdParty', 'temporaryCompany', 'temporaryCompanyPartner', 'projectOwner'];

  private configCustomProjectInternalThirdPartiesService() {
    this.customProjectInternalThirdPartiesService = new CustomThirdPartiesSourceService(this.projectThirdParties);

    this.thirdPartiesToObserve.forEach(propertyName => {
      this.disposables.push(this.bindingEngine.propertyObserver(this.entity, propertyName).subscribe((newValue: number, oldValue: number) => {
        if (oldValue != newValue) {
          this.customProjectInternalThirdPartiesService = new CustomThirdPartiesSourceService(this.projectThirdParties);
        }
      }))
    });

    this.disposables.push(
      this.bindingEngine.collectionObserver(this.entity.projectPartners).subscribe(records => {
        this.customProjectInternalThirdPartiesService = new CustomThirdPartiesSourceService(this.projectThirdParties);
      })
    );
  }

  //#endregion

  //#region External Team

  public customContactPersonService(thirdPartyId: number): ServiceBase<Merlin.Web.Model.ContactPerson> {
    if (thirdPartyId == null) thirdPartyId = 0;
    let cp: ServiceBase<Merlin.Web.Model.ContactPerson> = new ServiceBase(Constants.EntityTypeNames.ContactPerson);
    cp.gridDataSource.expands = ['person'];
    cp.gridDataSource.queryParameters = { companyId: thirdPartyId };
    return cp;
  }

  private getProjectContactMenuItem(projectContact: Merlin.Web.Model.ProjectContact) {
    return [
      {
        group: "1",
        hiddenLabel: true,
        items: [
          {
            label: this.i18n.tr("menu.remove"),
            icon: "digi-trash",
            handler: () => {
              this.deleteProjectContact(projectContact);
            }
          }
        ]
      }
    ];
  }

  private getProjectPartnerMenuItem(projectPartner: Merlin.Web.Model.ProjectPartner) {
    return [
      {
        group: "1",
        hiddenLabel: true,
        items: [
          {
            label: this.i18n.tr("menu.remove"),
            icon: "digi-trash",
            handler: () => {
              this.deleteProjectPartner(projectPartner);
            }
          }
        ]
      }
    ];
  }

  public async addProjectContact() {
    let pc: Merlin.Web.Model.ProjectContact = await this.projectContactsService.createEntity({ projectId: this.entity.id });
    this.observeProjectContact(pc);
    this.controller.addObject(pc);
  }

  public async addProjectPartner() {
    let pc: Merlin.Web.Model.ProjectPartner = await this.projectPartnersService.createEntity({ projectId: this.entity.id });
    this.controller.addObject(pc);
  }

  private observeProjectContact(pc: Merlin.Web.Model.ProjectContact) {
    this.disposables.push(this.bindingEngine.propertyObserver(pc, 'thirdPartyId').subscribe((oldValue, newValue) => {
      if (oldValue != newValue) {
        if (pc.contactPersonId != null) {
          pc.contactPersonId = null;
        }
      }
    }));
  }

  public deleteProjectContact(entity: Merlin.Web.Model.ProjectContact) {
    if (entity == null) return;
    if (entity.id > 0) {
      entity.entityAspect.setDeleted();
    } else {
      entity.entityAspect.setDetached();
    }
  }

  public deleteProjectPartner(entity: Merlin.Web.Model.ProjectPartner) {
    if (entity == null) return;
    if (entity.id > 0) {
      entity.entityAspect.setDeleted();
    } else {
      entity.entityAspect.setDetached();
    }
  }

  private async computePriceToSquareMeter() {
    let totalProject = (await this.projectApiService.getNumber(this.entity.id)).lastOfferPrice;
    if (totalProject != null && this.entity.totalSurfaceProject != null && this.entity.totalSurfaceProject > 0) {
      this.priceToSquareMeter = totalProject / this.entity.totalSurfaceProject
    }
    else {
      this.priceToSquareMeter = null;
    }
  }

  //#endregion
}

import { RefToDisplay } from '../constants';
import { GlobalLoaderService, KeyboardShortcut } from 'digiwall-lib';
import { HttpClient } from 'aurelia-fetch-client';
import { autoinject } from 'aurelia-framework';
import { JSONPatchOperation } from './json-patch-operation';
import * as Constants from '../constants';
import { I18N } from 'aurelia-i18n';
import { FiltersUtils } from 'resources/utilities/filter-utils';
import { ActionDialogBoxInputParameters, Box, CustomLogger, DialogBoxViewModel } from 'digiwall-lib';
import { PriceOfferLineFromItem } from '../projects/quotes/add-items/add-items';
import * as toastr from 'toastr';
import { IDataLineApiService, RequestFilterPriceOfferLine, RequestCreatePriceOfferLine, RequestMovePriceOfferLine, MeteringTotalPrices, BulkUpdateOperation } from './i-data-line-api-service';
import { POVSummary, SummaryLineCoefficient, UpdateCoefficientDto } from './price-offer-version-api-model';

@autoinject
export class PriceOfferVersionApiService implements IDataLineApiService {
  protected callback: () => void;

  constructor(protected httpClient: HttpClient, private box: Box, private i18n: I18N, protected filtersUtils: FiltersUtils, protected globalLoaderService: GlobalLoaderService, private logger: CustomLogger) { }
  computePAR?(versionId: number): Promise<boolean> {
    throw new Error('Method not implemented.');
  }
  deletePAR?(versionId: number, lineId: number): Promise<boolean> {
    throw new Error('Method not implemented.');
  }
  async recomputeLines(versionId: number): Promise<MeteringTotalPrices> {
    let query = await this.httpClient.get(Constants.Application.PriceOfferVersionController.RecomputeLines.format(versionId.toString()));
    if (query.ok) {
      let result = await query.json();
      return result;
    }
  }
  async getLastChapterId(versionId: number): Promise<number> {
    let query = await this.httpClient.get(Constants.Application.PriceOfferVersionController.GetLastChapterId.format(versionId.toString()));
    if (query.ok) {
      let result = await query.json();
      return result;
    }
  }

  subscribe(callback: () => void) {
    this.callback = callback;
  }
  async createPriceOfferVersion(priceOfferVersionId: number) {
    let requestUri = Constants.Application.PriceOfferVersionController.CreatePriceOfferVersion.format(priceOfferVersionId.toString());
    this.globalLoaderService.allow(true, 0);
    let query = await this.httpClient.post(requestUri);
    if (query.ok) {
      return true;
    }
  }

  public async children(versionId: number, lineId: number, displayHidden: boolean, refToDisplay: RefToDisplay): Promise<Array<any>> {
    let requestUri = Constants.Application.PriceOfferVersionController.GetChildren.format(versionId.toString(), lineId.toString(), displayHidden.toString(), refToDisplay.toString());
    let query = await this.httpClient.get(requestUri);
    if (query.ok) {
      return await query.json();
    }
  }

  public async filter(versionId: number, filterParams: any, quickFilter: string, displayMode: Constants.TreeDisplayMode, displayHidden: boolean, refToDisplay: RefToDisplay): Promise<Array<any>> {
    let requestParams: RequestFilterPriceOfferLine = {
      filterModel: filterParams,
      quickFilter: quickFilter,
      displayMode: displayMode,
      displayHidden: displayHidden,
      refToDisplay: refToDisplay
    };
    let requestUri = Constants.Application.PriceOfferVersionController.GetAll.format(versionId.toString());
    let response = await this.httpClient.post(requestUri, JSON.stringify(requestParams));
    if (response.ok) {
      return await response.json();
    }
  }
  public async fetch(versionId: number, ids: number[], displayHidden: boolean, refToDisplay: RefToDisplay) {
    let result = await this.httpClient.post(Constants.Application.PriceOfferVersionController.GetByIds.format(versionId.toString(), displayHidden.toString(), refToDisplay.toString()), JSON.stringify(ids));
    if (result.ok) {
      let entities: Array<any> = await result.json();
      return entities;
    }
    else {
      return null;
    }
  }
  public async bulkPatch(versionId: number, bulkUpdateOperation: BulkUpdateOperation[]): Promise<Array<any>> {
    let requestUri = Constants.Application.PriceOfferVersionController.BulkPatch.format(versionId.toString());

    const params = bulkUpdateOperation.map(x => {
      return {
        lineId: x.lineId,
        patchDoc: JSONPatchOperation.operateReplace(x.propertyName, x.propertyValue)
      }
    });
    let patchResponse = await this.httpClient.patch(requestUri, JSON.stringify(params));
    if (patchResponse.ok) {
      return await patchResponse.json();
    } else {
      if (patchResponse.status == 400) {
        return bulkUpdateOperation.map(x => x.lineId);
      }
    }
    return [];
  }
  public async patch(versionId: number, lineId: number, propertyName: string, propertyValue: any): Promise<Array<any>> {
    let requestUri = Constants.Application.PriceOfferVersionController.Patch.format(versionId.toString(), lineId.toString());
    let patchResponse = await this.httpClient.patch(requestUri, JSON.stringify(JSONPatchOperation.operateReplace(propertyName, propertyValue)));
    if (patchResponse.ok) {
      return await patchResponse.json();
    } else {
      if (patchResponse.status == 400) {
        return [lineId];
      }
    }
    return [];
  }


  public async duplicateLine(versionId: number, lineId: number): Promise<Array<number>> {
    let requestUri = Constants.Application.PriceOfferVersionController.DuplicateLine.format(versionId.toString(), lineId.toString());
    let response = await this.httpClient.get(requestUri);
    if (response.ok) {
      return await response.json();
    }
  }

  public async duplicateVersion(versionId: number): Promise<number> {
    let requestUri = Constants.Application.PriceOfferVersionController.DuplicateVersion.format(versionId.toString());
    this.globalLoaderService.allow(true, 0);
    let response = await this.httpClient.get(requestUri);
    if (response.ok) {
      return await response.json();
    }
  }

  public async create(versionId: number, targetId: number, categoryId: number, action: Constants.PriceOfferLineMoveAction): Promise<Array<number>> {
    let requestCreatePriceOfferLine: RequestCreatePriceOfferLine = {
      targetId: targetId,
      categoryId: categoryId,
      fK: versionId,
      action: action
    };

    let requestUri = Constants.Application.PriceOfferVersionController.CreateLine.format(versionId.toString());
    this.globalLoaderService.allow(true);
    let query = await this.httpClient.post(requestUri, JSON.stringify(requestCreatePriceOfferLine));
    if (query.ok) {
      return await query.json();
    }
    return [];
  }

  public async move(versionId: number, lineIds: Array<number>, targetId: number, moveType: Constants.PriceOfferLineMoveAction): Promise<Array<number>> {

    let requestMovePriceOfferLine: RequestMovePriceOfferLine = {
      action: moveType,
      targetId: targetId,
      priceOfferLineIds: lineIds
    };

    let requestUri = Constants.Application.PriceOfferVersionController.Move.format(versionId.toString());
    let response = await this.httpClient.post(requestUri, JSON.stringify(requestMovePriceOfferLine));
    if (response.ok) {
      return await response.json();
    }

    return [];
  }


  async paste(versionId: number, lineIds: number[], targetId: number, moveType: Constants.PriceOfferLineMoveAction): Promise<number[]> {
    let requestMovePriceOfferLine: RequestMovePriceOfferLine = {
      action: moveType,
      targetId: targetId,
      priceOfferLineIds: lineIds
    };

    let requestUri = Constants.Application.PriceOfferVersionController.Paste.format(versionId.toString());
    let response = await this.httpClient.post(requestUri, JSON.stringify(requestMovePriceOfferLine));
    if (response.ok) {
      return await response.json();
    }

    return [];
  }
  public async select(lineId: number) {
    return null;
  }
  public async unselect(lineId: number) {
    return null;
  }

  public async delete(versionId: number, lineIds: Array<number>): Promise<Array<number>> {
    let result = await this.canDelete(versionId, lineIds);
    if (result) {
      let msg = lineIds.length == 1 ? this.i18n.tr('menu.delete_confirmation') : this.i18n.tr('menu.delete_confirmation_plural', { count: lineIds.length });
      let ids = [];

      let buttonYes: ActionDialogBoxInputParameters = {
        label: this.i18n.tr("general.ok", { ns: "common" }),
        title: this.i18n.tr("general.ok", { ns: "common" }),
        theme: 'primary',
        type: 'solid',
        disabled: false,
        fn: (thisBox: DialogBoxViewModel) => {
          thisBox.controller.ok(true);
        },
        keyboardShortcut: KeyboardShortcut.Enter
      };
      let buttonNo: ActionDialogBoxInputParameters = {
        label: this.i18n.tr("general.cancel", { ns: "common" }),
        title: this.i18n.tr("general.cancel", { ns: "common" }),
        theme: 'dark',
        type: 'ghost',
        disabled: false,
        fn: (thisBox: DialogBoxViewModel) => {
          thisBox.controller.ok(false);
        },
        keyboardShortcut: KeyboardShortcut.Escape
      };
      await this.box.showQuestion(msg, this.i18n.tr("menu.question", { ns: "common" }), [buttonNo, buttonYes])
        .whenClosed(async (result) => {
          if (!result.wasCancelled && result.output) {
            let requestUri = Constants.Application.PriceOfferVersionController.DeleteLine.format(versionId.toString());
            let response = await this.httpClient.delete(requestUri, JSON.stringify(lineIds));
            if (response.ok) {
              let temp = await response.json();
              ids.push(...temp);
            } else {
              if (response.status == 400) {
                let errorText = await response.text();
                this.logger.LogError(this.i18n.tr(errorText.slice(1, -1)), null, null, true);
              }
            }
          }
        });
      return ids;
    } else {
      this.box.showWarning(this.i18n.tr('versionmetering.notCanDelete'), this.i18n.tr('general.warningTitle'));
      return [];
    }
  }

  private async canDelete(versionId: number, lineIds: Array<number>): Promise<boolean> {
    let requestUri = Constants.Application.PriceOfferVersionController.CanDelete.format(versionId.toString());
    let response = await this.httpClient.post(requestUri, JSON.stringify(lineIds));
    if (response.ok) {
      return await response.json();
    }
  }

  public async versionHasLines(versionId: number): Promise<boolean> {
    let requestUri = Constants.Application.PriceOfferVersionController.VersionHasLine.format(versionId.toString());
    let response = await this.httpClient.get(requestUri);
    if (response.ok) {
      return await response.json();
    }
  }
  async createFromItems(versionId: number, selectedItems: PriceOfferLineFromItem[]): Promise<Array<number>> {
    let requestUri = Constants.Application.PriceOfferVersionController.CreateFromItems.format(versionId.toString());
    let query = await this.httpClient.post(requestUri, JSON.stringify(selectedItems));

    if (query.ok) {
      return await query.json();
    }
    return null;
  }

  async getMeteringTotalPrices(versionId: number): Promise<MeteringTotalPrices> {
    let query = await this.httpClient.get(Constants.Application.PriceOfferVersionController.PriceOfferVersionTotal.format(versionId.toString()));
    if (query.ok) {
      let result = await query.json();
      return result;
    }
  }

  async createVariantGroup(versionId: number, lineIds: number[]): Promise<Array<number>> {
    let query = await this.httpClient.post(Constants.Application.PriceOfferVersionController.CreateNewVariantGroup.format(versionId.toString()), JSON.stringify(lineIds));
    if (query.ok) {
      return await query.json();
    }
  }
  async deleteVariantGroup(versionId: number, lineId: number): Promise<Array<number>> {
    let query = await this.httpClient.delete(Constants.Application.PriceOfferVersionController.DeleteVariantGroup.format(versionId.toString(), lineId.toString()));
    if (query.ok) {
      return await query.json();
    }
  }


  async getPMThatNeedRepartion(versionId: number): Promise<Array<string>> {
    let response = await this.httpClient.get(Constants.Application.PriceOfferVersionController.GetPMThatNeedRepartion.format(versionId.toString()));
    if (response.ok) {
      return await response.json();
    }
    else {
      toastr.error(await response.text());
    }
  }

  async recomputeTotalVersion(versionId: number): Promise<void> {
    let response = await this.httpClient.get(Constants.Application.PriceOfferVersionController.RecomputeTotalVersion.format(versionId.toString()));
    if (response.ok) {
      return await response.json();
    }
    else {
      toastr.error(await response.text());
    }
  }

  async getSummary(priceOfferVersionId: number): Promise<POVSummary> {
    let response = await this.httpClient.get(Constants.Application.PriceOfferVersionController.GetSummary.format(priceOfferVersionId.toString()));
    if (response.ok) {
      return await response.json();
    }
  }

  async updateCoefficient(priceOfferVersionId: number, coefficientName: string, coefficient: number | null, ids: Array<number>): Promise<void> {
    let updateCoefficientDto = {
      coefficient: coefficient,
      ids: ids
    } as UpdateCoefficientDto;
    this.globalLoaderService.allow(true, 0);
    let query = await this.httpClient.post(Constants.Application.PriceOfferVersionController.UpdateCoefficient.format(priceOfferVersionId.toString(), coefficientName), JSON.stringify(updateCoefficientDto));
  }

  async updateSummaryLine(priceOfferVersionId: number, line: SummaryLineCoefficient) {
    let query = await this.httpClient.post(Constants.Application.PriceOfferVersionController.UpdateSummaryLine.format(priceOfferVersionId.toString()), JSON.stringify(line));

  }
}

import { Router } from 'aurelia-router';
import { EntityDetailViewModelBase, CustomLogger, EditingModeEnum, ServiceBase, Various } from 'digiwall-lib';
import { Merlin } from "../../generated";
import { autoinject, BindingEngine } from 'aurelia-framework';
import * as Constants from '../../constants';
import { DialogController } from 'aurelia-dialog';

@autoinject
export class AttachExternalEmailDetail extends EntityDetailViewModelBase<Merlin.Web.Model.ExternalEmail> {
  public ressourceName: string = Constants.EntityTypeNames.ExternalEmail;
  public documentTitle: string;

  constructor(router: Router, logger: CustomLogger, private bindingEngine: BindingEngine, private dialogController: DialogController) {
    super(router, logger);

    super.initialize(new ServiceBase<Merlin.Web.Model.ExternalEmail>(Constants.EntityTypeNames.ExternalEmail));
  }

  public async activate(params: any) {
    super.activate(params);
    var id = params.param1;
    if (id == Various.NewId) {
      this.editingMode = EditingModeEnum.Create;
      this.entity = await this.service.createEntity();
    }
    else {
      this.editingMode = EditingModeEnum.Update;
      this.entity = await this.service.getEntityById(id);
      this.controller.addObject(this.entity);
    }
  }

  public async save() {
    let newEntity = await this.saveCurrentEntity();
    this.dialogController.ok(newEntity);
  }

  private isCancelling: boolean = false;

  public async close() {
    this.isCancelling = true;
    this.entity.entityAspect.rejectChanges();
    await this.dialogController.cancel();
    this.isCancelling = false;
  }
}

import { CustomLogger, ServiceBase, CamelCase, IMenuItems, ListViewModelBase, FieldType, Various } from 'digiwall-lib';
import { Router } from 'aurelia-router';
import { autoinject } from 'aurelia-framework';
import { Merlin } from "../generated";
import { ColDef } from "ag-grid-community";
import * as Constants from '../constants';
import { DialogService } from 'aurelia-dialog';
import { GridHelperMerlin } from 'resources/elements/grid-helper';

@autoinject
export class ExternalEmailList extends ListViewModelBase<Merlin.Web.Model.ExternalEmail> {
  public ressourceName: string = Constants.EntityTypeNames.ExternalEmail;


  private linkedToUser: boolean;
  private projectId: number;

  private menuItems: Array<IMenuItems>;

  constructor(router: Router, logger: CustomLogger, private dialogService: DialogService) {
    super(router, logger, new ServiceBase<Merlin.Web.Model.ExternalEmail>(Constants.EntityTypeNames.ExternalEmail));
    this.setMenuItems();
  }

  public async activate(params: any) {
    super.activate(params);
    this.linkedToUser = params.linkedToUser;
    this.projectId = params.projectId;
    if (this.projectId != null) {
      this.service.gridDataSource.queryParameters = { linkedToUser: this.linkedToUser, projectId: this.projectId };
    } else {
      this.service.gridDataSource.queryParameters = { linkedToUser: this.linkedToUser };
    }
  }

  public getDetailsUrl(self, entity: Merlin.Web.Model.ExternalEmail) {
    if (entity && entity.id) {
      var url = '/external-emails/' + entity.id;
      return url;
    }
    return null;
  }

  public getDataGridColumns() {
    super.getDataGridColumns();
    let defs: ColDef[] = [
      ...new GridHelperMerlin().contextMenuColdDef(this),
      {
        headerName: this.i18n.tr("externalemail.type"),
        field: "type",
        type: FieldType.Enum,
        filterParams: {
          category: "externalemail",
          enum: Constants.ExternalEmailType
        },
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          category: "externalemail",
          enum: Constants.ExternalEmailType
        },
        valueGetter: (data) => {
          if (data?.data?.[data.colDef.field] != null) {
            return this.i18n.tr("externalemail." + CamelCase.toCamelCase(data.data[data.colDef.field] as string));
          }
          return;
        }
      },
      {
        headerName: this.i18n.tr("externalemail.subject"),
        field: "subject",
        type: FieldType.String
      },
      {
        headerName: this.i18n.tr("externalemail.cc"),
        field: "cc",
        type: FieldType.String
      },
      {
        headerName: this.i18n.tr("externalemail.attachmentNumber"),
        field: "attachmentNumber",
        type: FieldType.Number
      },
      {
        headerName: this.i18n.tr("externalemail.date"),
        field: "date",
        filterParams: {
          dateFormat: Various.DateFormatWithHours
        },
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          dateFormat: Various.DateFormatWithHours
        },
        type: FieldType.Date
      },
      {
        headerName: this.i18n.tr("externalemail.from"),
        field: "from",
        type: FieldType.String
      },
      {
        headerName: this.i18n.tr("externalemail.to"),
        field: "to",
        type: FieldType.String
      },
      ...new GridHelperMerlin().baseEntityColDef(this),

    ];
    return defs;
  }

  private setMenuItems(): void {
    this.menuItems = [];
  }
}

import { ItemType } from './../constants';
import { ItemApiService } from './../services/item-api-service';
import { ItemPrice } from 'utils/item-price';
import { HttpClient } from 'aurelia-fetch-client';
import { Router } from 'aurelia-router';
import { EntityDetailViewModelBase, CustomLogger, EditingModeEnum, ServiceBase, EnumerationTypeService, FileFlow, Various, IAnchorTab, IMenuGroup, DialogBoxViewModel, PictureHelper, UIInternal, DateUtils } from 'digiwall-lib';
import { autoinject, BindingEngine, computedFrom, Disposable, TaskQueue } from 'aurelia-framework';
import * as Constants from '../constants';
import { Merlin } from 'generated';
import { validateTrigger, ValidationRules } from 'aurelia-validation';
import { ItemFamilyTreeView } from './item-families-tree-view/item-family-tree-view';
import moment = require('moment');
import { FilterQueryOp, Predicate } from 'breeze-client';
import { ItemComposition } from 'item-composition/item-composition';
import { ItemPriceDetail } from 'item-price-detail/item-price-detail';
import { ItemContractList } from './item-contract-list/item-contract-list';

@autoinject
export class ItemDetail extends EntityDetailViewModelBase<Merlin.Web.Model.Item> {
  public ressourceName: string = Constants.EntityTypeNames.Item;
  private isComposed: Boolean;
  private Constants = Constants;

  public tabList: Array<IAnchorTab>;
  private menuItems: Array<IMenuGroup>;

  private deliveryService: EnumerationTypeService;
  private unitTypeService: EnumerationTypeService;
  private unitService: ServiceBase<Merlin.Web.Model.Unit>;
  private itemPriceService: ServiceBase<Merlin.Web.Model.ItemPrice>;
  private containerTypeService: EnumerationTypeService;
  private itemConditioningService: ServiceBase<Merlin.Web.Model.ItemConditioning>;
  private itemCompositionService: ServiceBase<Merlin.Web.Model.ItemComposition>;
  private itemCompositionLineService: ServiceBase<Merlin.Web.Model.ItemCompositionLine>;
  private itemFamilyService: ServiceBase<Merlin.Web.Model.ItemFamily>;
  private itemPriceFromDateService: ServiceBase<Merlin.Web.Model.ItemPriceFromDate>;
  private itemActivityCodeService: ServiceBase<Merlin.Web.Model.EnumerationTypeMerlin>;
  private supplierService: ServiceBase<Merlin.Web.Model.ThirdParty>;
  private itemFeatureService: EnumerationTypeService;
  private itemDocuments: ServiceBase<Merlin.Web.Model.ItemDocument>
  private fileTypeService: EnumerationTypeService;


  private applicationParameterService: ServiceBase<Merlin.Web.Model.ApplicationParameter>;
  private applicationParameter: Merlin.Web.Model.ApplicationParameter;
  private defaultMarginCoefficient: number = 0.0;
  private unitBuyingPrice: number = 0.0;
  private unitSellingPrice: number = 0.0;
  private changeSellingPrice: boolean = false;
  private isInFamilyOfParameters: boolean = false;
  firstTab: any;
  childrenNotValidated: boolean;
  private get defaultPredicate(): Predicate {
    if (this.entity) return new Predicate("itemId", FilterQueryOp.Equals, this.entity.id);
  }

  private readonly entityExtends = ['itemType', 'itemDeliveryTypes.itemDeliveryEnum', 'unitType', 'defaultUnit', 'itemFamilies.itemFamily',
    'features.itemFeatureEnum', 'activityCodes.activityCodeEnum', 'itemDocuments.document.fileType', 'itemDocuments.document.documentStatus', 'itemDocuments.document.language'];

  private itemPricesDisposables: Array<Disposable> = [];
  private itemCompositionsDisposables: Array<Disposable> = [];

  private nbDecimalForPriceDisplay: number
  private isUpdatingPrice: boolean = false;

  constructor(router: Router, logger: CustomLogger, private bindingEngine: BindingEngine, private fileHelper: PictureHelper, private httpClient: HttpClient, private itemApiService: ItemApiService, private taskQueue: TaskQueue) {
    super(router, logger);
    this.deliveryService = new EnumerationTypeService(Constants.EnumerationTypes.ItemDeliveryType);
    this.unitTypeService = new EnumerationTypeService(Constants.EnumerationTypes.UnitType);
    this.unitService = new ServiceBase<Merlin.Web.Model.Unit>(Constants.EntityTypeNames.Unit);
    this.itemPriceService = new ServiceBase<Merlin.Web.Model.ItemPrice>(Constants.EntityTypeNames.ItemPrice);
    this.containerTypeService = new EnumerationTypeService(Constants.EnumerationTypes.ContainerType);
    this.itemConditioningService = new ServiceBase<Merlin.Web.Model.ItemConditioning>(Constants.EntityTypeNames.ItemConditioning);
    this.itemCompositionService = new ServiceBase<Merlin.Web.Model.ItemComposition>(Constants.EntityTypeNames.ItemComposition);
    this.itemCompositionLineService = new ServiceBase<Merlin.Web.Model.ItemCompositionLine>(Constants.EntityTypeNames.ItemCompositionLine);
    this.applicationParameterService = new ServiceBase<Merlin.Web.Model.ApplicationParameter>(Constants.EntityTypeNames.ApplicationParameter);
    this.itemFamilyService = new ServiceBase<Merlin.Web.Model.ItemFamily>(Constants.EntityTypeNames.ItemFamily);
    this.itemPriceFromDateService = new ServiceBase<Merlin.Web.Model.ItemPriceFromDate>(Constants.EntityTypeNames.ItemPriceFromDate);
    this.itemActivityCodeService = new ServiceBase<Merlin.Web.Model.EnumerationTypeMerlin>(Constants.EntityTypeNames.EnumerationTypeMerlin);
    this.itemActivityCodeService.gridDataSource.queryParameters = { category: Constants.EnumerationTypes.ActivityCode, onlyActifs: true };
    this.itemFeatureService = new EnumerationTypeService(Constants.EnumerationTypes.ItemFeature);
    this.itemDocuments = new ServiceBase<Merlin.Web.Model.ItemDocument>(Constants.EntityTypeNames.ItemDocument);
    this.fileTypeService = new EnumerationTypeService(Constants.EnumerationTypes.FileType);

    this.supplierService = new ServiceBase<Merlin.Web.Model.ThirdParty>(Constants.EntityTypeNames.ThirdParty);
    this.supplierService.gridDataSource.queryParameters = { isSupplier: true };

    super.initialize(new ServiceBase<Merlin.Web.Model.Item>(Constants.EntityTypeNames.Item));

    ValidationRules.customRule('validation-deliveries', (value, obj: Merlin.Web.Model.Item) => {
      if (obj.itemTypeId != Constants.ItemType.Composed)
        return (value != null && value.length > 0);
      else
        return true
    }, 'item.deliveryError');

    // ValidationRules.customRule('validation-compositions', (value: Array<Merlin.Web.Model.ItemComposition>, obj) => {
    //   if (value != null && value.length > 0) {
    //     return value.every(x => x.itemCompositionLines != null && x.itemCompositionLines.length > 0);
    //   }
    //   return !this.isComposed;
    // }, "itemcomposition.lineError");
    ValidationRules.customRule('validation-article-code', async (value: string, obj) => {
      return await this.isArticleCodeExists(value);
    }, "item.uniqueCodeError");
  }

  @computedFrom('editingMode', 'entity.articleCode')
  public get documentTitle() {
    if (this.editingMode === EditingModeEnum.Create) {
      return this.i18n.tr("item.addItem");
    }
    else {
      return this.entity.articleCode ?? this.entity.description._translation ?? "";
    }
  }

  @computedFrom("entity.itemTypeId", "entity.itemCompositions.length", "entity.itemPrices.length")
  public get disabledItemType() {
    if (this.entity.itemTypeId == Constants.ItemType.Composed) {
      return this.entity.itemCompositions == null || this.entity.itemCompositions.length != 0;
    } else if (this.entity.itemTypeId == Constants.ItemType.Supply) {
      return this.entity.itemPrices == null || this.entity.itemPrices.length != 0;
    }
    return false;
  }

  public get expandedItemComposition() {
    if (this.entity == null || this.entity.itemCompositions == null) {
      return false;
    }
    return this.entity.itemCompositions.length > 0;
  }
  public set expandedItemComposition(val) { }

  public async activate(params: any) {
    let id: number = params.param1;
    await super.activate(params);
    this.applicationParameter = await this.applicationParameterService.firstEntity(null);
    if (id == Various.NewId) {
      this.editingMode = EditingModeEnum.Create;
      this.entity = await this.service.createEntity({ unitTypeId: null, defaultUnitId: null, itemDeliveryTypes: null });
      if (params.callback != null) params.callback(this.entity);
      this.entity.itemTypeId = Constants.ItemType.Supply;
      this.entity.isDeprecated = false;
      this.entity.detailsVisible = false;
      if (this.applicationParameter != null) {
        this.defaultMarginCoefficient = this.applicationParameter.defaultMarginCoefficientForItem;
      }
    }
    else {
      this.editingMode = EditingModeEnum.Update;
      await this.loadEntity(id);
    }

    if (this.applicationParameter != null) {
      this.nbDecimalForPriceDisplay = this.applicationParameter.nbDecimalForPriceDisplay;
    }

    this.setDocumentList();
    this.setTabList();
    this.setMenuItems();
    if (!this.isCreationMode && params.tab) {
      this.firstTab = params.tab
      //   this.tabList.find(x => x.id == params.tab).isSelected = true;
    }

    ValidationRules
      .ensure('itemDeliveryTypes')
      .satisfiesRule('validation-deliveries')
      // .ensure('itemCompositions')
      // .satisfiesRule('validation-compositions')
      .ensure('articleCode')
      .satisfiesRule('validation-article-code')
      .on(this.entity);

    this.controller.validateTrigger = validateTrigger.changeOrBlur;
  }

  private async loadEntity(id) {
    this.entity = await this.service.getEntityById(id, ...this.entityExtends);

    let arrayOfPromise = [] as Promise<any>[];
    let p1 = this.defaultPredicate;
    arrayOfPromise.push(this.itemConditioningService.getEntities(p1, ['containerType', 'contentUnit.unitName']));
    await Promise.all(arrayOfPromise);

    await this.loadItemPrices();

    this.controller.addObject(this.entity);
    this.unitService.gridDataSource.queryParameters = { unitTypeId: this.entity.unitTypeId };
    this.isComposed = this.entity.itemTypeId == Constants.ItemType.Composed;
    if (!this.isComposed) {
      let resultPrice = this.getItemPriceCurrent(this.entity.itemPrices.filter(x => x.isDefaultPrice)[0])
      if (resultPrice != null) {
        this.unitBuyingPrice = resultPrice.unitBuyingPrice;
        this.unitSellingPrice = resultPrice.unitSellingPrice;
        this.defaultMarginCoefficient = resultPrice.defaultMarginCoefficientPrice;
      }
    }

    await this.loadItemCompositions();
  }

  public attached() {
    this.disposables.push(
      this.bindingEngine.propertyObserver(this.entity, "itemTypeId").subscribe(async (newValue, oldValue) => {
        this.isComposed = newValue == Constants.ItemType.Composed;
        this.setTabList();
      }),
      this.bindingEngine.propertyObserver(this.entity, "unitTypeId").subscribe(async (newValue, oldValue) => {
        this.unitService.gridDataSource.queryParameters = { unitTypeId: newValue };

        if (oldValue != null && newValue != oldValue) {
          this.entity.defaultUnitId = null;
          this.entity.defaultUnit = null;
        } else if (oldValue == null) {
          this.entity.defaultUnit = await this.unitService.firstEntity(null, null, { unitTypeId: newValue });
        }
      }),
      this.bindingEngine.propertyObserver(this.entity, "defaultUnitId").subscribe(async (newValue, oldValue) => {
        if (oldValue == null && oldValue != newValue) {
          if (!this.isComposed) {
            this.entity.itemPrices.forEach(x => {
              x.unitId = newValue;
            });
          }
        }
      }),
    );

    if (this.isCreationMode) {
      this.disposables.push(
        this.bindingEngine.propertyObserver(this, "unitSellingPrice").subscribe(async (newValue, oldValue) => {
          if (this.isCreationMode && !this.isComposed && newValue != null && newValue != oldValue) {
            this.changeSellingPrice = true;
          }
        }),
        this.bindingEngine.propertyObserver(this, "unitBuyingPrice").subscribe(async (newValue, oldValue) => {
          if (this.isCreationMode && !this.isComposed && newValue != null && newValue != oldValue) {
            this.unitSellingPrice = await ItemPrice.getSellingPrice(newValue, this.defaultMarginCoefficient);
          }
        }),
        this.bindingEngine.propertyObserver(this, "defaultMarginCoefficient").subscribe(async (newValue, oldValue) => {
          if (this.isCreationMode && !this.isComposed && newValue != null && newValue != oldValue) {
            this.unitSellingPrice = await ItemPrice.getSellingPrice(this.unitBuyingPrice, newValue);
          }
        })
      );
    } else {
      this.disposables.push(
        this.bindingEngine.propertyObserver(this.entity, "detailsVisible").subscribe(async (newValue, oldValue) => {
          if (this.entity.itemTypeId == Constants.ItemType.Composed) {
            await this.itemApiService.patchItem(this.entity.id, "DetailsVisible", newValue);
            this.loadItemCompositions();
          }
        })
      );
    }
    this.taskQueue.queueTask(() => {
      setTimeout(() => {
        if (this.firstTab != null) {
          document.getElementById(this.firstTab).scrollIntoView({ behavior: "smooth", block: "start" });
        }
      }, 500)
    })

  }

  public detached(): void {
    this.itemCompositionsDisposables.forEach(d => d.dispose());
    this.itemPricesDisposables.forEach(d => d.dispose());
    return super.detached();
  }

  private setMenuItems() {
    this.menuItems = [
      {
        group: "1",
        hiddenLabel: true,
        items: [
          {
            label: this.i18n.tr("item.priceHistory"),
            icon: "digi-time-line",
            handler: () => {
              this.showPriceHistory();
            }
          },
          {
            label: this.i18n.tr("menu.delete"),
            icon: "digi-trash",
            handler: () => {
              this.delete()
            }
          }
        ]
      },
      {
        group: "3",
        hiddenLabel: true,
        items: [
          {
            label: this.i18n.tr("general.duplicate"),
            icon: "digi-file-copy-line",
            handler: () => {
              this.duplicateItem();
            }
          }
        ]
      },
      {
        group: "2",
        hiddenLabel: true,
        items: [
          {
            label: this.i18n.tr("item.validate"),
            icon: "digi-check-line",
            handler: () => {
              this.validateItem()
            },
            disabled: () => (this.entity.itemTypeId == ItemType.Composed && this.childrenNotValidated) || this.entity.articleCode == null || this.entity.articleCode.trim().length == 0,
            hidden: this.entity.isValidated
          }
        ]
      }
    ];
  }

  validateItem() {
    this.entity.isValidated = true;
  }

  private setTabList() {
    this.tabList = new Array<IAnchorTab>(
      {
        id: "tab1",
        name: this.i18n.tr("groupTabPanel.generalInformation"),
        isVisible: true
      },
      {
        id: "tab4",
        name: this.i18n.tr("groupTabPanel.itemprices"),
        isVisible: !this.isComposed && !this.isCreationMode
      },
      {
        id: "tab5",
        name: this.i18n.tr("groupTabPanel.itemcompositions"),
        isVisible: this.isComposed as boolean && !this.isCreationMode
      },
      {
        id: "tab2",
        name: this.i18n.tr("groupTabPanel.descriptions"),
        isVisible: true
      },
      {
        id: "tab6",
        name: this.i18n.tr("groupTabPanel.files"),
        isVisible: !this.isCreationMode
      },
      {
        id: "tab3",
        name: this.i18n.tr("groupTabPanel.itemConditionings"),
        isVisible: !this.isCreationMode
      }
    )
  }

  public getItemPricesMenuItem(currentThis: any) {
    return [
      {
        group: "1",
        hiddenLabel: true,
        items: [
          {
            label: this.i18n.tr("itempricefromdate.goToPriceFromDates"),
            icon: "digi-search-eye-line",
            handler: async () => {
              await this.goToPriceFromDate(currentThis);
            }
          },
          {
            label: this.i18n.tr("menu.remove"),
            icon: "digi-trash",
            handler: async () => {
              await this.removeItemPrice(currentThis);
            }
          }
        ]
      }
    ]
  }

  public getMenuItemsConditionning(params) {
    return [
      {
        group: "1",
        hiddenLabel: true,
        items: [
          {
            label: this.i18n.tr("menu.remove"),
            icon: "digi-trash",
            handler: () => {
              this.removeConditioning(params);
            }
          }
        ]
      }
    ];
  }

  public getCompoMenuItem(params) {
    return [
      {
        group: "1",
        hiddenLabel: true,
        items: [
          {
            label: this.i18n.tr("itemcomposition.goToComposition"),
            icon: "digi-search-eye-line",
            disabled: () => this.isCreationMode,
            handler: () => {
              this.goToComposition(params.id);
            }
          },
          {
            label: this.i18n.tr("menu.remove"),
            icon: "digi-trash",
            handler: () => {
              this.removeComposition(params);
            }
          }
        ]
      }
    ];
  }

  public override async beforeSave() {
    if (this.isCreationMode) {
      this.entity.defaultBuyingPrice = this.unitBuyingPrice;
      this.entity.defaultSellingPrice = this.unitSellingPrice;
      this.entity.defaultMarginCoefficient = this.defaultMarginCoefficient;
    }
    return true;
  }

  private async validateObjects(objects: Array<any>): Promise<boolean> {
    if (!Array.isArray(objects)) return false;
    const valResultsPromises = objects.map(ipc => this.controller.validate({ object: ipc }));
    const valResults = await Promise.all(valResultsPromises);
    if (valResults.some(v => v.valid === false))
      return false;
    return true;
  }

  public override async save() {
    try {

      if (!this.isCreationMode) {
        // Save ItemPrices/ItemPriceFromDates via API.
        let itemPricesChanges = this.entity.itemPrices?.filter(ip => (ip.entityAspect.entityState.isAddedModifiedOrDeleted() || ip.itemPriceFromDates.some(ipfd => ipfd.entityAspect.entityState.isAddedModifiedOrDeleted())));
        if (itemPricesChanges?.length > 0) {
          let defaultPrice = this.entity.itemPrices.filter(x => x.isDefaultPrice).length;
          if (defaultPrice == 0) {
            this.logError(this.i18n.tr("item.defaultPriceRequired"), null, true);
            return false;
          }
          let valid = await this.validateObjects(itemPricesChanges);
          if (!valid) return false;

          let saved = await this.itemApiService.updateItemPrices(itemPricesChanges);
          if (!saved) return false;
          await this.itemApiService.updateItemLink(this.entity.id);
          this.itemPricesDisposables.map(d => d.dispose());
          // Detach all ItemPrices and ItemPriceFromDates and reload them.
          this.itemPriceFromDateService.rejectAnyEntityChangesFromCache(this.itemPriceFromDateService.entityTypeName);
          this.itemPriceFromDateService.setAllDetached(this.entity.itemPrices.flatMap(i => i.itemPriceFromDates));
          this.itemPriceService.rejectAnyEntityChangesFromCache(this.itemPriceService.entityTypeName);
          this.itemPriceService.setAllDetached(this.entity.itemPrices);
          await this.loadItemPrices();
        }

        // Save ItemCompositions/ItemCompositionLines via API.
        let itemCompoChanges = this.entity.itemCompositions?.filter(ic => (ic.entityAspect.entityState.isAddedModifiedOrDeleted() || ic.itemCompositionLines.some(icl => icl.entityAspect.entityState.isAddedModifiedOrDeleted())));
        if (itemCompoChanges?.length > 0) {
          let defaultPrice = this.entity.itemCompositions.filter(x => x.isDefaultPrice).length;
          if (defaultPrice == 0) {
            this.logError(this.i18n.tr("item.defaultPriceRequired"), null, true);
            return false;
          }
          let valid = await this.validateObjects(itemCompoChanges);
          if (!valid) return false;
          let saved = await this.itemApiService.updateItemCompositions(itemCompoChanges);
          if (!saved) return false;

          this.itemCompositionsDisposables.map(d => d.dispose());
          // Detach all ItemPrices and ItemPriceFromDates and reload them.
          this.itemCompositionLineService.rejectAnyEntityChangesFromCache(this.itemCompositionLineService.entityTypeName);
          this.itemCompositionLineService.setAllDetached(this.entity.itemCompositions.flatMap(i => i.itemCompositionLines));
          this.itemCompositionService.rejectAnyEntityChangesFromCache(this.itemCompositionService.entityTypeName);
          this.itemCompositionService.setAllDetached(this.entity.itemCompositions);
          await this.loadItemCompositions();
        }
        await this.loadEntity(this.entity.id);
      }

      // Save rest of entity
      if (this.hasChanges)
        await super.save(true);

    } catch (e) {
      console.log(e)
    }
  }

  public override async afterSave() {
    if (this.isCreationMode && this.entity.itemTypeId != Constants.ItemType.Composed) {
      await this.addItemPrice();
    }
    if (!this.isCreationMode) {
      await this.updateItemLink()
    }
  }

  private async isArticleCodeExists(articleCode: string) {
    if (!this.entity.isValidated) return true;
    let itemsPredicate: Predicate = new Predicate('articleCode', FilterQueryOp.Equals, articleCode);
    let currentArticle: Predicate = new Predicate('id', FilterQueryOp.NotEquals, this.entity.id);
    let nbItems = await this.service.getCount(itemsPredicate.and(currentArticle));
    return nbItems <= 0;
  }

  public async duplicateItem() {
    let response: Response = await this.httpClient.fetch(Constants.Application.RemoteServiceName + Constants.Application.DuplicateItem + "?itemId=" + this.entity.id);
    if (response.ok) {
      this.router.navigate('items/' + await response.json());
    }
  }


  //#region ItemFamilies
  public async openItemFamiliesTreeView() {
    this.box.showCustomDialog(ItemFamilyTreeView, this.entity.id, this.i18n.tr("item.itemFamilies"), null, { canSave: false, model: { entity: this.entity } });
  }

  //#endregion

  //#region ItemPrices

  private async loadItemPrices(): Promise<void> {
    this.itemPricesDisposables.map(d => d.dispose());
    await this.itemPriceService.getEntities(this.defaultPredicate, ['supplier', 'unit', 'itemPriceFromDates']);
    this.entity?.itemPrices?.map(itemPrice => {
      this.addCurrentItemPriceFromDateObservers(ItemPrice.getCurrentPriceFromDate(itemPrice, true));
      this.setItemPriceObserver(itemPrice);
    });
  }

  public async removeItemPrice(itemPrice: Merlin.Web.Model.ItemPrice) {
    if (await this.itemApiService.canDeleteItemPrice(itemPrice.id)) {
      await this.itemPriceService.deleteEntities([itemPrice], true);
      if (this.entity.itemPrices.length > 0 && !this.entity.itemPrices.some(x => x.isDefaultPrice)) {
        this.entity.itemPrices[0].isDefaultPrice = true;
        this.save();
      } else {
        this.entity.defaultBuyingPrice = 0;
        this.entity.defaultSellingPrice = 0;
        this.entity.defaultMarginCoefficient = 0;
        this.save();
      }
    }
  }

  public async addItemPrice() {
    const newItemPriceInitialValues = await this.itemApiService.newItemPrice(this.entity.id);
    if (!this.isCreationMode) {
      newItemPriceInitialValues.itemId = null;
      let newItemPrice: Merlin.Web.Model.ItemPrice = await this.itemPriceService.createEntity(newItemPriceInitialValues);
      this.entity.itemPrices.push(newItemPrice);
      this.controller.addObject(newItemPrice);
      this.setItemPriceObserver(newItemPrice);
      this.addCurrentItemPriceFromDateObservers(ItemPrice.getCurrentPriceFromDate(newItemPrice, true));
    } else {
      await this.itemApiService.updateItemPrices([newItemPriceInitialValues]);
    }
  }

  public async updateItemLink() {
    await this.itemApiService.updateItemLink(this.entity.id);
  }

  private setItemPriceObserver(itemPrice: Merlin.Web.Model.ItemPrice) {
    this.itemPricesDisposables.push(
      this.bindingEngine.propertyObserver(itemPrice, "isDefaultPrice").subscribe(async (newValue, oldValue) => {
        if (newValue != oldValue) {
          this.entity.itemPrices.filter(y => y.id != itemPrice.id && y.isDefaultPrice).forEach(y => y.isDefaultPrice = false);
          this.updateItemDefaultPricesFromItemPrice(itemPrice);
        }
      }),
      this.bindingEngine.propertyObserver(itemPrice, "unitId").subscribe(async (newValue, oldValue) => {
        if (newValue != oldValue && itemPrice.isDefaultPrice) {
          this.updateItemDefaultPricesFromItemPrice(itemPrice);
        }
      })
    );
  }

  private updateItemDefaultPricesFromItemPrice(itemPrice: Merlin.Web.Model.ItemPrice) {
    if (itemPrice.isDefaultPrice) {
      this.entity.defaultUnitId = itemPrice.unitId
      let itemPriceFromDate = ItemPrice.getCurrentPriceFromDate(itemPrice);
      if (itemPriceFromDate != null) {
        this.unitSellingPrice = itemPriceFromDate.unitSellingPrice;
        this.unitBuyingPrice = itemPriceFromDate.unitBuyingPrice;
        this.defaultMarginCoefficient = itemPriceFromDate.defaultMarginCoefficientPrice;
      }
    }
  }

  public getItemPriceCurrent(itemPrice) {
    if (itemPrice != null) {
      return ItemPrice.getCurrentPriceFromDate(itemPrice, true);
    }
  }

  public isToday(date) {
    return new DateUtils(this.config).isSameDay(date, new Date());
  }

  private async goToPriceFromDate(itemPrice: Merlin.Web.Model.ItemPrice) {
    await this.box.showEditDialog(ItemPriceDetail, itemPrice.id, this.i18n.tr('itempricefromdate.itempricefromdate'), null,
      {
        canSave: false,
        actions: [
          // EditDialogAction.GetSaveAction(this.i18n, false)
          {
            label: this.i18n.tr("menu.save"),
            title: this.i18n.tr("menu.save"),
            theme: 'primary',
            type: 'solid',
            closeDialog: false,
            fn: async (dialogBoxViewModel: DialogBoxViewModel) => {
              let valid = await this.beforeSave();
              if (!valid) return;
              let saved = await this.save();
              if (saved == false) return;
              let entity = await dialogBoxViewModel.customVM.currentViewModel.entity;
              if (entity != null) {
                dialogBoxViewModel.controller.ok(entity);
              }
            }
          }
        ],
        model: { itemPrice: itemPrice, unitTypeId: this.entity.unitTypeId }
      }).whenClosed(async result => {
        if (!result.wasCancelled) {
          this.updateItemDefaultPricesFromItemPrice(itemPrice);
          this.entity.itemPrices.splice(0);
          this.entity.itemPrices.push(...await this.itemPriceService.getEntities(this.defaultPredicate, ['supplier', 'unit', 'itemPriceFromDates']));
          this.entity.itemPrices.map(itemPrice => {
            this.addCurrentItemPriceFromDateObservers(ItemPrice.getCurrentPriceFromDate(itemPrice, true));
            this.setItemPriceObserver(itemPrice);
          });
          await this.itemApiService.updateItemPrices(this.entity.itemPrices)
          this.entity.itemPrices.forEach(x => x.entityAspect.setUnchanged());
        }
      });
  }



  private pricesObserversSuspended = false;
  private pricesObserversSuspendedTimer: NodeJS.Timeout;
  private suspendPricesObservers(): void {
    if (this.pricesObserversSuspended) return;
    this.pricesObserversSuspended = true;
    if (this.pricesObserversSuspendedTimer != null) clearTimeout(this.pricesObserversSuspendedTimer);
    this.pricesObserversSuspendedTimer = setTimeout(() => this.pricesObserversSuspended = false, 600);
  }


  public addCurrentItemPriceFromDateObservers(currentItemPriceFromDate: Merlin.Web.Model.ItemPriceFromDate) {
    if (currentItemPriceFromDate != null) {
      this.itemPricesDisposables.push(
        this.bindingEngine.propertyObserver(currentItemPriceFromDate, 'unitSellingPrice').subscribe(async (newValue, oldValue) => {
          if (newValue != oldValue /*&& !this.pricesObserversSuspended*/) {
            if (currentItemPriceFromDate.unitBuyingPrice != null && currentItemPriceFromDate.unitBuyingPrice != 0 && currentItemPriceFromDate.unitSellingPrice >= currentItemPriceFromDate.unitBuyingPrice) {
              let value = await ItemPrice.getMarginCoef(currentItemPriceFromDate.unitBuyingPrice, currentItemPriceFromDate.unitSellingPrice);
              //this.suspendPricesObservers();
              currentItemPriceFromDate.defaultMarginCoefficientPrice = value;
              this.updateItemDefaultPricesFromItemPrice(currentItemPriceFromDate.itemPrice);
            } else {
              if (currentItemPriceFromDate.unitSellingPrice < currentItemPriceFromDate.unitBuyingPrice) {
                this.logError("itemprice.errorUnitSellingPrice", null, true);
              }
            }
          }
        }),
        this.bindingEngine.propertyObserver(currentItemPriceFromDate, 'unitBuyingPrice').subscribe(async (newValue, oldValue) => {
          if (newValue != oldValue /*&& !this.pricesObserversSuspended*/) {
            let value = await ItemPrice.getSellingPrice(currentItemPriceFromDate.unitBuyingPrice, currentItemPriceFromDate.defaultMarginCoefficientPrice)
            //this.suspendPricesObservers();
            currentItemPriceFromDate.unitSellingPrice = value;
            this.updateItemDefaultPricesFromItemPrice(currentItemPriceFromDate.itemPrice);
          }
        }),
        this.bindingEngine.propertyObserver(currentItemPriceFromDate, 'defaultMarginCoefficientPrice').subscribe(async (newValue, oldValue) => {
          if (newValue != oldValue /*&& !this.pricesObserversSuspended*/) {
            let value = await ItemPrice.getSellingPrice(currentItemPriceFromDate.unitBuyingPrice, currentItemPriceFromDate.defaultMarginCoefficientPrice);
            //this.suspendPricesObservers();
            currentItemPriceFromDate.unitSellingPrice = value;
            this.updateItemDefaultPricesFromItemPrice(currentItemPriceFromDate.itemPrice);
          }
        }),
      );
    }
  }
  //#endregion

  //#region ItemConditionings
  public async removeConditioning(conditioning: Merlin.Web.Model.ItemConditioning) {
    await this.itemConditioningService.deleteEntities([conditioning], true);
  }

  public async addConditioning() {
    let newConditioning = await this.itemConditioningService.createEntity();
    newConditioning.contentQuantity = 1;
    newConditioning.itemId = this.entity.id;
    newConditioning.contentUnitId = this.entity.defaultUnitId;
  }
  //#endregion

  //#region ItemCompositions
  public async loadItemCompositions() {
    if (this.entity.itemTypeId == Constants.ItemType.Composed) {
      await this.itemCompositionService.getEntities(this.defaultPredicate, null, { itemId: this.entity.id });
      this.entity.itemCompositions.sort((a, b) => a.fromQuantity - b.fromQuantity);

      this.itemCompositionsDisposables.forEach(d => d.dispose());
      this.itemCompositionsDisposables = [];
      this.entity.itemCompositions.map(x => this.setItemCompositionObserver(x));
      let defaultPrice = this.entity.itemCompositions.filter(x => x.isDefaultPrice);
      // let resultPrice = this.getItemPriceCurrent([0])
      if (defaultPrice.length > 0) {
        this.unitBuyingPrice = defaultPrice[0].totalBuyingPrice;
        this.unitSellingPrice = defaultPrice[0].totalSellingPrice;
        this.defaultMarginCoefficient = defaultPrice[0].marginCoefficient;
      }

      this.childrenNotValidated = await this.itemApiService.childrenNotValidated(this.entity.id);
    }
  }

  public async addItemComposition() {
    if (this.canSave) {
      await this.service.save();
    }
    const defaultValues = await this.itemApiService.newItemComposition(this.entity.id);
    if (this.isCreationMode) {
      this.router.navigateToRoute('item-detail', { param1: this.entity.id, tab: 'tab5' })
    } else {
      let newComposition = await this.itemCompositionService.getEntityById(defaultValues.id);
      this.controller.addObject(newComposition);
      this.setItemCompositionObserver(newComposition);
    }
  }

  private setItemCompositionObserver(itemComposition: Merlin.Web.Model.ItemComposition) {
    this.itemCompositionsDisposables.push(
      this.bindingEngine.propertyObserver(itemComposition, "isDefaultPrice").subscribe(async (newValue, oldValue) => {
        if (newValue) {
          this.entity.itemCompositions.filter(x => x.id != itemComposition.id && x.isDefaultPrice).forEach(x => x.isDefaultPrice = false);
        }
        this.updateItemDefaultPricesFromDefaultComposition();
      }),
      this.bindingEngine.propertyObserver(itemComposition, "marginCoefficient").subscribe(async (newValue, oldValue) => {
        if (newValue != oldValue) {
          let computedEntity = await this.itemApiService.computePrices(itemComposition);
          if (computedEntity != null) {
            itemComposition.totalBuyingPrice = computedEntity.totalBuyingPrice;
            itemComposition.totalSellingPrice = computedEntity.totalSellingPrice;
            this.updateItemDefaultPricesFromDefaultComposition(itemComposition);
          }
        }
      })
    );
  }

  private updateItemDefaultPricesFromDefaultComposition(itemComposition?: Merlin.Web.Model.ItemComposition) {
    if (itemComposition == null && this.entity.itemCompositions?.length > 0) {
      itemComposition = this.entity.itemCompositions.find(c => c.isDefaultPrice);
      if (itemComposition == null) {
        itemComposition = this.entity.itemCompositions[0];
        itemComposition.isDefaultPrice = true;
      }
    }

    if (this.entity.itemCompositions.length == 0 || itemComposition == null) {
      this.entity.defaultBuyingPrice = 0;
      this.entity.defaultSellingPrice = 0;
      this.entity.defaultMarginCoefficient = 0;
    } else if (itemComposition?.isDefaultPrice) {
      this.entity.defaultBuyingPrice = itemComposition.totalBuyingPrice;
      this.entity.defaultSellingPrice = itemComposition.totalSellingPrice;
      this.entity.defaultMarginCoefficient = itemComposition.marginCoefficient;
    }
  }

  public async removeComposition(composition: Merlin.Web.Model.ItemComposition) {
    if (composition.entityAspect.entityState.isAdded()) {
      composition.entityAspect.setDetached();
    } else {
      await this.itemCompositionService.deleteEntities([composition], true);
    }
    this.updateItemDefaultPricesFromDefaultComposition();
  }

  private showItemCompositions = true;
  public async goToComposition(compositionId: number) {

    this.box.showEditDialog(ItemComposition, this.entity.id, this.i18n.tr("itemcomposition.itemDetail") + " " + this.entity.articleCode + (this.entity.description?._translation != null ? " - " + this.entity.description._translation : ""), null,
      {
        canSave: false,
        keyboard: false,
        size: 'all',
        model: {
          param1: compositionId
        }
      }).whenClosed(async result => {
        this.showItemCompositions = false;
        await this.loadEntity(this.entity.id);
        this.showItemCompositions = true;
      });
  }
  //#endregion

  //#region document
  public documentList: Array<Merlin.Web.Model.Document> = []
  public setDocumentList() {
    this.documentList.splice(0);
    this.documentList.push(...this.entity.itemDocuments.map(x => x.document));
  }

  async refreshDocument() {
    let p1 = new Predicate("itemId", FilterQueryOp.Equals, this.entity.id);
    await this.itemDocuments.getEntities(p1, ['document.fileType']);
    this.setDocumentList();
  }

  async deleteFile(document: Merlin.Web.Model.Document) {
    let docToDelete = this.entity.itemDocuments.find(x => x.documentId == document.id);
    await this.itemDocuments.deleteEntities([docToDelete], true);
    this.setDocumentList();
  }
  //#endregion

  private async showPriceHistory() {
    await this.box.showCustomDialog(ItemContractList, 0, null, {
      model: {
        itemId: this.entity.id
      },
      size: "xl"
    })
  }
}

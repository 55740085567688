import { Merlin } from 'generated';
import { CustomLogger, EditingModeEnum, EntityDetailViewModelBase, PictureHelper, ServiceBase, Various } from 'digiwall-lib';
import { autoinject } from 'aurelia-framework';
import * as Constants from '../constants';
import { Router } from 'aurelia-router';

@autoinject
export class BlackListedDomainMatchingDetail extends EntityDetailViewModelBase<Merlin.Web.Model.BlackListedDomainMatching> {
  public ressourceName: string = Constants.EntityTypeNames.BlackListedDomainMatching;

  constructor(router: Router, logger: CustomLogger) {
    super(router, logger);
    super.initialize(new ServiceBase<Merlin.Web.Model.BlackListedDomainMatching>(Constants.EntityTypeNames.BlackListedDomainMatching));
  }

  public async activate(params: any) {
    super.activate(params);
    var id = params.param1;
    if (id == Various.NewId) {
      this.editingMode = EditingModeEnum.Create;
      this.entity = await this.service.createEntity();
      if (params.callback != null) params.callback(this.entity);
    }
    else {
      this.editingMode = EditingModeEnum.Update;
      this.entity = await this.service.getEntityById(id);
      this.controller.addObject(this.entity);
    }
  }

  public get documentTitle() {
    if (this.editingMode === EditingModeEnum.Create) {
      return this.i18n.tr("blacklisteddomainmatching.addBlackListedDomainMatching");
    } else {
      if (this.entity)
        return this.entity.name ?? '';
    }
  }

  protected async beforeSave(): Promise<boolean | void> {
    this.entity.name = this.entity.name?.trim();
    return super.beforeSave();
  }
}

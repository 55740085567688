import { Router } from 'aurelia-router';
import { autoinject } from 'aurelia-framework';
import { POVSummary } from './../../../services/price-offer-version-api-model';
import { PriceOfferVersionApiService } from 'services/price-offer-version-api-service';
import { MeteringTotalPrices } from 'services/i-data-line-api-service';

@autoinject
export class PriceOfferVersionSummary {
  priceOfferVersionId: number;
  priceOfferSummary: POVSummary;
  public footer: MeteringTotalPrices;

  constructor(private priceOfferVersionApiService: PriceOfferVersionApiService, private router: Router) {

  }

  async activate(params) {
    this.priceOfferVersionId = params.priceOfferVersionId;
    await this.refreshPrice();
  }
  async refreshPrice() {
    this.priceOfferSummary = await this.priceOfferVersionApiService.getSummary(this.priceOfferVersionId);
    this.footer = await this.priceOfferVersionApiService.getMeteringTotalPrices(this.priceOfferVersionId);
  }
}

import { RemoteFileStorage } from 'digiwall-lib';
import { BindingEngine, Container, Disposable, autoinject, bindable, customElement } from "aurelia-framework";
import Quill, { QuillOptionsStatic } from "quill";
import QuillImageDropAndPaste from 'quill-image-drop-and-paste'
import { ITemplateApiService, IPlaceholderItem } from "services/email-template-api-service";
import { DialogService } from "aurelia-dialog";
import { InsertLink } from './insert-link';

@autoinject()
@customElement("dw-quill")
export class DWQuill {
  private quill: Quill;
  private container: HTMLDivElement;
  private mentionOptions: any;
  private imageDropAndPasteOptions: any;
  //private remoteFileServicePicture: string = Constants.Application.RemoteServiceName + Constants.Application.RemoteFileServicePicture;
  private fileButtonAdded = false;
  private linkButtonAdded = false;

  private toolbarOptions = [
    ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
    ['blockquote', 'code-block'],
    [{ 'header': 1 }, { 'header': 2 }],               // custom button values
    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
    [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
    [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
    // [{ 'direction': 'rtl' }],                         // text direction
    [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
    [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
    //[{ 'font': ['arial', 'inter', 'sans-serif', 'serif', 'monospace'] }],
    [{ 'align': [] }],
    ['clean']
  ];

  @bindable()
  value: string;

  @bindable()
  api: ITemplateApiService;

  @bindable()
  emailContextId: number;

  @bindable
  mode: 'edit' | 'preview' = 'edit';

  @bindable()
  canPreview?: boolean | null;

  @bindable()
  readonly = false;

  @bindable()
  addFiles: Function;

  private disposables: Array<Disposable> = [];

  constructor(private element: Element, private bindingEngine: BindingEngine, private remoteFileStorageService: RemoteFileStorage.Service) {
  }


  async attached() {
    if (this.api?.previewRequestParams != null && this.canPreview == null) {
      this.canPreview = true;
    }
    await this.initQuill();

    this.disposables.push(
      this.bindingEngine.propertyObserver(this, 'emailContextId').subscribe(() => this.initQuill())
    )
  }

  detached() {
    this.disposables.forEach(d => d.dispose());
  }

  private async initQuill() {

    await this.registerModuleMentions();// PLACEHOLDERS/MERGE_TOKENS
    this.registerModuleImageDropAndPaste();
    this.createQuillContainer();
    this.createQuillInstance();

    // Set value
    if (this.mode == 'edit') {
      const innerHTML = this.quill.root.innerHTML = this.removeHeadAndBodyTags(this.value);

      // Fix Quill bug on inserting duplicated mentions, see https://github.com/quill-mention/quill-mention/issues/148#issuecomment-630975844.
      const delta = this.quill.clipboard.convert({ html: innerHTML });
      this.quill.updateContents(delta, 'silent');
      // EO. Fix

      // Get value on change
      this.quill.on("text-change", () => {
        this.value = this.getHtml();
      });
    } else {
      if (this.api?.previewRequestParams != null) {
        this.api.previewRequestParams.contentHtml = this.removeHeadAndBodyTags(this.value);
      }
      // this.quill.root.innerHTML = await this.api.preview();
      this.quill.root.innerHTML = await this.api.getContent();
    }
  }

  private createQuillContainer() {
    this.removeQuillContainerIfExists();

    this.container = document.createElement('div');
    this.element.prepend(this.container);
  }

  private removeQuillContainerIfExists(): void {
    if (this.container != null) {
      this.container.remove();
      let toolbar = this.element.getElementsByClassName('ql-toolbar');
      if (toolbar[0] != null)
        toolbar[0].remove();
    }
  }

  private async registerModuleMentions(): Promise<void> {
    if (this.mode == 'edit') {
      let placeholders = [];
      if (this.api != null && this.api.getTokens != null && this.emailContextId != null)
        placeholders = await this.api.getTokens(this.emailContextId);

      this.mentionOptions = {
        allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
        // mentionDenotationChars: ["@", "#"],
        mentionDenotationChars: ["@"],
        showDenotationChar: false,
        positioningStrategy: 'fixed',
        source: function (searchTerm, renderList, mentionChar) {
          let values: IPlaceholderItem[];
          values = placeholders;

          if (searchTerm.length === 0) {
            renderList(values, searchTerm);
          } else {
            const matches = [];
            for (let i = 0; i < values.length; i++)
              if (
                ~values[i].value.toLowerCase().indexOf(searchTerm.toLowerCase())
                || ~values[i].label.toLowerCase().indexOf(searchTerm.toLowerCase())
              )
                matches.push(values[i]);
            renderList(matches, searchTerm);
          }
        },
        renderItem: function (item: IPlaceholderItem, searchTerm) {
          return `<span class="placeholder-name">${item.label}</span><span class="placeholder-key">${item.value}</span>`;
        },
      };

      Quill.register("module/mentions", this.mentionOptions, true);
    }
  }

  private registerModuleImageDropAndPaste() {
    if (this.imageDropAndPasteOptions == null) {
      this.imageDropAndPasteOptions = {
        handler: async (imageDataUrl, type, imageData: { name: string, dataUrl: string }) => {
          let url = await this.remoteFileStorageService.uploadBase64(`emails/${imageData.name}`, imageData.dataUrl);
          let imageUrl: string = url;
          if (imageUrl == null || imageUrl.trim().length == 0) return;

          let index = (this.quill.getSelection() || {}).index
          if (index === undefined || index < 0) index = this.quill.getLength()
          this.quill.insertEmbed(index, 'image', imageUrl, 'user');
        }
      }
      Quill.register('modules/imageDropAndPaste', QuillImageDropAndPaste);
    }
  }

  private createQuillInstance() {
    // Add custom font 'Inter'
    var FontAttributor = Quill.import('formats/font');
    FontAttributor.whitelist = [
      'arial', 'sans-serif', 'serif', 'monospace', 'inter'
    ];
    Quill.register('formats/font', FontAttributor, true);

    if (!this.linkButtonAdded) {
      this.linkButtonAdded = true;
      this.toolbarOptions.push(['link']);
    }

    // Add button to add files, if binded
    if (this.addFiles != null && typeof this.addFiles == 'function' && !this.fileButtonAdded) {
      this.fileButtonAdded = true;
      this.toolbarOptions.push(['image']);
    }

    // Create Quill instance
    this.quill = new Quill(this.container, {
      modules: {
        toolbar: this.toolbarOptions,
        mention: this.mentionOptions,
        imageDropAndPaste: this.imageDropAndPasteOptions
      },
      theme: 'snow',
      readOnly: this.mode == 'preview' || this.readonly
    } as QuillOptionsStatic);

    if (this.linkButtonAdded) {
      var toolbar = this.quill.getModule('toolbar');
      toolbar.addHandler('link', () => this.insertLink());
    }

    if (this.fileButtonAdded) {
      // Add button link handler
      var toolbar = this.quill.getModule('toolbar');
      toolbar.addHandler('image', this.addFiles);
    }
  }



  /**
   * Remove <head> and <body> from the given content. 
   * Befcause Quill replace these tags by spaces and break-lines, we must give the content of the body only.
   *
   * @private
   * @param {string} content
   * @return {*} 
   * @memberof DWQuill
   */
  private removeHeadAndBodyTags(content: string) {
    if (content == null || typeof content != "string") return '';
    return content
      .replace(/<head>[\s\S]*?<\/head>/gs, '')
      .replace(this._bodyStart, '')
      .replace(this._bodyEnd, '')
      .trim();
  }

  /**
   * Return html content with <header> and <body>.
   *
   * @private
   * @return {*} 
   * @memberof DWQuill
   */
  private getHtml() {
    return this.header() + this.body();
  }

  private header(): string {
    return `<head><meta http-equiv="Content-Type" content="text/html; charset=UTF-8"><link href="https://fonts.googleapis.com/css2?family=Inter" rel="stylesheet"><style>${this.quillCss}</style></head>`;
  }

  private readonly _bodyStart = `<body><div class="ql-editor">`;
  private readonly _bodyEnd = `</div></body>`;
  private body(): string {
    return `${this._bodyStart}${this.quill.root.innerHTML}${this._bodyEnd}`;
  }

  public previewMode() {
    this.mode = 'preview';
    this.initQuill();
  }

  public editMode() {
    this.mode = 'edit';
    this.initQuill();
  }

  private insertLink() {
    const cursorPosition = this.quill.getSelection().index;
    console.log(cursorPosition)
    let dialogService = Container.instance.get(DialogService);
    dialogService.open({
      viewModel: InsertLink,
      model: {

      },
      lock: false
    }).whenClosed((result) => {
      if (!result.wasCancelled && result.output.trim()) {
        this.quill.clipboard.dangerouslyPasteHTML(cursorPosition, result.output, "user");
      }
    })
  }

  private readonly quillCss = `
.ql-editor {
  box-sizing: border-box;
  line-height: 1.42;
  height: 100%;
  outline: none;
  overflow-y: auto;
  padding: 12px 15px;
  tab-size: 4;
  -moz-tab-size: 4;
  text-align: left;
  white-space: pre-wrap;
  word-wrap: break-word;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 10pt !important;
}
.ql-editor > * {
  cursor: text;
}
.ql-editor p,
.ql-editor ol,
.ql-editor ul,
.ql-editor pre,
.ql-editor blockquote,
.ql-editor h1,
.ql-editor h2,
.ql-editor h3,
.ql-editor h4,
.ql-editor h5,
.ql-editor h6 {
  margin: 0;
  padding: 0;
  counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
}
.ql-editor ol,
.ql-editor ul {
  padding-left: 1.5em;
}
.ql-editor ol > li,
.ql-editor ul > li {
  list-style-type: none;
}
.ql-editor ul > li::before {
  content: '\\2022';
}
.ql-editor ul[data-checked=true],
.ql-editor ul[data-checked=false] {
  pointer-events: none;
}
.ql-editor ul[data-checked=true] > li *,
.ql-editor ul[data-checked=false] > li * {
  pointer-events: all;
}
.ql-editor ul[data-checked=true] > li::before,
.ql-editor ul[data-checked=false] > li::before {
  color: #777;
  cursor: pointer;
  pointer-events: all;
}
.ql-editor ul[data-checked=true] > li::before {
  content: '\\2611';
}
.ql-editor ul[data-checked=false] > li::before {
  content: '\\2610';
}
.ql-editor li::before {
  display: inline-block;
  white-space: nowrap;
  width: 1.2em;
}
.ql-editor li:not(.ql-direction-rtl)::before {
  margin-left: -1.5em;
  margin-right: 0.3em;
  text-align: right;
}
.ql-editor li.ql-direction-rtl::before {
  margin-left: 0.3em;
  margin-right: -1.5em;
}
.ql-editor ol li:not(.ql-direction-rtl),
.ql-editor ul li:not(.ql-direction-rtl) {
  padding-left: 1.5em;
}
.ql-editor ol li.ql-direction-rtl,
.ql-editor ul li.ql-direction-rtl {
  padding-right: 1.5em;
}
.ql-editor ol li {
  counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
  counter-increment: list-0;
}
.ql-editor ol li:before {
  content: counter(list-0, decimal) '. ';
}
.ql-editor ol li.ql-indent-1 {
  counter-increment: list-1;
}
.ql-editor ol li.ql-indent-1:before {
  content: counter(list-1, lower-alpha) '. ';
}
.ql-editor ol li.ql-indent-1 {
  counter-reset: list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
}
.ql-editor ol li.ql-indent-2 {
  counter-increment: list-2;
}
.ql-editor ol li.ql-indent-2:before {
  content: counter(list-2, lower-roman) '. ';
}
.ql-editor ol li.ql-indent-2 {
  counter-reset: list-3 list-4 list-5 list-6 list-7 list-8 list-9;
}
.ql-editor ol li.ql-indent-3 {
  counter-increment: list-3;
}
.ql-editor ol li.ql-indent-3:before {
  content: counter(list-3, decimal) '. ';
}
.ql-editor ol li.ql-indent-3 {
  counter-reset: list-4 list-5 list-6 list-7 list-8 list-9;
}
.ql-editor ol li.ql-indent-4 {
  counter-increment: list-4;
}
.ql-editor ol li.ql-indent-4:before {
  content: counter(list-4, lower-alpha) '. ';
}
.ql-editor ol li.ql-indent-4 {
  counter-reset: list-5 list-6 list-7 list-8 list-9;
}
.ql-editor ol li.ql-indent-5 {
  counter-increment: list-5;
}
.ql-editor ol li.ql-indent-5:before {
  content: counter(list-5, lower-roman) '. ';
}
.ql-editor ol li.ql-indent-5 {
  counter-reset: list-6 list-7 list-8 list-9;
}
.ql-editor ol li.ql-indent-6 {
  counter-increment: list-6;
}
.ql-editor ol li.ql-indent-6:before {
  content: counter(list-6, decimal) '. ';
}
.ql-editor ol li.ql-indent-6 {
  counter-reset: list-7 list-8 list-9;
}
.ql-editor ol li.ql-indent-7 {
  counter-increment: list-7;
}
.ql-editor ol li.ql-indent-7:before {
  content: counter(list-7, lower-alpha) '. ';
}
.ql-editor ol li.ql-indent-7 {
  counter-reset: list-8 list-9;
}
.ql-editor ol li.ql-indent-8 {
  counter-increment: list-8;
}
.ql-editor ol li.ql-indent-8:before {
  content: counter(list-8, lower-roman) '. ';
}
.ql-editor ol li.ql-indent-8 {
  counter-reset: list-9;
}
.ql-editor ol li.ql-indent-9 {
  counter-increment: list-9;
}
.ql-editor ol li.ql-indent-9:before {
  content: counter(list-9, decimal) '. ';
}
.ql-editor .ql-indent-1:not(.ql-direction-rtl) {
  padding-left: 3em;
}
.ql-editor li.ql-indent-1:not(.ql-direction-rtl) {
  padding-left: 4.5em;
}
.ql-editor .ql-indent-1.ql-direction-rtl.ql-align-right {
  padding-right: 3em;
}
.ql-editor li.ql-indent-1.ql-direction-rtl.ql-align-right {
  padding-right: 4.5em;
}
.ql-editor .ql-indent-2:not(.ql-direction-rtl) {
  padding-left: 6em;
}
.ql-editor li.ql-indent-2:not(.ql-direction-rtl) {
  padding-left: 7.5em;
}
.ql-editor .ql-indent-2.ql-direction-rtl.ql-align-right {
  padding-right: 6em;
}
.ql-editor li.ql-indent-2.ql-direction-rtl.ql-align-right {
  padding-right: 7.5em;
}
.ql-editor .ql-indent-3:not(.ql-direction-rtl) {
  padding-left: 9em;
}
.ql-editor li.ql-indent-3:not(.ql-direction-rtl) {
  padding-left: 10.5em;
}
.ql-editor .ql-indent-3.ql-direction-rtl.ql-align-right {
  padding-right: 9em;
}
.ql-editor li.ql-indent-3.ql-direction-rtl.ql-align-right {
  padding-right: 10.5em;
}
.ql-editor .ql-indent-4:not(.ql-direction-rtl) {
  padding-left: 12em;
}
.ql-editor li.ql-indent-4:not(.ql-direction-rtl) {
  padding-left: 13.5em;
}
.ql-editor .ql-indent-4.ql-direction-rtl.ql-align-right {
  padding-right: 12em;
}
.ql-editor li.ql-indent-4.ql-direction-rtl.ql-align-right {
  padding-right: 13.5em;
}
.ql-editor .ql-indent-5:not(.ql-direction-rtl) {
  padding-left: 15em;
}
.ql-editor li.ql-indent-5:not(.ql-direction-rtl) {
  padding-left: 16.5em;
}
.ql-editor .ql-indent-5.ql-direction-rtl.ql-align-right {
  padding-right: 15em;
}
.ql-editor li.ql-indent-5.ql-direction-rtl.ql-align-right {
  padding-right: 16.5em;
}
.ql-editor .ql-indent-6:not(.ql-direction-rtl) {
  padding-left: 18em;
}
.ql-editor li.ql-indent-6:not(.ql-direction-rtl) {
  padding-left: 19.5em;
}
.ql-editor .ql-indent-6.ql-direction-rtl.ql-align-right {
  padding-right: 18em;
}
.ql-editor li.ql-indent-6.ql-direction-rtl.ql-align-right {
  padding-right: 19.5em;
}
.ql-editor .ql-indent-7:not(.ql-direction-rtl) {
  padding-left: 21em;
}
.ql-editor li.ql-indent-7:not(.ql-direction-rtl) {
  padding-left: 22.5em;
}
.ql-editor .ql-indent-7.ql-direction-rtl.ql-align-right {
  padding-right: 21em;
}
.ql-editor li.ql-indent-7.ql-direction-rtl.ql-align-right {
  padding-right: 22.5em;
}
.ql-editor .ql-indent-8:not(.ql-direction-rtl) {
  padding-left: 24em;
}
.ql-editor li.ql-indent-8:not(.ql-direction-rtl) {
  padding-left: 25.5em;
}
.ql-editor .ql-indent-8.ql-direction-rtl.ql-align-right {
  padding-right: 24em;
}
.ql-editor li.ql-indent-8.ql-direction-rtl.ql-align-right {
  padding-right: 25.5em;
}
.ql-editor .ql-indent-9:not(.ql-direction-rtl) {
  padding-left: 27em;
}
.ql-editor li.ql-indent-9:not(.ql-direction-rtl) {
  padding-left: 28.5em;
}
.ql-editor .ql-indent-9.ql-direction-rtl.ql-align-right {
  padding-right: 27em;
}
.ql-editor li.ql-indent-9.ql-direction-rtl.ql-align-right {
  padding-right: 28.5em;
}
.ql-editor .ql-video {
  display: block;
  max-width: 100%;
}
.ql-editor .ql-video.ql-align-center {
  margin: 0 auto;
}
.ql-editor .ql-video.ql-align-right {
  margin: 0 0 0 auto;
}
.ql-editor .ql-bg-black {
  background-color: #000;
}
.ql-editor .ql-bg-red {
  background-color: #e60000;
}
.ql-editor .ql-bg-orange {
  background-color: #f90;
}
.ql-editor .ql-bg-yellow {
  background-color: #ff0;
}
.ql-editor .ql-bg-green {
  background-color: #008a00;
}
.ql-editor .ql-bg-blue {
  background-color: #06c;
}
.ql-editor .ql-bg-purple {
  background-color: #93f;
}
.ql-editor .ql-color-white {
  color: #fff;
}
.ql-editor .ql-color-red {
  color: #e60000;
}
.ql-editor .ql-color-orange {
  color: #f90;
}
.ql-editor .ql-color-yellow {
  color: #ff0;
}
.ql-editor .ql-color-green {
  color: #008a00;
}
.ql-editor .ql-color-blue {
  color: #06c;
}
.ql-editor .ql-color-purple {
  color: #93f;
}
.ql-editor .ql-font-arial {
  font-family: Arial, Helvetica, sans-serif;
}
.ql-editor .ql-font-serif {
  font-family: Georgia, Times New Roman, serif;
}
.ql-editor .ql-font-monospace {
  font-family: Monaco, Courier New, monospace;
}
.ql-editor .ql-font-inter {
  font-family: 'Inter', sans-serif;
}
.ql-editor .ql-font-sans-serif {
  font-family: sans-serif;
}
.ql-editor .ql-size-small {
  font-size: 8pt;
}
.ql-editor .ql-size-large {
  font-size: 14pt;
}
.ql-editor .ql-size-huge {
  font-size: 18pt;
}
.ql-editor .ql-direction-rtl {
  direction: rtl;
  text-align: inherit;
}
.ql-editor .ql-align-center {
  text-align: center;
}
.ql-editor .ql-align-justify {
  text-align: justify;
}
.ql-editor .ql-align-right {
  text-align: right;
}
.ql-editor.ql-blank::before {
  color: rgba(0,0,0,0.6);
  content: attr(data-placeholder);
  font-style: italic;
  left: 15px;
  pointer-events: none;
  position: absolute;
  right: 15px;
}
.ql-editor h1 {
  font-size: 2em;
}
.ql-editor h2 {
  font-size: 1.5em;
}
.ql-editor h3 {
  font-size: 1.17em;
}
.ql-editor h5 {
  font-size: 0.83em;
}
.ql-editor h6 {
  font-size: 0.67em;
}
h1, h2, h3, h4, h5, h6 {
  font-weight: 400;
  line-height: 110%;
}
  `;
}
